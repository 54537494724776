import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { CURRENT_TIME } from '../config/timeline-props'
import ItemCache from '../utils/ItemCache'
import mergeStyles from '../utils/merge-styles'
import FieldBlock from './FieldBlock'

export default class TimelineItem extends PureComponent {

  static propTypes = {
    template:          PropTypes.object,
    content:           PropTypes.object,
    type:              PropTypes.string,
    layoutId:          PropTypes.string,
    scale:             PropTypes.string,
    length:            PropTypes.number,
    size:              PropTypes.number,
    sizeScale:         PropTypes.number,
    timeOffset:        PropTypes.number,
    itemPos:           PropTypes.number,
    className:         PropTypes.string,
    lang:              PropTypes.string,
    vertical:          PropTypes.bool,
    autoFocus:         PropTypes.bool,
    focus:             PropTypes.bool,
    autoUpdate:        PropTypes.number,
    itemHandler:       PropTypes.func,
    customRender:      PropTypes.func,
    customFieldRender: PropTypes.func,
    customClass:       PropTypes.func,
    customStyle:       PropTypes.func,
  }

  constructor(props) {
    super(props)

    this._timeout = 0
    this._time = 0
    this._x = 0
    this._y = 0
  }

  componentDidMount() {
    this.storeSize(true)
  }

  componentWillUnmount() {
    this.mouseOutHandler()
  }

  componentDidUpdate() {
    if (this.storeSize(true)) {
      this.props.itemHandler({ type: 'update' })
    }
  }

  storeSize(force = false) {
    const { content, type } = this.props
    const id = content._id
    const d = ItemCache.get(id)

    if (force || (type === 'point' && !d)) {
      const l = Math.floor(this.refBlock.offsetHeight)

      if (l !== d) {
        ItemCache.set(id, l)
        return true
      }
    }
    return false
  }

  onloadHandler = () => {
    this.componentDidUpdate()
  }

  mouseDownHandler = event => {
    this._time = Date.now()
    this._x = event.clientX
    this._y = event.clientY
  }

  mouseUpHandler = event => {
    const dt = Date.now() - this._time
    const dx = event.clientX - this._x
    const dy = event.clientY - this._y
    const d = Math.sqrt(dx * dx + dy * dy)

    if (dt < 200 && d < 5) {
      this.changeContent(event)
    }
  }

  mouseOverHandler = () => {
    this._time = Date.now()
    window.clearTimeout(this._timeout)
    this._timeout = window.setTimeout(() => {
      this.changeContent({ type: 'mouseup' })
    }, 100)
  }

  mouseOutHandler = () => {
    window.clearTimeout(this._timeout)
  }

  changeContent = event => {
    const { itemHandler, content } = this.props
    itemHandler && itemHandler(event, content)
  }

  setRefBlock = el => (this.refBlock = el)

  render() {
    const {
      content,
      length,
      vertical,
      focus,
      autoFocus,
      layoutId,
      template,
      scale,
      size,
      itemPos,
      timeOffset,
      className,
      customRender,
      customClass,
      customStyle,
      lang,
      customFieldRender,
    } = this.props

    if (!template) {
      return false
    }

    let fields
    const type = content._display || 'range'
    const end = content._end
    const point = type !== 'range' || !end

    if ((length > 5 && size > 8) || point) {
      fields = template.fields.map((field, idx) => {
        const val = field.timelineVis && field.editorVis !== false && content[field.id]

        if (val) {
          const templateStyle = template?.styles?.[layoutId]?.['fields']?.[field.id]

          const style = mergeStyles(templateStyle, scale, length, size, focus)

          if (style.visible === 'hidden' || style.opacity === 0) {
            return false
          }
          delete style.visible

          return (
            <FieldBlock
              lang={lang}
              key={idx}
              id={content._id}
              field={field}
              val={val}
              style={style}
              size={size}
              itemPos={itemPos}
              length={length}
              onload={this.onloadHandler}
              vertical={vertical}
              customRender={customFieldRender}
            />
          )
        } else {
          return false
        }
      })

      if (customRender) {
        fields.push(customRender(template, content))
      }
    }

    const style = mergeStyles(template.styles?.[layoutId], scale, length, size, focus)
    if (length) {
      if (vertical && !style._rotation) {
        style.width = size
        if (!point) {
          style.height = length
        } else {
          style.height = 'auto'
          style.overflow = 'visible'
        }

        if (style.padding && style.padding * 3 > length) {
          style.paddingTop = 0
          style.paddingBottom = 0
        }
      } else {
        if (!point) {
          style.width = length
          style.height = size
        } else {
          style.width = size
          style.maxWidth = size
          style.height = 'auto'
        }

        if (style.padding && style.padding * 3 > length) {
          style.paddingLeft = 0
          style.paddingRight = 0
        }
      }
    }

    if (timeOffset) {
      if (vertical) {
        style.top = timeOffset
      } else {
        style.left = timeOffset
      }
    }

    if (end === CURRENT_TIME) {
      const r = parseInt(style.borderRadius)

      if (vertical) {
        if (style._rotation === '-90deg') {
          style.borderRadius = `${0} ${r}px ${r}px ${0}`
        } else if (style._rotation === '90deg') {
          style.borderRadius = `${r}px ${0} ${0} ${r}px`
        }
      } else {
        style.borderRadius = `${r}px ${0} ${0} ${r}px`
      }
    }

    let _className = 'timeline-item'
    _className += vertical ? ' vert' : ' horz'

    if (style._rotation) {
      _className += ' rotate-' + style._rotation
    }

    if (className) {
      _className += ' ' + className
    }

    if (customClass) {
      _className += ' ' + customClass(content, length, vertical)
    }

    if (customStyle) {
      customStyle(style, content, length, size, itemPos)
    }

    const props = {
      onMouseDown: this.mouseDownHandler,
      onMouseUp:   this.mouseUpHandler,
      className:   _className,
      style:       style,
    }

    if (autoFocus) {
      props.onMouseOver = this.mouseOverHandler
      props.onMouseOut = this.mouseOutHandler
    }

    return (
      <div {...props} ref={this.setRefBlock}>
        {fields}
      </div>
    )
  }

}
