import {
  getPropsFromTheme,
  getScalesFromTheme,
  rangeLimits,
  scaleBlend,
  timeLimits } from '../../utils/action-helpers'
import { defaultState } from './initial-state'

const parseOptions = opts => {
  ['minSpan', 'maxSpan', 'minTime'].forEach(key => {
    if (opts[key]) {
      opts[key] = parseFloat(opts[key])
    }
  })

  // prevent invalid min/max times
  if (isFinite(opts.minTime) && isFinite(opts.maxTime)) {
    if (opts.minTime > opts.maxTime) {
      delete opts.minTime
    } else if (opts.maxTime < opts.minTime) {
      delete opts.maxTime
    }
    opts.maxSpan = opts.maxTime - opts.minTime
  }

  if (opts.lengthScale && opts.lengthScale > 20) {
    opts.lengthScale /= 100
  }

  opts.zoomLimit = -10
}

export const setOptions = (state, data) => {
  const { theme, scope, ...opts } = data

  parseOptions(opts)

  let newState = {
    ...state ? state : { ...defaultState },
    ...opts,
  }

  if (theme) {
    newState = {
      ...newState,
      ...getPropsFromTheme(newState, theme),
    }
    newState.scales = getScalesFromTheme(theme)
  }

  newState = {
    ...newState,
    ...rangeLimits(newState),
  }

  newState = {
    ...newState,
    ...timeLimits(newState, newState.startTime, newState.endTime),
  }

  const fullLength =
          (newState.orientation === 'vertical'
            ? newState.height
            : newState.width) * newState.lengthScale
  newState.length =  Math.floor(fullLength)

  return {
    ...newState,
    ...scaleBlend(newState),
  }
}
