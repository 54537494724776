import './TransitionWrapper.css'

import React, { CSSProperties, useEffect, useState } from 'react'

export interface TransitionWrapperOwnProps {
  animationDuration?: number,
  inline?: boolean,
  show: boolean,
  onVisibilityChange?: (visible: boolean) => void,
}

export const TransitionWrapper: React.FC<TransitionWrapperOwnProps> = ({
  animationDuration = 50,
  children,
  inline = false,
  onVisibilityChange,
  show,
}) => {
  const [visible, setVisible] = useState(show)

  useEffect(() => {
    show && setVisible(true)
  }, [show])

  const handleAnimationEnd = () => {
    !show && setVisible(false)
  }

  useEffect(() => {
    onVisibilityChange && onVisibilityChange(visible)
  }, [visible])

  const style: CSSProperties = {
    animation: `${show ? 'fade-in' : 'fade-out'} ${animationDuration}ms`,
    display:   visible ? undefined : 'none',
  }

  return inline
    ? <span
      className="transition-wrapper"
      style={style}
      onAnimationEnd={handleAnimationEnd}
    >
      {children}
    </span>
    : <div
      className="transition-wrapper"
      style={style}
      onAnimationEnd={handleAnimationEnd}
    >
      {children}
    </div>

}
