import {
  scaleBlend
} from '../../utils/action-helpers'
import { defaultState } from './initial-state'

export const setSize = (state, data) => {
  const { width, height, lengthScale } = data
  let newState = state || { ...defaultState }

  const w = width || newState.width
  const h = height || newState.height
  const s = lengthScale || newState.lengthScale
  const l = (newState.orientation === 'vertical' ? h : w) * s

  newState = {
    ...newState,
    width:       w,
    height:      h,
    lengthScale: s,
    length:      Math.floor(l),
  }

  return {
    ...newState,
    ...scaleBlend(newState),
  }
}
