import * as TimeUtils from '@kpv-lab/time-utils'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { calculateExtension } from '../utils/action-helpers'
import ItemCache from '../utils/ItemCache'
import TimelineRuler from './TimelineRuler'

export class Timeline extends Component {

  static propTypes = {
    width:              PropTypes.number,
    height:             PropTypes.number,
    rulerSize:          PropTypes.number,
    length:             PropTypes.number,
    offset:             PropTypes.number,
    position:           PropTypes.number,
    startMargin:        PropTypes.number,
    endMargin:          PropTypes.number,
    zoom:               PropTypes.number,
    zoomSpeed:          PropTypes.number,
    minZoom:            PropTypes.number,
    maxZoom:            PropTypes.number,
    zoomLimit:          PropTypes.number,
    maxSpan:            PropTypes.number,
    minSpan:            PropTypes.number,
    startTime:          PropTypes.number,
    endTime:            PropTypes.number,
    minTime:            PropTypes.number,
    maxTime:            PropTypes.number,
    minItemTime:        PropTypes.number,
    maxItemTime:        PropTypes.number,
    blendIn:            PropTypes.number,
    blendOut:           PropTypes.number,
    fadeIn:             PropTypes.number,
    fadeOut:            PropTypes.number,
    unrestricted:       PropTypes.bool,
    disableInteraction: PropTypes.bool,
    wheelZoom:          PropTypes.bool,
    scale:              PropTypes.string,
    prevScale:          PropTypes.string,
    nextScale:          PropTypes.string,
    containerClass:     PropTypes.string,
    containerStyle:     PropTypes.object,
    mode:               PropTypes.string,
    orientation:        PropTypes.string,
    origin:             PropTypes.string,
    name:               PropTypes.string,
    lang:               PropTypes.string,
    viewMode:           PropTypes.string,
    theme:              PropTypes.object,
    layout:             PropTypes.object,
    friction:           PropTypes.number,
    rangeBuffer:        PropTypes.number,
    scrollSpeed:        PropTypes.number,
    highlightRange:     PropTypes.array,
    markers:            PropTypes.array,
    fixedFocus:         PropTypes.number,
    focusMode:          PropTypes.string,
    bgLines:            PropTypes.number,
    cursorPos:          PropTypes.number,
    timelineOffset:     PropTypes.number,
    autoMode:           PropTypes.bool,
    onClick:            PropTypes.func,
    onUpdate:           PropTypes.func,
    onAutoModeZoom:     PropTypes.func,
    customStyle:        PropTypes.func,
    autoUpdate:         PropTypes.number,
    children:           PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    disableScroll:      PropTypes.bool,
    innerRef:           PropTypes.any,
  }

  static defaultProps = {
    width:          window.innerWidth || 800,
    height:         window.innerHeight || 600,
    rulerSize:      130,
    length:         500,
    offset:         0,
    startMargin:    0,
    endMargin:      0,
    minZoom:        -2,
    maxZoom:        8.5,
    zoomLimit:      -10,
    minTime:        1500,
    maxTime:        2016,
    minItemTime:    0,
    maxItemTime:    0,
    unrestricted:   false,
    wheelZoom:      true,
    maxSpan:        0,
    minSpan:        0,
    fadeIn:         0,
    fadeOut:        0,
    zoom:           5,
    zoomSpeed:      1,
    startTime:      1900,
    endTime:        2015,
    containerClass: 'timeline-container',
    containerStyle: {},
    mode:           'years',
    orientation:    'vertical',
    name:           'default',
    viewMode:       '2d',
    friction:       0.9,
    rangeBuffer:    1.15,
    highlightRange: [0.1, 0.1],
    markers:        [],
    fixedFocus:     -1,
    cursorPos:      0,
    scrollSpeed:    0,
    focusMode:      'precise',
    bgLines:        0,
    timelineOffset: 0,
  }

  constructor(props) {
    super(props)

    this._time = 0
  }

  componentDidMount() {
    const { startTime, endTime, orientation } = this.props
    this._startTime = startTime
    this._endTime = endTime
    this._initSpan = endTime - startTime
    // this._setOffset();
    this._vertical = orientation === 'vertical'
    this._horizontal = !this._vertical
    this._interaction = ''
    this._interationType = 'mouse'
    this._timeout = 0
    this._raf = 0
    this._delta = 0
    this._deltaSpace = 0
    this._scrollDelta = 0
    // this._pos = 0;
    // this._prevPos = 0;

    this._startPoint = { spacePos: 0, timePos: 0 }
    this._currPoint = Object.assign({}, this._startPoint)
    this._prevPoint = Object.assign({}, this._startPoint)
    this._currentTrack = -1
    this._currentTrackGroupId = 0

    this._focus = 0.5
    this._renderQueue = 0
    this._time = Date.now()
  }

  componentWillUnmount() {
    this._removeEvents()
  }

  componentDidUpdate(prevProps) {
    const { height, width, orientation, startTime, endTime } = this.props

    if (
      height !== prevProps.height ||
      width !== prevProps.width ||
      orientation !== prevProps.orientation
    ) {
      this._interaction = ''
      window.cancelAnimationFrame(this._raf)
      // this._setOffset();
    }

    this._startTime = startTime
    this._endTime = endTime
    this._refreshTheme = prevProps.theme !== this.props.theme

    if (orientation !== prevProps.orientation || this._refreshTheme) {
      this.forceUpdate()
    }
  }

  _setOffset() {
    const { orientation, innerRef } = this.props
    const el = innerRef.current
    if (el) {
      this._clientOffset =
        el.getBoundingClientRect()[orientation === 'horizontal' ? 'left' : 'top'] || 0
    }
  }

  _interactionStart = event => {
    const { startTime, endTime, fixedFocus } = this.props

    if (this._interaction.startsWith('drag')) {
      return
    }

    this._target = event.target.closest('.timeline-group, .timeline-ruler-canvas, .timeline')
    if (!this._target) {
      return
    }

    let evt
    if (event.type === 'touchstart') {
      evt = event.touches[0]
      this._interationType = 'touch'
    } else {
      evt = event
      this._interationType = 'mouse'
    }

    if (event.shiftKey) {
      this._zooming = true
      this._zoomFocus = fixedFocus > -1 ? fixedFocus : this._setFocus(evt, true)
    } else {
      this._zooming = false
    }

    this._intStartTime = Date.now()
    this._interaction = 'drag'
    this._totalDelta = 0
    this._startTime = startTime
    this._endTime = endTime
    this._initSpan = endTime - startTime

    if (event.target.classList.contains('timeline-filter-start')) {
      this._adjustTime = 'start'
    } else if (event.target.classList.contains('timeline-filter-end')) {
      this._adjustTime = 'end'
    } else if (event.target.classList.contains('timeline-filter-range')) {
      this._adjustTime = 'range'
    } else {
      this._adjustTime = ''
    }

    this._startPoint = {
      spacePos: this._vertical ? evt.clientX : evt.clientY,
      timePos:  this._vertical ? evt.clientY : evt.clientX,
    }
    this._currPoint = Object.assign({}, this._startPoint)
    this._prevPoint = Object.assign({}, this._startPoint)

    if (this._interationType === 'mouse') {
      document.body.addEventListener('mousemove', this._interactionUpdate, false)
      document.body.addEventListener('mouseup', this._interactionEnd, false)
      document.body.addEventListener('mouseleave', this._interactionEnd, false)
    } else {
      document.body.addEventListener('touchmove', this._interactionUpdate, false)
      document.body.addEventListener('touchend', this._interactionEnd, false)
      document.body.addEventListener('touchcancel', this._interactionEnd, false)
      document.body.addEventListener('touchleave', this._interactionEnd, false)
    }

    this._update()
  }

  _interactionUpdate = event => {
    const {
      startTime,
      endTime,
      minSpan,
      maxSpan,
      length,
      startMargin,
      endMargin,
      zoomSpeed,
      scrollSpeed,
    } = this.props

    // required to prevent some janky behaviour in Chrome!
    event.stopPropagation()
    event.preventDefault()

    const evt = event.touches ? event.touches[0] : event
    // this._pos = orientation === 'horizontal' && !this._zooming ? evt.clientX : evt.clientY;

    this._currPoint.spacePos = this._vertical ? evt.clientX : evt.clientY
    this._currPoint.timePos = this._vertical ? evt.clientY : evt.clientX

    let timeSpan = endTime - startTime
    if (this._zooming) {
      // zoom
      const focusTime = startTime + timeSpan * this._zoomFocus
      const dt = (this._currPoint.timePos - this._prevPoint.timePos) * zoomSpeed
      timeSpan = this._zoomTimeSpan(dt / 100)

      if (maxSpan) {
        timeSpan = Math.clamp(timeSpan, minSpan, maxSpan)
      }

      this._startTime = focusTime - this._zoomFocus * timeSpan
      this._endTime = this._startTime + timeSpan
      this._prevPoint.timePos = this._currPoint.timePos
      this._prevPoint.spacePos = this._currPoint.spacePos
      this._applyLimits(timeSpan)
      return
    }

    const dt = this._startPoint.timePos - this._currPoint.timePos
    const ds = this._currPoint.spacePos - this._prevPoint.spacePos

    this._interaction = Math.abs(ds) > Math.abs(dt) ? 'dragSpace' : 'dragTime'

    if (this._interaction === 'dragSpace') {
      this._trackUpdate(ds)
    }

    // move use normal scroll speed if we are dragging the ruler
    let _scrollSpeed = scrollSpeed || ItemCache.get('scrollFactor') || 1
    if (event.target.classList.contains('timeline-ruler-canvas')) {
      _scrollSpeed = 1
    }

    if (maxSpan) {
      timeSpan = Math.clamp(timeSpan, minSpan, maxSpan)
    }

    // const dt = (this._startPos - this._pos) * _scrollSpeed;
    switch (this._adjustTime) {

      case 'range':
        const f = length / (length - startMargin - endMargin)
        timeSpan = (this._initSpan + dt) / f
        if (maxSpan) {
          timeSpan = Math.clamp(timeSpan, minSpan, maxSpan)
        }

        const step = 10
        timeSpan = Math.max(step * Math.round(timeSpan / step), 1) * f

        const t0 = (endTime + startTime) / 2
        this._startTime = t0 - timeSpan / 2
        this._endTime = this._startTime + timeSpan
        // console.log('time span', timeSpan, startTime, endTime);
        break

      case 'start':
      case 'end':
      default:
        this._startTime += (dt * _scrollSpeed * timeSpan) / (length - startMargin - endMargin)
        this._endTime = this._startTime + timeSpan

    }

    this._applyLimits(timeSpan)

    this._totalDelta +=
      Math.abs(this._currPoint.timePos - this._prevPoint.timePos) +
      Math.abs(this._currPoint.spacePos - this._prevPoint.spacePos)

    if (this._adjustTime !== 'range') {
      this._startPoint.timePos = this._currPoint.timePos
    }
    // this._startPoint.spacePos = this._currPoint.spacePos;
  }

  _interactionEnd = event => {
    const { onClick } = this.props
    const dt = Date.now() - this._intStartTime

    if (this._interaction.startsWith('drag') && onClick && this._totalDelta < 50 && dt < 300) {
      // console.log('TL click:', this._totalDelta, dt);
      onClick(event)
    }

    this._interaction = ''
    this._removeEvents()
  }

  _wheelUpdate = event => {
    const {
      startTime,
      endTime,
      length,
      minSpan,
      maxSpan,
      fixedFocus,
      autoMode,
      wheelZoom,
      zoomSpeed,
      scrollSpeed,
      onAutoModeZoom,
      disableInteraction,
    } = this.props

    // TODO: is closest a performance bottleneck?
    this._target = event.target.closest('.timeline-group, .timeline-ruler-canvas, .timeline')

    if (!this._target) {
      return
    }

    event.stopPropagation()

    if (this._interaction.startsWith('drag')) {
      return
    }

    let timeSpan = this._endTime - this._startTime
    let dz = event.deltaY
    let zoomMode = true
    let scrollMode = false
    const rate = (event.altKey ? 0.1 : 1) * zoomSpeed
    const onRuler = this._target.classList.contains('timeline-ruler-canvas')

    if (autoMode && onAutoModeZoom) {
      onAutoModeZoom(dz)
      return
    }

    if (this._target.dataset.scrollable === 'true') {
      // check to see if we are scrolling the tracks across space
      if (this._horizontal && Math.abs(event.deltaY) > Math.abs(event.deltaX)) {
        scrollMode = true
        zoomMode = false
        dz = event.deltaY
      } else if (this._vertical && Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
        scrollMode = true
        zoomMode = false
        dz = event.deltaX
      }
    }

    if (disableInteraction && zoomMode) {
      return
    }

    if (!scrollMode) {
      // normal scrolling in time
      if (this._horizontal && Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
        // prevent zooming when scrolling horizontally along the time axis
        dz = event.deltaX
        zoomMode = false
      } else if (this._vertical && Math.abs(event.deltaY) > Math.abs(event.deltaX)) {
        dz = event.deltaY
        zoomMode = false
      }
    }

    if (!dz) {
      return
    }

    // always zoom if the mouse is over the ruler
    if (onRuler && (wheelZoom || event.shiftKey)) {
      zoomMode = true
    } else {
      zoomMode = false
    }

    // console.log('mode:', onRuler, zoomMode, scrollMode, dz, this._target.dataset.scrollable);
    if (scrollMode) {
      // scroll tracks in space
      this._trackUpdate(-dz)
    } else if (zoomMode) {
      // zoom
      const focus = fixedFocus > -1 ? fixedFocus : this._setFocus(event)
      const dt = endTime - startTime
      const focusTime = startTime + dt * focus
      dz *= rate
      timeSpan = this._zoomTimeSpan(dz / 100)

      if (maxSpan) {
        timeSpan = Math.clamp(timeSpan, minSpan, maxSpan)
      }

      this._startTime = focusTime - focus * timeSpan
      this._endTime = this._startTime + timeSpan
      this._currentTrack = -1
      // console.log('wheel handler zoom:', this._startTime, dz, focus, timeSpan, length);
    } else {
      // scroll tracks in time
      const _scrollSpeed = scrollSpeed || ItemCache.get('scrollFactor') || 1
      timeSpan = this._endTime - this._startTime

      if (maxSpan) {
        timeSpan = Math.clamp(timeSpan, minSpan, maxSpan)
      }

      this._startTime += (dz * _scrollSpeed * timeSpan) / length
      this._endTime = this._startTime + timeSpan
      this._currentTrack = -1
      // console.log('wheel handler time:', this._startTime, dz, _scrollSpeed, timeSpan, length);
    }

    this._applyLimits(timeSpan)

    if (!this._interaction && this._delta === 0) {
      this._interaction = 'wheel'
      this._update()
    }

    window.clearTimeout(this._timeout)
    this._timeout = window.setTimeout(() => {
      this._interaction = ''
    }, 50)
  }

  _trackUpdate(ds) {
    const trackSide = parseInt(this._target.dataset.trackSide)
    this._scrollDelta += ds * trackSide
    this._currentTrackGroupId = this._target.dataset.groupId
    const startTrack = parseFloat(this._target.dataset.startTrack)
    const trackHeight = parseFloat(this._target.dataset.trackHeight)
    const trackLimit = parseInt(this._target.dataset.trackLimit)
    const track = Math.min(Math.max(startTrack - this._scrollDelta / trackHeight, 0), trackLimit)
    // console.log('track:', track, trackLimit);
    this._currentTrack = track === this._currentTrack ? -1 : track.toFixed(3) * 1
  }

  /**
   * @todo this function is partially a replication of timeLimits function in packages/timeline/src/utils/action-helpers.js
   */
  _applyLimits(timeSpan) {
    const {
      cursorPos,
      minTime,
      maxTime,
      unrestricted,
      startMargin,
      endMargin,
      length,
      minItemTime,
      maxItemTime,
    } = this.props
    const restricted = !unrestricted
    const extendedMaxTime = maxTime + calculateExtension(timeSpan)
    // console.log('apply tl limits:', restricted, this._startTime, minTime, this._endTime,  maxTime, cursorPos);
    // continued scroll limit check
    if (restricted && cursorPos > 0) {
      // console.log('tl limit restricted with cursor', this._startTime, minTime, this._endTime,  maxTime);
      const len = length - startMargin - endMargin
      const cursorTimeOffset = ((cursorPos - startMargin) / len) * timeSpan
      const cursorTime = this._startTime + cursorTimeOffset
      const beforeStart = Math.floor(minItemTime) - cursorTimeOffset > this._startTime
      const afterEnd = maxItemTime < cursorTime
      const tSpan = Math.min(timeSpan, 3 * (maxItemTime - minItemTime))

      if (beforeStart) {
        this._startTime = Math.floor(minItemTime) - cursorTimeOffset
        this._endTime = this._startTime + tSpan
        this._prevPoint.timePos = this._currPoint.timePos
      } else if (afterEnd) {
        this._startTime = maxItemTime - cursorTimeOffset
        this._endTime = this._startTime + tSpan
        this._prevPoint.timePos = this._currPoint.timePos
      }
    } else if (restricted && this._endTime >= extendedMaxTime) {
      // console.log('tl limit restricted after maxTime', this._startTime, minTime, this._endTime,  maxTime);
      this._endTime = extendedMaxTime
      this._startTime = extendedMaxTime - timeSpan
      this._prevPoint.timePos = this._currPoint.timePos
    } else if (restricted && this._startTime <= minTime) {
      // console.log('tl limit restricted before minTime', this._startTime, minTime, this._endTime,  maxTime);
      this._startTime = minTime
      this._endTime = minTime + timeSpan
      this._prevPoint.timePos = this._currPoint.timePos
    } else if (unrestricted && cursorPos > 0) {
      // console.log('tl limit unrestricted with cursor', this._startTime, minTime, this._endTime,  maxTime);
      const len = length - startMargin - endMargin
      const cursorTimeOffset = ((cursorPos - startMargin) / len) * timeSpan
      const cursorTime = this._startTime + cursorTimeOffset
      // console.log('unrestricted cursor time:', this._startTime, this._endTime, cursorTime, cursorPos, length);
      if (cursorTime < minTime) {
        this._startTime = minTime - cursorTimeOffset
        this._endTime = this._startTime + timeSpan
        this._prevPoint.timePos = this._currPoint.timePos
      } else if (cursorTime > extendedMaxTime) {
        this._startTime = extendedMaxTime - cursorTimeOffset
        this._endTime = this._startTime + timeSpan
        this._prevPoint.timePos = this._currPoint.timePos
      }
    }
  }

  _setFocus(event) {
    const {
      startMargin,
      endMargin,
      length,
      orientation,
      startTime,
      minItemTime,
      maxItemTime,
      endTime,
      focusMode,
      width,
      height,
      timelineOffset,
      unrestricted,
    } = this.props
    const evt = event.nativeEvent || event
    const container = evt.target.closest('.timeline-container')

    if (!container) {
      return this._focus
    }

    const l = orientation === 'horizontal' ? width : height
    const m = Math.floor((length - l) / 2) // margin offset
    const p = (orientation === 'horizontal' ? evt.offsetX : evt.offsetY) - timelineOffset
    let focus = (p + m - startMargin) / (length - startMargin - endMargin)
    const focusTime = startTime + focus * (endTime - startTime)
    // console.log('focus:', focusMode, focusTime, minItemTime, startTime);

    if (focusMode === 'basic') {
      // we are only concerned with the rough position of the cursor in the parent frame
      const f = p / l
      if (f < 0.3) {
        focus = 0
      } else if (f > 0.7) {
        focus = 1
      } else {
        focus = 0.5
      }
      // console.log('focus mode:', p, f, m);
    }

    if (unrestricted) {
      // do nothing
    } else if (
      minItemTime &&
      (focusTime < minItemTime || startTime < minItemTime || endTime < minItemTime)
    ) {
      // if we try to zoom beyond the minimum time then set the focus to the other end
      focus = 1

      // special case
      if (endTime < minItemTime || startTime < minItemTime) {
        focus = (maxItemTime - startTime) / (endTime - startTime)
      }
    } else if (startMargin < 20 && focus < 0.1 && m > -40) {
      focus = 0
    } else if (endMargin < 20 && focus > 0.9 && m > -40) {
      focus = 1
    }

    this._focus = focus
    return focus
  }

  _zoomTimeSpan(deltaZoom) {
    const { startTime, endTime, minZoom, maxZoom, length } = this.props
    const timeSpan = (endTime - startTime) * TimeUtils.YEAR
    let zoom = Math.log10(timeSpan / length)
    zoom += deltaZoom
    // zoom = Math.min(Math.max(zoom, Math.max(minZoom, zoomLimit)), maxZoom)
    zoom = Math.min(Math.max(zoom, minZoom), maxZoom)
    return (Math.pow(10, zoom) * length) / TimeUtils.YEAR
  }

  _removeEvents() {
    if (this._interationType === 'mouse') {
      document.body.removeEventListener('mousemove', this._interactionUpdate)
      document.body.removeEventListener('mouseup', this._interactionEnd)
      document.body.removeEventListener('mouseleave', this._interactionEnd)
    } else {
      document.body.removeEventListener('touchmove', this._interactionUpdate)
      document.body.removeEventListener('touchend', this._interactionEnd)
      document.body.removeEventListener('touchcancel', this._interactionEnd)
      document.body.removeEventListener('touchleave', this._interactionEnd)
    }
  }

  _update = () => {
    const { onUpdate, length, friction, minSpan, maxSpan } = this.props

    if (this._interaction || this._delta !== 0 || this._deltaSpace !== 0) {
      window.cancelAnimationFrame(this._raf)
      this._raf = window.requestAnimationFrame(this._update)
    }
    this._scrollDelta = 0

    if (!this._interaction) {
      // momentum slow down
      if (this._deltaSpace) {
        // scroll in space
        this._deltaSpace *= friction
        if (Math.abs(this._deltaSpace) < 1) {
          this._deltaSpace = 0
        }

        if (this._deltaSpace !== 0) {
          this._trackUpdate(this._deltaSpace)
        }
      } else {
        // scroll in time
        this._delta *= friction
        if (Math.abs(this._delta) < 1) {
          this._delta = 0
        }
      }

      const scrollFactor = ItemCache.get('scrollFactor') || 1
      let timeSpan = this._endTime - this._startTime

      if (maxSpan) {
        timeSpan = Math.clamp(timeSpan, minSpan, maxSpan)
      }

      this._startTime -= (this._delta * scrollFactor * timeSpan) / length
      this._endTime = this._startTime + timeSpan
      this._applyLimits(timeSpan)
    } else {
      // still interacting, no momentum
      if (this._interaction === 'dragSpace') {
        this._deltaSpace = this._currPoint.spacePos - this._prevPoint.spacePos
        this._delta = 0
      } else {
        this._delta = this._currPoint.timePos - this._prevPoint.timePos
        this._deltaSpace = 0
      }
      this._prevPoint.timePos = this._currPoint.timePos
      this._prevPoint.spacePos = this._currPoint.spacePos
    }

    // propagate changes through to the store
    if (
      onUpdate &&
      (this._startTime !== this._prevStart ||
        this._endTime !== this._prevEnd ||
        this._currentTrack > -1)
    ) {
      this._renderQueue++
      this._prevStart = this._startTime
      this._prevEnd = this._endTime
      onUpdate(
        this._startTime,
        this._endTime,
        this._interaction,
        this._currentTrackGroupId,
        this._currentTrack,
        this._target
      )
      this._currentTrack = -1
    }
  }

  render() {
    const {
      theme,
      width,
      height,
      rulerSize,
      containerClass,
      disableInteraction,
      startTime,
      endTime,
      minTime,
      maxTime,
      minZoom,
      maxZoom,
      length,
      position,
      blendIn,
      blendOut,
      fadeIn,
      fadeOut,
      scale,
      prevScale,
      nextScale,
      layout,
      viewMode,
      offset,
      markers,
      bgLines,
      customStyle,
      mode,
      lang,
      startMargin,
      endMargin,
      orientation,
      disableScroll,
    } = this.props
    // console.log('TL render:', startTime, endTime);
    this._time = Date.now()

    let className = 'timeline timeline-' + orientation
    let style = {
      width:  width,
      height: height,
    }

    if (customStyle) {
      style = Object.assign({}, style, customStyle(style))
    }

    let containerStyle
    if (viewMode === '3d') {
      const pox = layout.perspectiveOriginX
      const poy = layout.perspectiveOriginY
      const tox = layout.transformOriginX
      const toy = layout.transformOriginY

      containerStyle = {
        perspective:       `${length}px`,
        transformStyle:    'preserve-3d',
        perspectiveOrigin: `${typeof pox === 'number' ? pox : 50}% ${
          typeof poy === 'number' ? poy : 50
        }%`,
      }

      className += ' timeline-3d'

      style.transform = `translate3d(${layout.translateX || 0}px, ${layout.translateY
        || 0}px, ${layout.translateZ || 0}px) rotateX(${layout.rotateX ||
        0}deg)  rotateY(${layout.rotateY || 0}deg) rotateZ(${layout.rotateZ || 0}deg)`
      style.transformOrigin = `${typeof tox === 'number' ? tox : 50}% ${
        typeof toy === 'number' ? toy : 50
      }%`
    } else {
      className += ' timeline-2d'
    }

    this._renderQueue = 0

    let handlers = {}
    if (!disableInteraction) {
      handlers = {
        onMouseDown:  this._interactionStart,
        onTouchStart: this._interactionStart,
      }
    }

    return (
      <div
        className={containerClass}
        style={containerStyle}
        onWheel={disableScroll ? null : this._wheelUpdate}
        ref={this.props.innerRef}
      >
        <div className={className} style={style} {...handlers}>
          <TimelineRuler
            orientation={orientation}
            position={position}
            startTime={startTime}
            endTime={endTime}
            minTime={minTime}
            maxTime={maxTime}
            minZoom={minZoom}
            maxZoom={maxZoom}
            blendIn={blendIn}
            blendOut={blendOut}
            fadeIn={fadeIn}
            fadeOut={fadeOut}
            scale={scale}
            lang={lang}
            prevScale={prevScale}
            nextScale={nextScale}
            mode={mode}
            rulerSize={rulerSize}
            fullWidth={width}
            length={length}
            offset={offset}
            startMargin={startMargin}
            endMargin={endMargin}
            markers={markers}
            bgLines={bgLines}
            theme={theme}
            refresh={this._refreshTheme}
          />
          {this.props.children}
        </div>
      </div>
    )
  }

}

export default React.forwardRef(function timeline(props, ref) {
  return <Timeline innerRef={ref} {...props} />
})
