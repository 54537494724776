import { produce } from 'immer'

export const SAVE_FEEDBACK_BROWSER_DATA = 'feedback/SAVE_FEEDBACK_BROWSER_DATA'

export interface SaveFeedbackAction {
  type: typeof SAVE_FEEDBACK_BROWSER_DATA,
  data: {
    itemId: string,
    title: string,
    text: string,
    source: string,
  },
}

export function saveFeedbackToBrowser(title: string, feedbackBody: string, itemId: string): SaveFeedbackAction {
  const data = {
    itemId,
    title,
    text:   feedbackBody,
    source: 'client',
  }

  return {
    type: SAVE_FEEDBACK_BROWSER_DATA,
    data: data,
  }
}

const initialState = {
  currentFeedback: {},
}

export const reducer = () => {
  return produce((state, action) => {
    if (action?.type === SAVE_FEEDBACK_BROWSER_DATA) {
      state.currentFeedback[action.data.itemId] = action.data
    }
  }, initialState)
}
