'use strict'

let _cache = {}

const ItemCache = {
  get: function (key) {
    return _cache[key]
  },

  set: function (key, val) {
    _cache[key] = val
    return val
  },

  clear: function (key) {
    if (key) {
      delete _cache[key]
    } else {
      _cache = {}
    }
  },
}

export default ItemCache
