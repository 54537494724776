import { useEffect, useRef } from 'react'

export function useEventListener(event, handler, delay = 0, element = window) {
  const savedHandler = useRef()

  function debounce(fn, ms) {
    let timer
    return () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        /** eslint-disable-next-line prefer-rest-params */
        fn.apply(this, arguments)
      }, ms)
    }
  }

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) {
      return
    }

    const eventHandler = e => savedHandler.current(e)
    const eventListener = delay > 0 ? debounce(eventHandler, delay) : eventHandler

    element.addEventListener(event, eventListener)

    return () => {
      element.removeEventListener(event, eventListener)
    }
  }, [event, element])
}
