import {
  scaleBlend
} from '../../utils/action-helpers'
import { setIn } from '../../utils/state-helper'
import { defaultState } from './initial-state'

export const setScaleBlend = (state, data) => {
  const { key, val } = data
  const newState = state || { ...defaultState }
  setIn(newState.scales, key, val)

  return {
    ...newState,
    ...scaleBlend(newState),
  }
}
