import { gql } from '@apollo/client'

import settings from '../config/settings'
import client from '../services/apollo'

export const activityQuery = gql`
  query ActivityQuery {
    activity
  }
`
export const getChapterSection = (chapterTitle) => `Chapter: ${chapterTitle}`

let timeout = 0
let cache = []

export function saveActivity(activity, saveDelay = settings.saveDelay) {
  cache.push(activity)

  window.clearTimeout(timeout)
  timeout = window.setTimeout(() => {
    commitActivity(cache.slice())
    cache = []
  }, saveDelay)
}

function commitActivity(activity) {
  return client
    .mutate({
      mutation:  saveActivityQuery,
      variables: { activity },
      update:    (proxy, { data: { saveActivity: saveActivityData } }) => {
        // https://github.com/apollographql/apollo-client/issues/1701#issuecomment-380213533
        if (!proxy.data.data.ROOT_QUERY) {
          return
        }

        // Read the data from our cache for this query and merge in the new activity
        const store = proxy.readQuery({ query: activityQuery })
        const newActivity = Object.keys(saveActivityData).reduce((s, k) => {
          if (k === 'lastUpdatedAt') {
            s[k] = saveActivityData[k]
            return s
          }

          const act = saveActivityData[k]
          if (s[k]) {
            s[k] = Object.assign({}, s[k])

            // update existing entry
            if (!s[k].section.includes(act.section)) {
              // add to the page count
              s[k].pages++
              s[k].section += `,${act.section}`
            }
            s[k].lastUpdatedAt = act.lastUpdatedAt
          } else {
            // add new entry
            s[k] = act
          }
          return s
        }, Object.assign({}, store.activity))

        // Write our data back to the cache.
        proxy.writeQuery({ query: activityQuery, data: newActivity })
      },
    })
    .catch(err => {
      console.warn('save activity error:', err)
      // put the activity back on the list to try again
      cache.push(...activity)
    })
}

export const saveActivityQuery = gql`
  mutation SaveActivity($activity: [ActivityInput], $lastUpdate: Int) {
    saveActivity(activity: $activity, lastUpdate: $lastUpdate)
  }
`
