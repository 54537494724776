import classNames from 'classnames'
import React, { useContext, useRef } from 'react'

import { Place } from '../lib/place'
import { PopupContext } from '../lib/popup-context'

type PopupOwnProps = {
  alignment?: Place,
  className?: string,
  containerClass?: string,
  content: React.ReactNode,
  inline?: boolean,
  place?: Place,
};

export const Popup: React.FC<PopupOwnProps> = ({
  alignment,
  children,
  className = '',
  containerClass,
  content,
  inline = true,
  place = 'top',
}) => {
  const targetRef = useRef<HTMLDivElement>(null)
  const popupContext = useContext(PopupContext)

  const onMouseEnter = () => {
    if (targetRef.current && content) {
      popupContext.setCurrent({
        alignment,
        containerClass,
        content,
        place,
        target: targetRef.current,
      })
    }
    popupContext.show()
  }
  const Element = inline ? 'span' : 'div'
  const containerClasses = classNames('popup--target-container', className, { 'popup--target-inline': inline })
  return (<Element
    className={containerClasses}
    onMouseEnter={onMouseEnter}
    onMouseLeave={popupContext.hide}
    ref={targetRef}
  >
    {children}
  </Element>)
}
