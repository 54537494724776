import { rgbaString } from '@kpv-lab/color-utils'
import * as TimeUtils from '@kpv-lab/time-utils'
import { blendInStyle } from '@kpv-lab/timeline/src/TimelineRuler/methods/blendInStyle'
import { blendOutStyle } from '@kpv-lab/timeline/src/TimelineRuler/methods/blendOutStyle'
import { getStyle } from '@kpv-lab/timeline/src/TimelineRuler/methods/getStyle'
import { deriveInfoLabel } from '@kpv-lab/timeline/src/TimelineRuler/methods/labels/deriveInfoLabel'
import { drawInfoLabel } from '@kpv-lab/timeline/src/TimelineRuler/methods/labels/drawInfoLabel'
import { drawLabel } from '@kpv-lab/timeline/src/TimelineRuler/methods/labels/drawLabel'
import { getLabel } from '@kpv-lab/timeline/src/TimelineRuler/methods/labels/getLabel'
import { tickType } from '@kpv-lab/timeline/src/TimelineRuler/methods/tickType'
import { blend, getPixelRatio, keepInRange, step } from '@kpv-lab/timeline/src/TimelineRuler/rulerHelpers'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { AGE_OF_UNIVERSE, zoomRanges } from '../config/timeline-props'

const _scales = {
  seconds: ['seconds', 'minutes', 'hours'],
  years:   [...zoomRanges],
}

export default class TimelineRuler extends PureComponent {

  static propTypes = {
    rulerSize:       PropTypes.number,
    fullWidth:       PropTypes.number,
    length:          PropTypes.number,
    offset:          PropTypes.number,
    position:        PropTypes.number,
    startMargin:     PropTypes.number,
    endMargin:       PropTypes.number,
    startTime:       PropTypes.number,
    endTime:         PropTypes.number,
    minTime:         PropTypes.number,
    maxTime:         PropTypes.number,
    minZoom:         PropTypes.number, // eslint-disable-line
    maxZoom:         PropTypes.number, // eslint-disable-line
    blendIn:         PropTypes.number,
    blendOut:        PropTypes.number,
    fadeIn:          PropTypes.number,
    fadeOut:         PropTypes.number,
    scale:           PropTypes.string,
    prevScale:       PropTypes.string,
    nextScale:       PropTypes.string,
    mode:            PropTypes.string,
    orientation:     PropTypes.string,
    theme:           PropTypes.object,
    lang:            PropTypes.string,
    filtering:       PropTypes.bool, // eslint-disable-line
    refresh:         PropTypes.bool,
    bgLines:         PropTypes.number,
    quality:         PropTypes.string, // eslint-disable-line
    minLabelSpacing: PropTypes.number,
    markers:         PropTypes.array // eslint-disable-line
  }

  static defaultProps = {
    rulerSize:       130,
    fullWidth:       900,
    startMargin:     0,
    endMargin:       0,
    length:          600,
    offset:          0,
    startTime:       1900,
    endTime:         2015,
    minTime:         -3000,
    maxTime:         3000,
    minZoom:         -2,
    maxZoom:         8,
    blendIn:         0,
    blendOut:        0,
    fadeIn:          0,
    fadeOut:         0,
    scale:           'years',
    prevScale:       '',
    nextScale:       '',
    mode:            'years',
    orientation:     'vertical',
    filtering:       false,
    refresh:         false,
    bgLines:         0,
    minLabelSpacing: 15,
    quality:         'full',
    markers:         [],
  }

  _pxr = 1
  refCanvas = '123'
  refRuler = '1234'

  componentDidMount() {
    // console.log('ruler did mount', this.refCanvas);
    this._pxr = getPixelRatio()
    this.title = ''
    this._timeout = 0
    this._mounted = true
    this.styles = {}
    this.ticks = []

    const d = new Date()
    this._today = TimeUtils.toFloatTime({
      year:  d.getFullYear(),
      month: d.getMonth(),
      day:   d.getDay(),
    })

    this._scales = _scales[this.props.mode]
    this._priorities = {
      second: 1,
      minute: 2,
      hour:   3,
      day:    4,
      month:  5,
      year:   7,
      minor:  7,
      middle: 8,
      major:  9,
    }

    this._ctxBack = this.refCanvas.getContext('2d')

    this._canvasTicks = document.createElement('canvas')
    this._ctxTicks = this._canvasTicks.getContext('2d')

    this._canvasLabels = document.createElement('canvas')
    this._ctxLabels = this._canvasLabels.getContext('2d')

    this._canvasLabels2 = document.createElement('canvas')
    this._ctxLabels2 = this._canvasLabels2.getContext('2d')

    if (this.props.bgLines) {
      // this._ctxLines    = this.refCanvasLines.getContext('2d');
      this._ctxLines = this.refCanvasLines.getContext('2d')
      this._ctxLines.imageSmoothingEnabled = false
    }

    this._canvasTitle = document.createElement('canvas')
    this._ctxTitle = this._canvasTitle.getContext('2d')

    this._setSize()
    this._setScaleTypes()
    this.draw()
  }

  componentDidUpdate(prevProps) {
    const { length, rulerSize, fullWidth, orientation, refresh } = this.props
    const pxr = getPixelRatio()

    if (
      prevProps.length !== length ||
      prevProps.rulerSize !== rulerSize ||
      prevProps.fullWidth !== fullWidth ||
      prevProps.orientation !== orientation ||
      pxr !== this._pxr
    ) {
      this._setSize()
    }

    if (refresh || pxr !== this._pxr) {
      this.styles = {}
      this.title = ''
      this._setScaleTypes()
    }

    this._pxr = pxr
    this.draw()
  }

  componentWillUnmount() {
    this._mounted = false
  }

  _setSize() {
    const { fullWidth, rulerSize, length, orientation } = this.props
    const pxr = getPixelRatio()
    let w, h

    if (orientation === 'horizontal') {
      w = Math.floor(length * pxr)
      h = Math.floor(rulerSize * pxr)
    } else {
      w = Math.floor(rulerSize * pxr)
      h = Math.floor(length * pxr)
    }

    this.refCanvas.width = w
    this.refCanvas.height = h
    this._canvasTicks.width = Math.min(w, h)
    this._canvasTicks.height = Math.max(w, h)
    this._canvasLabels.width = Math.min(w, h)
    this._canvasLabels.height = Math.max(w, h)
    this._canvasLabels2.width = Math.min(w, h)
    this._canvasLabels2.height = Math.max(w, h)
    this._canvasTitle.width = Math.min(w, h)
    this._canvasTitle.height = Math.max(w, h)

    if (this.refCanvasLines) {
      this.refCanvasLines.width = Math.floor((fullWidth - rulerSize) * pxr)
      this.refCanvasLines.height = Math.max(w, h)
    }
  }

  _setScaleTypes() {
    const { theme, mode } = this.props
    let j, k, len, len1, s, order, scale, type

    this._scaleTypes = {}
    if (mode === 'seconds') {
      order = ['hour', 'minute', 'second']
    } else {
      order = ['major', 'middle', 'minor', 'year', 'month', 'day', 'hour', 'minute', 'second']
    }
    const ref = this._scales

    for (j = 0, len = ref.length; j < len; j++) {
      s = ref[j]
      this._scaleTypes[s] = []
      scale = theme[s]

      for (k = 0, len1 = order.length; k < len1; k++) {
        type = order[k]

        if (scale[type]) {
          this._scaleTypes[s].push(type)
        }
      }
    }
  }

  draw() {
    const {
      startTime,
      endTime,
      theme,
      rulerSize,
      length,
      orientation,
      startMargin,
      endMargin,
      minLabelSpacing,
      minTime,
      maxTime,
      mode,
      blendIn,
      blendOut,
      fadeIn,
      fadeOut,
      scale,
      prevScale,
      nextScale,
      lang,
    } = this.props

    if (!this._mounted) {
      return
    }

    const timeSpan = endTime - startTime
    const pxr = getPixelRatio()
    let yearIncrement
    let startOffset = 0
    const margin = startMargin + endMargin

    if (startMargin) {
      startOffset = timeSpan * (startMargin / (length - margin))
    }

    const current = TimeUtils.timeDetails(startTime - startOffset)
    if (current.dayOfWeek === undefined) {
      const date = new Date(`${current.year}-${current.month + 1}-${current.day + 1}`)
      current.dayOfWeek = date.getDay()
    }
    // console.log('start', startTime, current.dayOfWeek, current)

    const subYear =
      mode === 'seconds' || ['months', 'days', 'hours', 'minutes', 'seconds'].includes(scale)

    if (theme[scale].minor) {
      yearIncrement = theme[scale].minor.interval
    }
    TimeUtils.roundToIntervalStart(scale, current, yearIncrement)
    let t = TimeUtils.toFloatTime(current)

    // misc
    const w = rulerSize * pxr
    let p = (length - margin) * ((t - startTime) / timeSpan) + startMargin // this is the initial offset position
    const startP = p

    if (orientation === 'horizontal') {
      p = length - p
    }

    let y = 0
    let py = 0
    let tickIdx = 0
    const attrs = {}
    const common = theme.common
    const textAlign = theme.common.textAlign
    let prevPriority = 1
    const divSize = {}
    let minSpacing = 1000
    let style, styleKey, tickAlpha, tickLength, priority, x1, x2, yy, i

    const textTransforms = {
      lowercase: 'toLowerCase',
      uppercase: 'toUpperCase',
      titlecase: 'toTitleCase',
    }
    const labelTextTransform = (subYear || lang !== 'de') && textTransforms[common.textTransform] || ''
    const titleTextTransform = textTransforms[common.titleTextTransform] || ''

    const ctxBack = this._ctxBack
    const ctxTicks = this._ctxTicks
    const ctxLabels = this._ctxLabels
    const ctxLabels2 = this._ctxLabels2

    if (orientation === 'horizontal') {
      ctxBack.save()
      ctxBack.translate(length * pxr, 0)
      ctxBack.rotate(90 * (Math.PI / 180))
    }

    ctxBack.clearRect(0, 0, w, length * pxr)
    ctxBack.lineCap = common.lineCap
    ctxBack.lineJoin = common.lineJoin
    ctxBack.textBaseline = common.textBaseline
    ctxBack.textAlign = textAlign

    ctxTicks.clearRect(0, 0, w, length * pxr)
    ctxTicks.lineCap = common.lineCap
    ctxTicks.lineJoin = common.lineJoin

    ctxLabels.clearRect(0, 0, w, length * pxr)
    ctxLabels.textBaseline = common.textBaseline
    ctxLabels.textAlign = textAlign
    ctxLabels.lineJoin = common.lineJoin

    ctxLabels2.clearRect(0, 0, w, length * pxr)
    ctxLabels2.textBaseline = common.textBaseline
    ctxLabels2.textAlign = textAlign
    ctxLabels2.lineJoin = common.lineJoin

    if (common.bgAlpha > 0) {
      ctxBack.globalAlpha = common.bgAlpha
    }

    const yearFractions = {
      second: TimeUtils.INVYEAR,
      minute: TimeUtils.MIN * TimeUtils.INVYEAR,
      hour:   TimeUtils.HOUR * TimeUtils.INVYEAR,
      day:    1 / 365,
      month:  1 / 12,
      year:   1,
    }

    for (const type of Object.keys(theme[scale])) {
      const params = theme[scale][type]
      if (typeof params !== 'object' || type === 'daylabel') {
        continue
      }

      // note, the fractional year amounts are only approximate - good enough for label positioning
      const yearFraction = yearFractions[type] || params.interval
      const spacing = yearFraction / timeSpan * length
      minSpacing = Math.min(minSpacing, spacing)
      divSize[type] = spacing * pxr

      attrs[`${scale}-${type}`] = this._getStyle(scale, type)
    }

    const s1 = this._step(minSpacing, minLabelSpacing * 0.8, minLabelSpacing)
    const s2 = this._step(minSpacing, minLabelSpacing * 0.2, minLabelSpacing * 0.3)

    for (const type of this._scaleTypes[scale]) {
      if (nextScale && blendOut > 0 && blendOut < 1) {
        for (const type2 of this._scaleTypes[nextScale]) {
          styleKey = `${scale}-${type}-${type2}`
          if (!attrs[styleKey]) {
            attrs[styleKey] = this._blendOutStyle(
              attrs[`${scale}-${type}`],
              this._getStyle(nextScale, type2),
              blendOut
            )
          }
        }
      } else if (prevScale && blendIn >= 0 && blendIn <= 1) {
        if (!theme[prevScale][type] || theme[prevScale].minor) {
          styleKey = `${scale}-${type}-in`
          if (!attrs[styleKey]) {
            attrs[styleKey] = this._blendInStyle(this._getStyle(scale, type), blendIn)
          }
        }
      }
    }

    let outTypes = []
    const _minTime = Math.max(minTime, -AGE_OF_UNIVERSE)
    const _maxTime = Math.ceil(maxTime || this._today)
    const nextYear = Math.max(_maxTime, new Date().getFullYear() + 1)
    let beforeStart, afterEnd, _blend, types, inTypes, label, extraLabel, value

    // add ticks and labels until we move beyond the end of the ruler
    while (tickIdx < 500) {
      // console.log p, current.year, current.month, t if tickIdx < 10
      // exit loop if we reach the end
      if ((orientation === 'horizontal' && p < 0) || (orientation === 'vertical' && p > length)) {
        break
      }

      // tick style for current mode and type
      y = p * pxr
      beforeStart = t < _minTime
      afterEnd = t > nextYear
      if (afterEnd && !theme.outsideAlpha) {
        break
      }

      _blend = 0
      types = this._tickType(scale, current)
      ;[label, value, extraLabel] = this._getLabel(scale, current, types[0])
      styleKey = `${scale}-${types[0]}`

      // special cases
      if (scale === 'seconds') {
        if (value % 10 === 0 && styleKey === 'seconds-second') {
          styleKey = 'seconds-minute'
        }
      }

      if (nextScale && blendOut > 0 && blendOut < 1) {
        // zoom value decreasing. blend current ticks into the next mode style
        _blend = -1
        outTypes = this._tickType(nextScale, current)
        styleKey = `${scale}-${types[0]}-${outTypes[0]}`
      } else if (prevScale && blendIn >= 0 && blendIn <= 1) {
        // start of range when zoom decreasing.
        // blend in new ticks that weren't present in the prev mode
        // and morph prev mode ticks into current style
        _blend = 1
        inTypes = this._tickType(prevScale, current)
        const [, valueIn] = this._getLabel(prevScale, current)

        if (
          !theme[prevScale][inTypes[0]] ||
          value !== valueIn ||
          (!theme[prevScale][types[0]] && !theme[scale][types[0]].interval)
        ) {
          styleKey = `${scale}-${types[0]}-in`
        }
      }

      style = attrs[styleKey]
      if (!style) {
        styleKey = styleKey.replace(/-(in|out)$/, '')
        style = attrs[styleKey]

        if (!style) {
          // console.warn('no style!', styleKey, attrs, this.props);
          tickIdx++
          break
        }
      }
      tickAlpha = style.tickAlpha
      tickLength = style.tickLength
      style._labelAlpha = style.labelAlpha
      style._outlineAlpha = style.outlineAlpha
      // cell1Alpha = style.cell1Alpha
      // cell2Alpha = style.cell2Alpha

      if (tickLength === 0 && style.tickThickness > 2 && common.lineCap !== 'butt') {
        // so that we can get dot or square like marks for zero length ticks
        tickLength = 0.0001
      }

      priority = this._priorities[types[0]]
      if (priority <= prevPriority || (tickIdx === 0 && mode === 'years')) {
        style._labelAlpha *= s1
        style._outlineAlpha *= s1
        tickAlpha *= s2
      }

      tickAlpha = (tickAlpha || 0).toFixed(3) * 1
      style._labelAlpha = (style._labelAlpha || 0).toFixed(3) * 1
      style._outlineAlpha = (style._outlineAlpha || 0).toFixed(3) * 1

      if (mode === 'years') {
        // special case for year 0!
        if (current.year === 0 && t > 0) {
          tickAlpha = 0
          style._labelAlpha = 0
          style._outlineAlpha = 0
        }

        // outside range
        if (beforeStart || afterEnd) {
          tickAlpha *= theme.outsideAlpha || 0
          style._labelAlpha *= theme.outsideAlpha || 0
          style._outlineAlpha *= theme.outsideAlpha || 0
        }

      } else if (beforeStart || afterEnd) {
        tickAlpha = 0
        style._labelAlpha = 0
        style._outlineAlpha = 0
      }

      // draw tick
      switch (textAlign) {

        case 'left':
          x1 = w * common.edgeAlign + style.tickPos * w + common.edgeWidth * pxr * 0.5
          x2 = x1 + w * tickLength
          break
        case 'right':
          // x1 = (1 - style.tickPos) * w
          x1 = w * common.edgeAlign - style.tickPos * w - common.edgeWidth * pxr * 0.5
          x2 = x1 - w * tickLength
          break
        default:
          x1 = (common.edgeAlign + style.tickPos) * w - w * tickLength * 0.5
          x2 = x1 + w * tickLength

      }

      const fadeInAlpha = fadeIn ? Math.min(Math.max(y / (length * pxr) / fadeIn, 0), 1) : 1
      const fadeOutAlpha = fadeOut
        ? 1 -
          Math.min(Math.max((y - length * pxr * (1 - fadeOut)) / (length * pxr) / fadeOut, 0), 1)
        : 1
      const fadeAlpha = Math.min(fadeInAlpha, fadeOutAlpha)

      if (tickAlpha > 0 && tickLength > 0) {
        ctxTicks.globalAlpha = tickAlpha * fadeAlpha
        ctxTicks.strokeStyle = style.tickColor
        ctxTicks.lineWidth = style.tickThickness
        ctxTicks.beginPath()
        ctxTicks.moveTo(x1, y)
        ctxTicks.lineTo(x2, y)
        ctxTicks.stroke()
        ctxTicks.closePath()

        if (
          scale === 'days' &&
          attrs['days-day'] &&
          (current.dayOfWeek === 0 || current.dayOfWeek === 6)
        ) {
          ctxTicks.globalAlpha = fadeAlpha * attrs['days-day'].cell1Alpha
          ctxTicks.fillStyle = attrs['days-day'].cell1Color
          ctxTicks.fillRect(Math.min(x1, x2), y, Math.abs(x2 - x1), y - py)
        }
      }

      // draw labels
      if (style._labelAlpha > 0) {
        i = 0
        for (const type of types) {
          if (i > 0) {
            [label, value, extraLabel] = this._getLabel(scale, current, type)
            styleKey = `${scale}-${type}`
            priority = this._priorities[type]

            if (_blend === 1) {
              // blend in
              inTypes = this._tickType(prevScale, current)
              // if this type isn't present on the previous scale then blend it in
              if (!theme[prevScale][type]) {
                styleKey = `${scale}-${type}-in`
              }
            } else if (_blend === -1) {
              // blend out
              styleKey = `${scale}-${type}-${type}`
            }
            style = attrs[styleKey]
            if (style && !style.labelPlacement) {
              continue
            }

            if (priority <= prevPriority) {
              style._labelAlpha *= s1
            }

            prevPriority = priority
          }

          if (style?._labelAlpha > 0) {
            yy = style.labelPlacement * divSize[type] * 0.5

            if (orientation === 'horizontal') {
              yy *= -1
            }

            this._drawLabel(
              style,
              labelTextTransform,
              w,
              y + yy,
              label,
              extraLabel,
              styleKey,
              divSize[type],
              fadeAlpha
            )
          }

          i++
        }
      }

      // tick time forward
      if (scale === 'seconds') {
        current.second += 1
      } else if (scale === 'minutes') {
        current.minute += 1
      } else if (scale === 'hours') {
        current.hour += 1
      } else if (scale === 'days') {
        current.day += 1
        current.dayOfWeek = (current.dayOfWeek + 1) % 7
      } else if (scale === 'months') {
        current.month += 1
        current.daysInMonth = TimeUtils.MONTHDAYS[current.month] || 31

        if (current.daysInMonth === 28 && current.leap) {
          current.daysInMonth = 29
        }
      } else {
        current.year += yearIncrement
      }

      // keep current time counter in range
      if (subYear) {
        keepInRange(current)
      }

      // set float time and position for next tick
      t = TimeUtils.toFloatTime(current)
      p = (length - margin) * ((t - startTime) / timeSpan) + startMargin

      if (orientation === 'horizontal') {
        p = length - p
      }

      py = y
      prevPriority = priority
      tickIdx++
    }

    // create title layer if required
    const drawInfo = this._setInfoLabel()

    if (drawInfo) {
      this._drawInfoLabel(titleTextTransform ? this.title[titleTextTransform]() : this.title)
    }

    if (orientation === 'horizontal') {
      ctxBack.restore()
    }

    this._combineLayers(ctxBack, common, w)
    this.fullUpdate = false

    // console.timeEnd('[draw DOM ruler]');
    this._prevZoom = this._zoom
    this._prevPos = startP
  }

  _getLabel = (scaleKey, current, type) => getLabel(this.props, scaleKey, current, type)


  _combineLayers(ctxBack, common, width) {
    // composite layers together
    const { length, bgLines, orientation } = this.props
    let x
    const pxr = getPixelRatio()
    const w = Math.floor(common.edgeWidth * pxr)
    const h = Math.floor(length * pxr)
    const layers = (common.layers || 'Ticks:1 Axis:1 Labels:1 Title:1').split(' ')
    ctxBack.globalAlpha = 1

    if (orientation === 'horizontal') {
      ctxBack.save()
      ctxBack.translate(length * pxr, 0)
      ctxBack.rotate(90 * (Math.PI / 180))
    }

    const rendered = {}
    for (const layer of layers) {
      let [canvas, alpha] = layer.split(':') // eslint-disable-line
      alpha = parseFloat(alpha)

      // console.log('Composite layer:', canvas, alpha);
      if (alpha === 0 || (canvas === 'Title' && !this.title) || canvas === 'Markers') {
        continue
      }

      if (canvas === 'Axis') {
        if (common.edgeAlpha > 0 && common.edgeWidth > 0) {
          ctxBack.beginPath()
          ctxBack.globalAlpha = common.edgeAlpha * alpha
          ctxBack.fillStyle = common.edgeColor
          x = Math.floor(width * common.edgeAlign - common.edgeAlign * common.edgeWidth * pxr)

          ctxBack.rect(x, 0, w, h)
          ctxBack.fill()
        }
      } else {
        ctxBack.globalAlpha = alpha
        ctxBack.drawImage(this[`_canvas${canvas}`], 0, 0)

        if (canvas === 'Labels') {
          ctxBack.drawImage(this._canvasLabels2, 0, 0)
        }
      }
      rendered[canvas] = true

      if (rendered.Axis && rendered.Ticks && !rendered.mirror && common.mirror) {
        // mirror the canvas back on itself
        ctxBack.save()
        ctxBack.translate(this.refCanvas.width, 0)
        ctxBack.scale(-1, 1)
        ctxBack.drawImage(this.refCanvas, 0, 0)
        ctxBack.restore()
        rendered.mirror = true
      }
    }

    if (bgLines) {
      this._ctxLines.globalAlpha = bgLines
      const fw = this.refCanvasLines.width
      this._ctxLines.clearRect(0, 0, fw, h)
      x = Math.floor(width * common.edgeAlign - common.edgeAlign * common.edgeWidth * pxr)
      this._ctxLines.drawImage(this._canvasTicks, x - 10, 0, 1, h, 0, 0, fw, h)
    }

    if (orientation === 'horizontal') {
      ctxBack.restore()
    }
  }

  _getStyle = (scaleKey, type) => getStyle(scaleKey, type, this.props.theme, this._scales)

  // find the type and value of tick for the current time
  _tickType = (scaleKey, current) => tickType(scaleKey, current, this.props.theme, this._scaleTypes)

  _blendOutStyle = (style1, style2, blendOut) => blendOutStyle(style1, style2, blendOut)

  _blendInStyle = (style, blendIn) => blendInStyle(style, blendIn, this.props)

  _blend = (a, b, t = 0) => blend(a, b, t)

  // linear [0-1] output when v moves between edge 1 & 2
  _step = (v, e1, e2) => step(v, e1, e2)

  _drawLabel = (style, textTransform, width, pos, str, extraStr, key, divSize, fadeAlpha)  =>
    drawLabel (style, textTransform, width, pos, str, extraStr, key, divSize, fadeAlpha, this.props, this._ctxLabels, this._ctxLabels2)

  _setInfoLabel() {
    const _info =  deriveInfoLabel(this.props, this.title)

    if (_info !== this.title) {
      this.title = _info
      return true
    } else {
      return false
    }
  }

  _drawInfoLabel = str=> drawInfoLabel(str, this.props, this._ctxTitle)

  setRefCanvas = el => (this.refCanvas = el)
  setRefCanvasLines = el => (this.refCanvasLines = el)

  render() {
    const {
      fullWidth,
      rulerSize,
      length,
      orientation,
      bgLines,
      theme,
      position,
      offset,
    } = this.props
    const pxr = getPixelRatio()
    let w1, h1
    // console.log('render ruler');

    if (orientation === 'horizontal') {
      w1 = length
      h1 = rulerSize
    } else {
      w1 = rulerSize
      h1 = length
    }

    const rulerStyle = {
      width:  w1,
      height: h1,
    }

    const cvsStyle = {
      width:  w1,
      height: h1,
    }

    const common = theme.common
    if (common.bgColor && common.bgAlpha > 0) {
      cvsStyle.backgroundColor = rgbaString(common.bgColor, common.bgAlpha)
    }

    if (orientation === 'horizontal') {
      rulerStyle.top = position
      rulerStyle.marginLeft = offset
    } else {
      rulerStyle.left = position
      rulerStyle.marginTop = offset
    }

    const w2 = Math.floor(w1 * pxr)
    const h2 = Math.floor(h1 * pxr)

    if (theme && theme.common.boxShadowAlpha > 0) {
      rulerStyle.boxShadow = `${theme.common.boxShadowX}px ${theme.common.boxShadowY}px ${theme
        .common.boxShadowBlur}px ${theme.common.boxShadowOffset || 0}px ${rgbaString(
        theme.common.boxShadowColor,
        theme.common.boxShadowAlpha
      )}`
    }

    let linesCvs
    if (bgLines) {
      const fw = fullWidth - rulerSize
      linesCvs = (
        <canvas
          className="timeline-ruler-lines"
          width={fw}
          height={h2}
          ref={this.setRefCanvasLines}
        />
      )
    }

    return (
      <div className="timeline-ruler" style={rulerStyle}>
        <canvas
          className="timeline-ruler-canvas"
          width={w2}
          height={h2}
          style={cvsStyle}
          ref={this.setRefCanvas}
        />
        {linesCvs}
      </div>
    )
  }

}

// polyfill
if (!String.prototype.toTitleCase) {
  String.prototype.toTitleCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
}
