if (!Math.clamp) {
  // console.log('polyfilling Math.clamp');
  Math.clamp = function (v, min, max) {
    return Math.min(Math.max(v, min), max)
  }
}

if (!Math.log10) {
  // console.log('polyfilling Math.log10');
  Math.log10 = function (v) {
    return Math.log(v) / Math.log(10)
  }
}

if (!String.prototype.toTitleCase) {
  String.prototype.toTitleCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
}

// element-closest | CC0-1.0 | github.com/jonathantneal/closest
/* eslint-disable @typescript-eslint/no-this-alias */
if (typeof Element !== 'undefined' && typeof Element.prototype.matches !== 'function') {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.mozMatchesSelector ||
    Element.prototype.webkitMatchesSelector ||
    function matches(selector) {
      const element = this
      const elements = (element.document || element.ownerDocument).querySelectorAll(selector)
      let index = 0

      while (elements[index] && elements[index] !== element) {
        ++index
      }

      return Boolean(elements[index])
    }
}

if (typeof Element !== 'undefined' && typeof Element.prototype.closest !== 'function') {
  Element.prototype.closest = function closest(selector) {
    let element = this

    while (element && element.nodeType === 1) {
      if (element.matches(selector)) {
        return element
      }

      element = element.parentNode
    }

    return null
  }
}
