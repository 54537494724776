import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import ItemCache from '../../utils/ItemCache'
import CanvasMarkers from '../CanvasMarkers'
import TimelineItemsBackground from '../TimelineItemsBackground'
import TimelineItemsGroup from '../TimelineItemsGroup'
import {
  getMetrics,
  parseLayoutItem,
  setTrackGroupOffsetFullStage,
  timelineItemAnchorFactory } from './utils/items-helper'
import { findItemsWithinRange } from './utils/items-range'
import { addMarkerStyle, markersFactory } from './utils/markers-helper'

export default class TimelineItems extends PureComponent {

  static propTypes = {
    params:             PropTypes.object, // eslint-disable-line
    items:              PropTypes.array,
    templates:          PropTypes.object,
    layout:             PropTypes.object,
    arrangement:        PropTypes.object,
    itemsTrack:         PropTypes.object,
    startTime:          PropTypes.number,
    endTime:            PropTypes.number,
    maxTime:            PropTypes.number,
    startMargin:        PropTypes.number,
    endMargin:          PropTypes.number,
    position:           PropTypes.number,
    length:             PropTypes.number,
    offset:             PropTypes.number, // eslint-disable-line
    availableSpace:     PropTypes.number,
    contentVisible:     PropTypes.bool,
    alignment:          PropTypes.string, // eslint-disable-line
    orientation:        PropTypes.string,
    layoutOrder:        PropTypes.string,
    autoUpdate:         PropTypes.number, // eslint-disable-line
    scale:              PropTypes.string, // eslint-disable-line
    zoom:               PropTypes.number,
    zoomLimit:          PropTypes.number,
    sizeScale:          PropTypes.number,
    layoutId:           PropTypes.string,
    activeId:           PropTypes.string, // eslint-disable-line
    activeClassName:    PropTypes.string, // eslint-disable-line
    lang:               PropTypes.string, // eslint-disable-line
    cursorPos:          PropTypes.number,
    fadeIn:             PropTypes.number,
    zoomLimitHandler:   PropTypes.func,
    itemHandler:        PropTypes.func,
    spaceScrollHandler: PropTypes.func,
    customItemRender:   PropTypes.func, // eslint-disable-line
    customFieldRender:  PropTypes.func,
    customItemStyle:    PropTypes.func, // eslint-disable-line
    customItemClass:    PropTypes.func, // eslint-disable-line
    customMarker:       PropTypes.func,
    children:           PropTypes.object,
    className:          PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.updateLength = false
    this._timeout1 = 0
    this._timeout2 = 0
    this.state = { opacity: props.fadeIn ? 0 : 1 }
  }

  componentDidMount() {
    this.updateLength = true
    if (this.props.fadeIn) {
      this._fadeIn()
    }
  }

  componentDidUpdate(prevProps) {
    const { fadeIn, items, layout, zoom } = this.props

    if (items !== prevProps.items) {
      this.updateLength = false
      this.setState({ opacity: fadeIn ? 0 : 1 })
      if (fadeIn) {
        this._fadeIn()
      }
    }

    if (zoom !== prevProps.zoom && layout.resizeMode !== 'instant') {
      this.updateLength = false
      this._deferStyleUpdate()
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this._timeout1)
    window.clearTimeout(this._timeout2)
  }

  _fadeIn() {
    window.clearTimeout(this._timeout1)
    this._timeout1 = window.setTimeout(() => {
      this.setState({ opacity: 1 })
    }, 32)
  }

  _deferStyleUpdate() {
    window.clearTimeout(this._timeout2)
    this._timeout2 = window.setTimeout(() => {
      this.updateLength = true
      // console.log('deferred update for timeline items');
      this.forceUpdate()
    }, 32)
  }

  itemHandler = (event, content) => {
    if (/load|update/.test(event.type)) {
      this.forceUpdate()
    } else if (event.type === 'mouseup') {
      this.props.itemHandler && this.props.itemHandler(event, content)
    }
  }

  renderItems() {
    const {
      zoomLimitHandler,
      startTime,
      endTime,
      startMargin,
      endMargin,
      availableSpace,
      orientation,
      layoutId,
      templates,
      position,
      length,
      layout,
      arrangement,
      itemsTrack,
      zoomLimit,
      cursorPos,
      maxTime,
      spaceScrollHandler,
      contentVisible,
      sizeScale,
      customMarker,
      customFieldRender,
    } = this.props


    const vertical = orientation === 'vertical'
    const _sizeScale = sizeScale || 1
    const len = length - startMargin - endMargin
    const pxpt = (endTime - startTime) / len

    const cursorTime = startTime + (cursorPos - startMargin) * pxpt

    const resizeInstant = layout.resizeMode === 'instant'
    const layoutOrder = layout.layoutOrder || 'c'
    const rulerSize = layout.rulerSize
    const autoFocus = layout.autoFocus === '1'
    const mainOffset = layout.rulerOffset || 0
    const fadeLength = 100
    const comps = []
    const bgComps = []
    const markersWidth = { '-2': 0, '-1': 0, 1: 0, 2: 0 }
    const markers = { '-2': [], '-1': [], 1: [], 2: [] }
    const trackInfo = {}
    const distFromEdge = { '-1': 0, 1: 0 }
    let markerStyles = {}
    let scrollFactor = 1
    let limitZoom = true
    let cursorIdx = -1
    const trackRanges = {}
    const metrics = getMetrics(layoutOrder, position, availableSpace, mainOffset, rulerSize, contentVisible)

    // helper methods
    const createTimelineItemAnchor = timelineItemAnchorFactory(
      vertical,
      this.updateLength || resizeInstant,
      autoFocus,
      this.itemHandler,
      customFieldRender
    )

    const {
      addPointMarker,
      addRangeMarker,
      addRulerMarker,
    } = markersFactory(markers, markersWidth, markerStyles, _sizeScale)

    // #########################  Start outer loop through each arrangement ####################
    for (const tplId of arrangement.get('order')) {
      const currentArrangement = arrangement.get(tplId)
      const currentArrangementItems = currentArrangement?.items
      if (!currentArrangementItems?.length) {
        continue
      }

      // this method gets called on every frame update so tries to do everything in a single pass
      // and minimise as much as possible (compared to the alternative of using map operations)
      trackInfo[tplId] = {
        minTrack: 0,
        maxTrack: 0,
      }

      // find the items template
      const tpl = templates[tplId] // TODO offload this to the timeline item

      if (!tpl) {
        console.warn('Template:', tplId, 'not available!', templates)
        continue
      } else if (!tpl.start) {
        continue
      }

      const { items: layoutItems } = layout
      const layoutItem = layoutItems?.[tplId]
      if (layoutItem?.show === false) {
        continue
      }

      const { timeSpacing, size, spacing, trackSide, rulerOffset } = parseLayoutItem(layoutItem, _sizeScale)

      // define size for this track group
      const _trackGroupSize = layoutItem?.trackGroupSize
      let trackGroupSize

      if (/px/i.test('' + _trackGroupSize)) {
        // size in pixels
        trackGroupSize = parseInt(_trackGroupSize) || metrics[trackSide].freeSpace
      } else {
        trackGroupSize = Math.floor(
          metrics[trackSide].freeSpace * (parseInt(_trackGroupSize) || 100) / 100
        )
      }
      trackGroupSize *= _sizeScale
      trackGroupSize = Math.min(metrics[trackSide].freeSpace, trackGroupSize)
      metrics[trackSide].freeSpace -= trackGroupSize

      const colSize = size + spacing
      let startTrack = (itemsTrack?.[tplId]) || 0
      const tracksVisible = (trackGroupSize - spacing) / colSize
      const maxTracks = Math.floor(tracksVisible)
      const trackGroup = []

      const itemsWithinRange = findItemsWithinRange(
        this.props,
        currentArrangement,
        currentArrangementItems.length,
        layoutItem,
        pxpt,
        trackInfo,
        tplId,
        vertical,
        _sizeScale,
        fadeLength,
        colSize,
        trackGroupSize,
        maxTracks,
        tpl
      )

      limitZoom = itemsWithinRange._limitZoom
      let trackCount = itemsWithinRange.trackCount
      const { outsideFirst, outsideLast } = itemsWithinRange

      const style = setTrackGroupOffsetFullStage(
        layout,
        tplId,
        vertical,
        trackGroupSize,
        layoutOrder,
        trackSide,
        metrics,
        distFromEdge
      )

      // ###############  Start second inner loop to create items for each track ##############

      // keep startTrack in range
      if (trackCount > maxTracks && startTrack + maxTracks > trackCount + 1) {
        startTrack = trackCount - maxTracks + 1
      } else if (startTrack > trackCount || trackCount <= maxTracks) {
        startTrack = 0
      }

      trackRanges[tplId] = {
        min:        1e15,
        max:        -1e15,
        track:      startTrack,
        maxTracks:  maxTracks,
        trackCount: trackCount,
      }

      let track = trackInfo[tplId].minTrack
      while (track <= trackInfo[tplId].maxTrack) {
        const currentTrack = trackInfo[tplId][track]
        track++

        if (!currentTrack) {
          // console.warn('no data for track:', track - 1, 'template:', tplId, trackInfo);
          continue
        }

        const rangeItems = currentTrack.rangeItems
        const pointItems = currentTrack.pointItems
        const startMarkerKey = `${layoutId}-${tplId}-s`
        const endMarkerKey = `${layoutId}-${tplId}-e`
        markerStyles = addMarkerStyle(
          markerStyles,
          startMarkerKey,
          tpl.styles?.[layoutId]?.startMarker
        )
        markerStyles = addMarkerStyle(
          markerStyles,
          endMarkerKey,
          tpl.styles?.[layoutId]?.endMarker
        )

        // normal range items don't need anything special
        for (const props of rangeItems) {
          props.sizeScale = 1 // sizeScale;
          props.track -= startTrack

          if (props.track > -1 && props.track <= maxTracks + 1) {
            trackGroup.push(createTimelineItemAnchor(props))
            props.trackPos = Math.floor(rulerOffset + props.track * colSize)

            if (props.timePos >= -10 && markerStyles[startMarkerKey].active) {
              addRangeMarker(startMarkerKey, props, false, trackSide, distFromEdge[trackSide])
            }

            if (props.timePos + props.length <= length + 10 && markerStyles[endMarkerKey].active) {
              addRangeMarker(endMarkerKey, props, true, trackSide, distFromEdge[trackSide])
            }

            if (props.track > 0) {
              trackRanges[tplId].min = Math.min(trackRanges[tplId].min, props.startTime)
              trackRanges[tplId].max = Math.max(trackRanges[tplId].max, props.endTime)
            }
          }
        }

        if (pointItems.length) {
          // point items after the cursor time need their positions adjusted to prevent overlaps
          let initialStartPos = 1e6
          let prevStartPos = -1e6
          let prevEndPos = -1e6
          // console.log('track info:', tplId, currentTrack, pointItems);
          const rulerMarkerKey = `${layoutId}-${tplId}-o`
          markerStyles = addMarkerStyle(
            markerStyles,
            rulerMarkerKey,
            tpl.styles?.[layoutId]?.outsideMarker
          )

          // first add the items on the cursor
          cursorIdx =
            currentTrack.idxOnCursor > -1 ? currentTrack.idxOnCursor : currentTrack.idxAfterCursor

          if (cursorIdx > -1) {
            let idx, l
            for (idx = cursorIdx, l = pointItems.length; idx < l; idx++) {
              const props = pointItems[idx]

              // first if the item is spanning the cursor position then proportionally shift
              // it's position so that the next item will be in place when it gets to the cursor
              if (props.timePos <= cursorPos && cursorPos < props.timePos + props.groupLength) {
                const next = props.nextItem
                let overlap = 0
                let nextPos = props.startPos

                if (next) {
                  overlap =
                    next.timePos + next.itemOffset - (props.timePos + props.groupLength) || 0
                  nextPos = next.timePos
                } else {
                  const maxTimePos = (maxTime - startTime) / pxpt
                  nextPos = startMargin + maxTimePos
                  overlap = nextPos - (props.timePos + props.groupLength - timeSpacing)
                }

                if (overlap < 0) {
                  // NOTE: weird bug here for close items.
                  // The first one is getting pushed up too quickly
                  const dc = cursorPos - props.timePos
                  const dp = nextPos - props.timePos
                  const d = Math.min(dc / dp, 1) * overlap
                  props.timePos += d
                }
              } else if (props.timePos < prevEndPos) {
                // check the current item doesn't overlap the previous one
                props.timePos = prevEndPos
              }

              prevStartPos = props.timePos
              prevEndPos = prevStartPos + props.groupLength
              initialStartPos = Math.min(initialStartPos, prevStartPos)

              const edge = prevStartPos + props.timeOffset

              if (edge > length - fadeLength) {
                props.alpha =
                  Math.min(
                    Math.max((length - edge) / Math.min(props.length, fadeLength), 0),
                    1
                  ).toFixed(3) * 1
              } else {
                props.alpha = 1
              }

              if (edge > length) {
                // beyond the end of the timeline so skip
                break
              }

              props.sizeScale = 1 // sizeScale;
              if (props.alpha > 0) {
                trackGroup.push(createTimelineItemAnchor(props))
              }

              if (props.group) {
                for (const grp of props.group) {
                  grp.timePos = props.timePos + grp.itemOffset
                  grp.sizeScale = props.sizeScale

                  const groupEdge = grp.timePos + props.timeOffset
                  if (groupEdge > length - fadeLength) {
                    grp.alpha =
                      Math.min(
                        Math.max((length - groupEdge) / Math.min(grp.length, fadeLength), 0),
                        1
                      ).toFixed(3) * 1
                  } else {
                    grp.alpha = 1
                  }

                  if (grp.alpha > 0) {
                    trackGroup.push(createTimelineItemAnchor(grp))
                  }
                }
              }

              if (markerStyles[startMarkerKey].active) {
                if (props.alpha > 0) {
                  addPointMarker(startMarkerKey, props, trackSide, distFromEdge[trackSide])
                }

                if (props.group) {
                  for (const grp of props.group) {
                    if (grp.alpha > 0) {
                      addPointMarker(startMarkerKey, grp, trackSide, distFromEdge[trackSide])
                    }
                  }
                }
              }

              const next = pointItems[idx + 1]

              // find the intersecting item and set the scroll factor if required
              if (
                props.startTime <= cursorTime &&
                cursorTime < props.startTime + (props.groupLength + props.timeOffset) * pxpt
              ) {
                if (next) {
                  const dl =
                    (next.startTime - props.startTime) / (pxpt * props.groupLength) ||
                    props.groupLength / len
                  const f = Math.min(dl, 1)
                  scrollFactor = Math.min(f, scrollFactor)
                }
              }

              if (props.alpha === 0) {
                break
              }
            }
          } else {
            cursorIdx = pointItems.length
          }

          cursorIdx = currentTrack.idxBeforeCursor

          // point event items before the cursor time need to be shifted up to prevent overlap
          // with the items after the cursor time
          prevStartPos = initialStartPos
          // console.log('prevStartPos:', initialStartPos, pointItems[cursorIdx].key);

          let idx
          // now work backwards through the items before the cursor
          for (idx = cursorIdx; idx >= 0; idx--) {
            const props = pointItems[idx]
            if (props.timePos + props.groupLength > prevStartPos) {
              // push back items
              props.timePos = prevStartPos - props.groupLength
            }
            prevStartPos = props.timePos
            prevEndPos = prevStartPos + props.groupLength

            const edge = prevStartPos + props.timeOffset + props.length
            // console.log('edge', props.key, edge, fadeLength);
            if (edge < fadeLength) {
              props.alpha =
                Math.min(Math.max(edge / Math.min(props.length, fadeLength), 0), 1).toFixed(3) * 1
            } else {
              props.alpha = 1
            }

            props.sizeScale = 1 // sizeScale;
            if (props.alpha > 0) {
              trackGroup.push(createTimelineItemAnchor(props))
              if (markerStyles[startMarkerKey].active) {
                addPointMarker(startMarkerKey, props, trackSide, distFromEdge[trackSide])
              }
            }

            if (props.group) {
              for (const grp of props.group) {
                grp.timePos = props.timePos + grp.itemOffset
                grp.sizeScale = props.sizeScale

                const groupEdge = grp.timePos + grp.timeOffset + grp.length
                if (groupEdge < fadeLength) {
                  grp.alpha =
                    Math.min(Math.max(groupEdge / Math.min(grp.length, fadeLength), 0), 1).toFixed(3) * 1
                } else {
                  grp.alpha = 1
                }

                if (grp.alpha > 0) {
                  trackGroup.push(createTimelineItemAnchor(grp))
                  if (markerStyles[startMarkerKey].active) {
                    addPointMarker(startMarkerKey, grp, trackSide, distFromEdge[trackSide])
                  }
                }
              }
            }

            if (props.alpha === 0) {
              break
            }
          }

          // add ruler markers
          if (markerStyles[rulerMarkerKey].active) {
            for (const props of pointItems) {
              addRulerMarker(rulerMarkerKey, props.rulerPos, trackSide * 2)

              if (props.group) {
                for (const grp of props.group) {
                  addRulerMarker(rulerMarkerKey, grp.rulerPos, trackSide * 2)
                }
              }
            }
          }
        }
      }
      // ###############  End second inner loop to create items for each track ###############

      distFromEdge[trackSide] += trackGroupSize
      trackCount += 1

      const bgStyles = {
        backgroundColor:     style.backgroundColor,
        bgTexture:           style.bgTexture,
        backgroundBlendMode: style.backgroundBlendMode,
        width:               style.width,
        height:              style.height,
        left:                style.left,
        top:                 style.top,
      }

      bgComps.push(
        <TimelineItemsBackground
          key={`trkbg-${tplId}`}
          trackSide={trackSide}
          vertical={vertical}
          style={bgStyles}
        />
      )

      comps.push(
        <TimelineItemsGroup
          key={`trkgrp-${tplId}`}
          id={tplId}
          track={startTrack.toFixed(3) * 1}
          trackHeight={size + spacing}
          trackCount={trackCount}
          tracksVisible={tracksVisible}
          trackSide={trackSide}
          scrollable={trackCount > maxTracks}
          vertical={vertical}
          style={style}
          spaceScrollHandler={spaceScrollHandler}
        >
          {trackGroup}
        </TimelineItemsGroup>
      )

      if (trackRanges[tplId].min === 1e15) {
        trackRanges[tplId].min = outsideFirst
      }

      if (trackRanges[tplId].max === -1e15) {
        trackRanges[tplId].max = outsideLast
      }
    }
    // #########################  End outer loop through each arrangement ####################

    if (limitZoom && zoomLimit === -10) {
      zoomLimitHandler(true)
    } else if (!limitZoom && zoomLimit > -10) {
      zoomLimitHandler(false)
    }

    // append marker elements
    for (const s of [-2, -1, 1, 2]) {
      if (markers[s].length && markersWidth[s]) {
        markers[s] = markers[s].sort((a, b) => a.t - b.t)
        comps.unshift(
          <CanvasMarkers
            edge={metrics[Math.min(Math.max(s, -1), 1)].rulerEdge}
            length={length}
            width={Math.ceil(markersWidth[s])}
            vertical={vertical}
            markers={markers[s]}
            styles={markerStyles}
            customMarker={customMarker}
            side={s}
            sizeScale={_sizeScale}
            key={`tl-markers-${s}`}
          />
        )
      }
    }

    // console.log('scrollFactor:', scrollFactor, 'cursorTime:', cursorTime);
    const pf = ItemCache.get('scrollFactor') || 1
    if (scrollFactor < 1) {
      // immediately slow down
      ItemCache.set('scrollFactor', scrollFactor)
    } else {
      // average speed up
      ItemCache.set('scrollFactor', (scrollFactor + pf) / 2)
    }

    ItemCache.set('trackRanges', trackRanges)
    // console.log('track range:', trackRanges);

    // finally done!
    return [...bgComps, ...comps]
  }

  render() {
    const style = {
      opacity:            this.state.opacity,
      transitionProperty: 'opacity',
      transitionDuration: '0s',
    }

    if (this.props.fadeIn && this.state.opacity) {
      style.transitionDuration = `${this.props.fadeIn}s`
    }

    return (
      <div className={this.props.className || 'timeline-items'} style={style}>
        {this.props.children}
        {this.renderItems()}
      </div>
    )
  }

}
