/* eslint-disable simple-import-sort/sort */
import './styles/App.css'
import '@components/timeline/styles/TimelineContainer.css'
import { PopupProvider } from '@kpv-lab/popup-manager'
import '../author/styles/AuthorTimeline.css'
import '../authors/styles/AuthorsView.css'
import { PersistGate } from 'redux-persist/integration/react'

// hot has to come before React imports
import { hot } from 'react-hot-loader/root'
// other imports
import PropTypes from 'prop-types'
import React, { Suspense, useEffect, useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import { Provider } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'
import { DimensionsProvider } from '@lib/dimensions'
import { getLang, loadLang } from '@config/language'

import LoginView from '../user/LoginView'
import AppError from './components/AppError'
import AppLoading from './components/AppLoading'
import AuthRoute from './components/AuthRoute'
import { CacheProvider } from '@emotion/core'
import { PartnersProvider } from '@lib/hooks'

const SignupView = React.lazy(
  () => import(/* webpackChunkName: "signupview" */'../user/SignupView'))
const Logout = React.lazy(
  () => import(/* webpackChunkName: "logoutview" */ '../user/components/Logout'))
const AppBody = React.lazy(
  () => import(
    /* webpackChunkName: "appbody" */
    /* webpackPreload: true */
    './components/AppBody'))

const App = ({ store, persistor, history, client, emotionCache }) => {
  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false)

  useEffect(async () => {
    await loadLang(getLang())
    setIsLanguageLoaded(true)
  }, [])

  const dragDropHandler = (event) => {
    event.preventDefault()
  }

  if (!isLanguageLoaded) {
    return null
  }

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <Suspense fallback={<AppLoading />}>
            <Router history={history}>
              <LastLocationProvider>
                <DimensionsProvider>
                  <CacheProvider value={emotionCache}>
                    <PopupProvider>
                      <div
                        className="app-root"
                        onDragOver={dragDropHandler}
                        onDragLeave={dragDropHandler}
                        onDragEnd={dragDropHandler}
                        onDrop={dragDropHandler}
                      >
                        <Switch>
                          <Route exact path="/signup" component={SignupView} />
                          <Route exact path="/login" component={LoginView} />
                          <Route exact path="/logout" component={Logout} />
                          <PartnersProvider>
                            <AuthRoute
                              loginPath="/login"
                              component={AppBody}
                              loadingComponent={AppLoading}
                              errorComponent={AppError}
                            />
                          </PartnersProvider>
                        </Switch>
                      </div>
                    </PopupProvider>
                  </CacheProvider>
                </DimensionsProvider>
              </LastLocationProvider>
            </Router>
          </Suspense>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  )

}

export default hot(App)

App.propTypes = {
  store:        PropTypes.object,
  persistor:    PropTypes.object,
  history:      PropTypes.object,
  client:       PropTypes.object,
  emotionCache: PropTypes.object,
}