'use strict'

export const SET_RANGE = 'timeline/SET_RANGE'
export const SET_TRACK = 'timeline/SET_TRACK'
export const SET_OPTIONS = 'timeline/SET_OPTIONS'
export const SET_SIZE = 'timeline/SET_SIZE'
export const SET_THEME = 'timeline/SET_THEME'
export const SET_CLASS_NAME = 'timeline/SET_CLASS_NAME'
export const SET_SCALE_BLEND = 'timeline/SET_SCALE_BLEND'
export const ZOOM_LIMIT = 'timeline/ZOOM_LIMIT'
