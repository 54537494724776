'use strict'
import { produce } from 'immer'

import * as types from '../action-types'
import { getInitialState } from './initial-state'
import { setOptions } from './setOptions'
import { setRange } from './setRange'
import { setScaleBlend } from './setScaleBlend'
import { setSize } from './setSize'
import { setTheme } from './setTheme'

export const reducer = produce((prevState, action = {}, scope = 'default') => {

  const state = prevState || getInitialState(scope)
  const _scope = action?.data?.scope || state.scope
  const scopeState = state[_scope]

  switch (action.type) {

    case types.SET_OPTIONS:
      state[_scope] = setOptions(scopeState, action.data)
      break

    case types.SET_THEME:
      state[_scope] = setTheme(scopeState, action.data)
      break

    case types.SET_CLASS_NAME:
      const { className } = action.data
      state[_scope].className = className
      break

    case types.SET_SIZE:
      state[_scope] = setSize(scopeState, action.data)
      break

    case types.SET_SCALE_BLEND:
      state[_scope] = setScaleBlend(scopeState, action.data)
      break

    case types.SET_RANGE:
      state[_scope] = setRange(scopeState, action.data)
      break

    case types.SET_TRACK:
      const { tplId, track } = action.data
      state[_scope].itemsTrack[tplId] = track
      break

    case types.ZOOM_LIMIT:
      const { limit } = action.data
      state[_scope].zoomLimit = limit
      break

    default:
      return state

  }
})
