import React from 'react'

import TimelineItemAnchor from '../../TimelineItemAnchor'

export function getMetrics(layoutOrder, position, availableSpace, mainOffset, rulerSize, contentVisible) {
  let metrics
  switch (layoutOrder) {

    case 'a': {
      // content, items, ruler
      metrics = {
        '-1': {
          rulerEdge: position,
          freeSpace: availableSpace + mainOffset,
        },
        1: {
          rulerEdge: position + rulerSize,
          freeSpace: Math.abs(mainOffset),
        },
      }
      break
    }

    case 'b': {
      // content, ruler, items
      const space = contentVisible ? mainOffset : position - rulerSize + mainOffset
      metrics = {
        '-1': {
          rulerEdge: position - rulerSize,
          freeSpace: Math.max(space, 0),
        },
        1: {
          rulerEdge: position,
          freeSpace: availableSpace - space,
        },
      }
      break
    }

    case 'c':
      // ruler, items, content
      metrics = {
        '-1': {
          rulerEdge: mainOffset,
          freeSpace: Math.max(mainOffset, 0),
        },
        1: {
          rulerEdge: position,
          freeSpace: availableSpace - Math.max(mainOffset, 0),
        },
      }
      break

    case 'd':
      // items, ruler, content
      metrics = {
        '-1': {
          rulerEdge: position,
          freeSpace: position,
        },
        1: {
          rulerEdge: position + rulerSize,
          freeSpace: availableSpace - position,
        },
      }
      break

  }
  return metrics
}

export const timelineItemAnchorFactory = (
  vertical,
  updateLength,
  autoFocus,
  itemHandler,
  customFieldRender
) => (props) => {

  /* eslint-disable react/prop-types */
  let fade = Math.min(Math.max(1 + props.track, 0), 1)
  fade *= Math.min(Math.max(1 - (props.track - props.maxTracks), 0), 1)

  return React.createElement(TimelineItemAnchor, {
    lang:              props.lang,
    key:               props.key,
    item:              props.item,
    template:          props.template,
    margin:            props.margin,
    timePos:           Math.floor(props.timePos),
    itemPos:           props.itemPos,
    track:             props.track,
    groupSize:         props.groupSize,
    trackSpacing:      props.trackSpacing,
    alpha:             props.alpha * fade,
    length:            props.length,
    pointHeight:       props.pointHeight,
    side:              props.side,
    size:              props.size,
    sizeScale:         props.sizeScale,
    timeOffset:        props.timeOffset,
    className:         props.className,
    layoutId:          props.layoutId,
    scale:             props.scale,
    type:              props.type,
    vertical:          vertical,
    zIndex:            props.zIndex,
    autoUpdate:        props.autoUpdate,
    updateLength,
    focus:             props.focus,
    autoFocus:         autoFocus,
    activeClassName:   props.activeClassName,
    itemHandler:       itemHandler,
    customItemRender:  props.customItemRender,
    customFieldRender: customFieldRender,
    customItemStyle:   props.customItemStyle,
  })
  /* eslint-enable */
}

export const parseLayoutItem = (layoutItem, sizeScale) => ({
  timeSpacing: layoutItem?.timeSpacing || 0,
  size:        layoutItem?.size * sizeScale,
  spacing:     (layoutItem?.spacing || 0) * sizeScale,
  trackSide:   layoutItem?.side === 'opposite' ? -1 : 1,
  rulerOffset: (layoutItem?.rulerOffset || 0) * sizeScale,
})

/**
 * set the track group offset on the full stage
 */
export const setTrackGroupOffsetFullStage = (layout, tplId, vertical, trackGroupSize, layoutOrder, trackSide, metrics, distFromEdge) => {
  let groupOffset = 0
  const style = { ...layout.items?.[tplId]?.style }

  if (vertical) {
    style.width = trackGroupSize
    style.height = '100%'

    switch (layoutOrder) {

      case 'a':
      case 'd':
        // items, ruler
        if (trackSide === -1) {
          groupOffset = metrics[trackSide].rulerEdge - distFromEdge[trackSide] - trackGroupSize
        } else {
          groupOffset = metrics[trackSide].rulerEdge + distFromEdge[trackSide]
        }
        style.left = groupOffset
        break

      case 'b':
      case 'c':
        // ruler, items
        if (trackSide === -1) {
          groupOffset = metrics[trackSide].rulerEdge - distFromEdge[trackSide] - trackGroupSize
        } else {
          groupOffset = metrics[trackSide].rulerEdge + distFromEdge[trackSide]
        }
        style.left = groupOffset
        break

    }
  } else {
    // horizontal
    style.height = trackGroupSize
    style.width = '100%'

    switch (layoutOrder) {

      case 'a':
      case 'd':
        // items, ruler
        if (trackSide === -1) {
          groupOffset = metrics[trackSide].rulerEdge - distFromEdge[trackSide] - trackGroupSize
        } else {
          groupOffset = metrics[trackSide].rulerEdge + distFromEdge[trackSide]
        }
        style.top = groupOffset
        break
      case 'b':
      case 'c':
        // ruler, items
        if (trackSide === -1) {
          groupOffset = metrics[trackSide].rulerEdge - distFromEdge[trackSide] - trackGroupSize
        } else {
          groupOffset = metrics[trackSide].rulerEdge + distFromEdge[trackSide]
        }
        style.top = groupOffset
        break

    }
  }
  return style
}
