export type VerticalPlace = 'top' | 'bottom' | 'middle'
export type HorizontalPlace = 'left' | 'right' | 'centre'
export type Place = VerticalPlace | HorizontalPlace

export const parseVertical = (place?: Place): VerticalPlace | undefined =>
  place === 'top' || place === 'middle' || place === 'bottom'
    ? place as VerticalPlace
    : undefined

export const parseHorizontal = (place?: Place): HorizontalPlace | undefined =>
  (place === 'left' || place === 'centre' || place === 'right')
    ? place as HorizontalPlace
    : undefined
