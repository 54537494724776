import { ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

const portalRoot = document.getElementById('portal')

type PortalProps = {
  children: ReactNode,
}

const Portal = ({ children }: PortalProps) => {
  const elRef = useRef<HTMLDivElement | null>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  useEffect(() => {
    elRef.current = document.createElement('div')
    portalRoot?.appendChild(elRef.current)

    return () => {
      if (elRef.current) {
        portalRoot?.removeChild(elRef.current)
      }
    }
  }, [])

  return mounted ? createPortal(children, elRef.current as Element) : null
}

export default Portal
