import './styles/LoginView.css'

import React from 'react'
import { Helmet } from 'react-helmet'

import Login from './components/Login'

export default function LoginView(props = {}) {
  return (
    <div className="login-overlay">
      <Helmet title="Login" />
      <Login {...props} redirectTo={window._leRedirect} />
    </div>
  )
}
