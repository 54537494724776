export const SHOW_IMAGE = 'SHOW_IMAGE'
export const HIDE_IMAGE = 'HIDE_IMAGE'
export const UPDATE_IMAGE = 'UPDATE_IMAGE'

export interface ImageMeta {
  width: number,
  height: number,
  aspectRatio: number,
}

export interface OriginMeta {
  left: number,
  top: number,
  width: number,
  height: number,
}

export interface ShowImageAction {
  type: typeof SHOW_IMAGE,
  data: any,
}

export interface HideImageAction {
  type: typeof HIDE_IMAGE,
  id: number | undefined,
}

export interface UpdateImageAction {
  type: typeof UPDATE_IMAGE,
  id: number,
  origin: any,
  src: ImageMeta,
}

export type ImageViewerActions = ShowImageAction | HideImageAction | UpdateImageAction

function showImage(data: any): ImageViewerActions {
  return {
    type: SHOW_IMAGE,
    data: data,
  }
}

function hideImage(id?: number | undefined): ImageViewerActions {
  return {
    type: HIDE_IMAGE,
    id,
  }
}

function updateImage(id: number, origin: OriginMeta, src: ImageMeta): ImageViewerActions {
  return {
    type:   UPDATE_IMAGE,
    id,
    origin,
    src,
  }
}

export {
  showImage,
  hideImage,
  updateImage,
}
