'use strict'

import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

export default class TimelineSpaceScroll extends PureComponent {

  static propTypes = {
    track:         PropTypes.number,
    tracksVisible: PropTypes.number,
    trackCount:    PropTypes.number,
    trackSide:     PropTypes.number,
    trackGroupId:  PropTypes.string,
    vertical:      PropTypes.bool,
    updateHandler: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.startPos = 0
    this.prevPos = 0
    this.startTrack = 0
    this.scrollTrackLength = 100

    this.state = { interacting: false, track: props.track }
  }

  componentWillUnmount() {
    this.removeEventListeners()
  }

  interactionStart = event => {
    const { vertical } = this.props
    event.preventDefault()
    event.stopPropagation()
    window.document.addEventListener('mousemove', this.interactionUpdate, false)
    window.document.addEventListener('mouseup', this.interactionEnd, false)
    window.document.addEventListener('mouseleave', this.interactionEnd, false)

    const r = this.refTrack.getBoundingClientRect()
    this.scrollTrackLength = vertical ? r.width : r.height
    this.startPos = vertical ? event.clientX : event.clientY

    if (event.target === this.refHandle) {
      // clicked handle so normal drag
    } else {
      // clicked track, so jump to that position
      const hr = this.refHandle.getBoundingClientRect()
      const tp = vertical ? r.left : r.top // track element offset
      const hp = vertical ? hr.left : hr.top // handle element offset
      this.updateTrack(this.startPos - tp - (hp - tp))
    }

    this.prevPos = this.startPos
  }

  interactionUpdate = event => {
    const { vertical } = this.props
    const pos = vertical ? event.clientX : event.clientY
    this.updateTrack(pos - this.prevPos)
    this.prevPos = pos
  }

  interactionEnd = () => {
    this.removeEventListeners()
  }

  updateTrack(dp) {
    const { updateHandler, track, trackGroupId, tracksVisible, trackCount, trackSide } = this.props
    const maxTracks = trackCount - tracksVisible
    const scrollableSpace =
      this.scrollTrackLength - tracksVisible / trackCount * this.scrollTrackLength
    const delta = trackSide * (dp / scrollableSpace) * maxTracks
    const t = Math.min(Math.max(track + delta, 0), maxTracks).toFixed(3) * 1

    if (t !== track) {
      updateHandler(trackGroupId, t)
    }
  }

  removeEventListeners() {
    window.document.removeEventListener('mousemove', this.interactionUpdate)
    window.document.removeEventListener('mouseup', this.interactionEnd)
    window.document.removeEventListener('mouseleave', this.interactionEnd)
  }

  setRefTrack = el => (this.refTrack = el)
  setRefHandle = el => (this.refHandle = el)

  render() {
    const { vertical, track, tracksVisible, trackCount, trackSide } = this.props

    const handleSize = tracksVisible / trackCount * 100
    let pos = track / (trackCount - tracksVisible) * 100
    if (trackSide === -1) {
      pos = 100 - pos
    }
    pos = Math.min(Math.max(pos, 0), 100)
    // console.log('space scrollbar:', track, tracksVisible, trackCount, trackSide, pos);

    const style = {}
    const handleStyle = {}

    if (vertical) {
      handleStyle.width = `${handleSize}%`
      handleStyle.left = `${pos - pos * handleSize / 100}%`
    } else {
      handleStyle.height = `${handleSize}%`
      handleStyle.top = `${pos - pos * handleSize / 100}%`
    }

    const className = `timeline-space-scroll scroll-${vertical ? 'vertical' : 'horizontal'}`

    return (
      <div className={className} onMouseDown={this.interactionStart} style={style}>
        <div className="timeline-space-scroll-track" ref={this.setRefTrack}>
          <span
            className="timeline-space-scroll-handle"
            style={handleStyle}
            ref={this.setRefHandle}
          />
        </div>
      </div>
    )
  }

}
