import { produce } from 'immer'

export const CANCEL_SNIPPET_SELECTION = 'CANCEL_SNIPPET_SELECTION'
export const START_SNIPPET_SELECTION = 'START_SNIPPET_SELECTION'

interface StartSnippetSelectionAction {
  type: typeof START_SNIPPET_SELECTION,
}

interface CancelSnippetSelectionAction {
  type: typeof CANCEL_SNIPPET_SELECTION,
}

type SnippetActions = StartSnippetSelectionAction | CancelSnippetSelectionAction

interface SnippetState {
  edit: boolean,
}

export const startSnippetSelection = (): SnippetActions => {
  return { type: START_SNIPPET_SELECTION }
}

export const cancelSnippetSelection = (): SnippetActions => {
  return { type: CANCEL_SNIPPET_SELECTION }
}

const initialState: SnippetState = {
  edit: false,
}

export const reducer = () => produce((state: SnippetState, action: SnippetActions|null) => {
  switch (action?.type) {

    case START_SNIPPET_SELECTION:
      state.edit = true
      break

    case CANCEL_SNIPPET_SELECTION:
      state.edit = false
      break

  }
}, initialState)

