import * as TimeUtils from '@kpv-lab/time-utils'

import { TimelineRulerProps } from '../../../TimelineRulerTypes'

export const getLabel = (props: TimelineRulerProps, scaleKey: string, current: TimeUtils.TimePoint, type?: string): Array<string> => {
  const { mode, minTime, maxTime, lang = 'en', theme } = props
  const scale = theme[scaleKey]
  const abbr = theme.common.textAbbr === 'full' ? 'FULLMONTHNAMES' : 'MONTHNAMES'
  const dayLabel =
    theme.common.dayLabel === 'full'
      ? 'FULLDAYNAMES'
      : theme.common.dayLabel === 'abbreviated' ? 'DAYNAMES' : ''
  const timeSpan = (maxTime - minTime) * TimeUtils.YEAR
  const skipAbbreviation = ['minor', 'middle'].includes(type || '')
  let value, label, hour, mins, extraLabel

  if (scale.major?.interval) {
    // year scales
    value = current.year
    label = TimeUtils.formatYear(value, { lang, skipAbbreviation })
  } else {
    // subyear scales
    // @ts-ignore
    if (type === 'second' || current.second > 0) {
      value = current.second
      label = `${TimeUtils.pad(true, value)}`
    // @ts-ignore
    } else if (type === 'minute' || current.minute > 0) {
      value = current.minute
      label = `${TimeUtils.pad(true, value)}`
    // @ts-ignore
    } else if (type === 'hour' || current.hour > 0) {
      value = current.hour
      label = `${TimeUtils.pad(true, value)}`
    } else if (type === 'day' || current.day > 0) {
      value = current.day + 1
      label = `${value}`
      if (dayLabel && scaleKey === 'days') {
        if (current.dayOfWeek) {
        // eslint-disable-next-line import/namespace
          extraLabel = TimeUtils[dayLabel][current.dayOfWeek][lang]
        }
      }
    } else if (type === 'month' || current.month > 0) {
      // eslint-disable-next-line import/namespace
      value = TimeUtils[abbr][current.month][lang]
      label = value
    } else {
      value = current.year

      if (mode === 'years') {
        label = TimeUtils.formatYear(value, { lang, skipAbbreviation })
      } else {
        label = '' + value
      }
    }

    // special formatting
    // @ts-ignore
    if (scaleKey === 'hours' && (current.hour > 0 || type === 'hour')) {
      label += ':00'
    } else if (scaleKey === 'minutes') {
      // && current.minute > 0
      hour = TimeUtils.pad(true, current.hour || 0)

      if (timeSpan > TimeUtils.HOUR) {
        // @ts-ignore
        label = current.minute > 0 ? `${hour}:${label}` : `${hour}:00`
      } else {
        label += ':00'
      }
    } else if (scaleKey === 'seconds') {
      mins = TimeUtils.pad(true, current.minute || 0)
      hour = TimeUtils.pad(timeSpan > TimeUtils.DAY, current.hour || 0)

      if (timeSpan > TimeUtils.HOUR) {
        // @ts-ignore
        label = current.second > 0 ? `${hour}:${mins}:${label}` : `${hour}:${mins}:00`
      } else {
        // @ts-ignore
        label = current.second > 0 ? `${mins}:${label}` : `${mins}:00`
      }
    }
  }

  return [label, value as string, extraLabel as string]
}
