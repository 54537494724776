import { authorMarkerStyles, templateIds, templateLayouts } from '@config/content-types'
import { updateArrangement } from '@kpv-lab/le-timeline'
import { isIpad } from '@lib/devices'
import { produce } from 'immer'

import * as types from './action-types'

const skipIntro = isIpad() || !!window.localStorage.getItem('le_skip_intro')

const initialState = {
  comparisonMode:  '',
  currentSection:  '',
  fullscreen:      true,
  introAuthors:    [],
  introVisible:    !skipIntro,
  layout:          {},
  layoutId:        '6',
  layouts:         {},
  nextYear:        0,
  pageSubTitle:    '',
  pageTitle:       '',
  prevYear:        0,
  projectStatus:   '',
  search:          '',
  searchHandler:   null,
  searchLabel:     '',
  sectionLinks:    [],
  showBookmark:    true,
  showFeedback:    true,
  showOnboard:     false,
  showSearch:      true,
  showSnippet:     true,
  sortBy:          'last',
  templates:       {},
  timelineArr:     new Map(),
  timelineCursor:  false,
  timelineFilter:  false,
  timelineItems:   [],
  userComparisons: [],
  view:            {},
}


export const reducer = produce((state, action = {}) => {
  switch (action.type) {

    case types.ONBOARD_TOGGLED:
      state.showOnboard = !state.showOnboard
      break

    case types.SHOW_INTRO: {
      state.introVisible = true
      break
    }

    case types.HIDE_INTRO: {
      state.introVisible = false
      break
    }

    case types.SET_TITLE:
      state.pageTitle = action.data.title
      state.subTitle = action.data.subTitle
      break

    case types.SET_COMPARISON_MODE:
      state.comparisonMode = action.mode
      break

    case types.SET_SECTION_LINKS: {
      const { links, current } = action.data

      state.sectionLinks = links
      state.currentSection = current
      break
    }

    case types.SET_MENU_OPTIONS: {
      const {
        showSearch,
        search,
        searchHandler,
        searchLabel,
        showBookmark,
        showFeedback,
        showSnippet,
        sortBy,
      } = action.data

      state.showSearch = !!showSearch
      state.showBookmark = !!showBookmark
      state.showFeedback = !!showFeedback
      state.showSnippet = !!showSnippet
      state.searchHandler = searchHandler || null
      state.searchLabel = searchLabel || ''
      state.search = search || ''
      state.sortBy = sortBy || 'last'

      break
    }

    case types.PROJECT_REQUEST:
      return { ...state, ...action.data }

    case types.PROJECT_SUCCESS: {
      state.layouts = { ...action.data.layouts }
      state.layoutId = action.data.layoutId
      state.templates = { ...action.data.templates }

      // inject the global template layout configs
      Object.keys(action.data.layouts).forEach(id => {
        state.layouts[id].layout.items = {
          ...state.layouts[id].layout.items,
          ...templateLayouts,
        }
      })

      // inject the author markers
      const layoutId = action.data.layoutId.toString()
      state.templates[templateIds.authorTplId.toString()].styles[layoutId] = authorMarkerStyles
      state.layout = state.layouts[layoutId]

      break
    }

    case types.SET_TIMELINE_ITEMS: {
      const { items, arrangement, cursor } = action.data
      state.timelineItems = items
      state.timelineArr = updateArrangement(items, arrangement)
      state.timelineCursor = cursor || false
      break
    }

    case types.CLEAR_TIMELINE_ITEMS:
      state.timelineItems = []
      break

    case types.FULL_SCREEN:
      state.fullscreen = action.fullscreen
      break

    case types.SET_SUBMENU_ID:
      state.submenuId = action.id
      break

    case types.CLEAR_SEARCH:
      state.search = ''
      break

    case types.SET_SEARCH:
      state.search = action.search
      break

  }
}, initialState)
