import { produce } from 'immer'

const CHANGE_SEARCH_STATE = 'CHANGE_SEARCH_STATE'

interface ChangeSearchStateAction {
  type: typeof CHANGE_SEARCH_STATE,
  data: boolean,
}

interface SearchState {
  active: boolean,
}

type SearchActions = ChangeSearchStateAction

export function changeSearchState(searchActiveState: boolean): SearchActions {
  return {
    type: CHANGE_SEARCH_STATE,
    data: searchActiveState,
  }
}

const initialState: SearchState = {
  active: false,
}

export const reducer = () => {
  return produce((state: SearchState, action: SearchActions|null) => {

    if (action?.type === CHANGE_SEARCH_STATE) {
      state.active = action.data
    }
  }, initialState)
}
