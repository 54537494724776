'use strict'
import { CURRENT_TIME } from '../config/timeline-props.js'

const maxTracks = 350

export function updateArrangement(items, arrangement, idKey) {
  const methods = {
    startTime:         arrangeByStartTime,
    startTimeSeries:   arrangeByStartTimeInSeries,
    durationStartTime: arrangeByDurationThenStartTime,
    endTimeSeries:     arrangeByEndTimeInSeries,
  }
  let minTime = 1e15
  let maxTime = -1e15

  const updatedArrangement = new Map(arrangement)
  updatedArrangement.delete('all')
  updatedArrangement.get('order').forEach(templateId => {
    const method = arrangement.get(templateId)?.method || 'startTime'
    const filteredItems = items.filter((item) => '' + item._tplId === templateId)

    const arrangementMethodResult = methods[method](filteredItems, idKey)
    updatedArrangement.set(templateId, arrangementMethodResult)

    minTime = Math.min(minTime, arrangementMethodResult.minTime)
    maxTime = Math.max(maxTime, arrangementMethodResult.maxTime)
  })

  updatedArrangement.set('minTime', minTime)
  updatedArrangement.set('maxTime', maxTime)

  return updatedArrangement
}

export function arrangeByStartTime(items, idKey = '_id') {
  const current = new Date().getFullYear() + 1
  const limit = -1e-12
  const tracks = { 0: limit }
  const arrangement = {
    minTime:   1e15,
    maxTime:   -1e15,
    method:    'startTime',
    maxTracks: 0,
  }

  // prepare for sorting
  const _items = []
  items.forEach((item, idx) => {
    if (item._start) {
      const id = item[idKey]
      let type = item._display
      const start = item._start
      let end

      // handle end time cases where the type isn't defined
      if (!type) {
        end = item._end || 0
        if (end) {
          type = 'range'
        }
      } else if (type === 'range') {
        end = item._end || 0
      } else {
        end = 0
      }
      end = end === CURRENT_TIME ? current : end || start + 1 / 365

      arrangement.minTime = Math.min(arrangement.minTime, start)
      arrangement.maxTime = Math.max(arrangement.maxTime, end)
      const index = item._index || parseInt(`${idx}`.substr(1) || 0)
      _items.push({ id: id, start: start, end: end, idx: index, type: type })
    }
  })

  // sort by asc start and desc end
  _items.sort((a, b) => a.start - b.start || b.end - a.end || a.idx - b.idx)
  const itemArr = []

  _items.forEach(item => {
    let track = 0

    // TODO expand to allow a max number of tracks for point items
    if (item.type === 'range') {
      while (tracks[track] !== limit && item.start < tracks[track] && track < maxTracks) {
        track++
        if (!tracks[track]) {
          tracks[track] = limit
        }
      }

      tracks[track] = item.end
    }

    arrangement.maxTracks = Math.max(arrangement.maxTracks, track + 1)
    itemArr.push({ id: item.id, track: track })
  })

  arrangement.items = itemArr
  return arrangement
}

export function arrangeByDurationThenStartTime(items, idKey = '_id') {
  const current = new Date().getFullYear() + 1
  const limit = 1e12
  const tracks = { 0: [-limit, limit] }
  const arrangement = {
    minTime:   limit,
    maxTime:   -limit,
    method:    'durationStartTime',
    maxTracks: 0,
  }

  // prepare for sorting
  const _items = []
  items.forEach((item, idx) => {
    if (item._start) {
      const id = item[idKey]
      const type = item._display
      const start = item._start
      let end = type === 'range' ? item._end : 0
      end = end === CURRENT_TIME ? current : end || start + 1

      arrangement.minTime = Math.min(arrangement.minTime, start)
      arrangement.maxTime = Math.max(arrangement.maxTime, end)
      const index = item._index || parseInt(`${idx}`.substr(1) || 0)
      _items.push({ id: id, start: start, end: end, duration: end - start, idx: index, type: type })
    }
  })

  // sort by longest duration then ascending start time
  _items.sort((a, b) => b.duration - a.duration || a.start - b.start || a.idx - b.idx)
  const itemTrack = {}
  let trackKey = 0

  // find the track for each item
  _items.forEach(item => {
    let track = 0

    // TODO expand to allow a max number of tracks for point items
    if (item.type === 'range') {
      // loop through the tracks and find the interval that this item will fit into
      while (track < maxTracks) {
        const intervals = tracks[track]
        if (findGapInIntervals(intervals, item.start, item.end)) {
          intervals.push(item.start, item.end)
          intervals.sort((a, b) => a - b)
          tracks[track] = intervals
          break
        }
        track++
        // tracks.push([-limit, limit]);
        tracks[++trackKey] = [-limit, limit]
      }
    }

    arrangement.maxTracks = Math.max(arrangement.maxTracks, track + 1)
    itemTrack[item.id] = track
  })

  // finally store the resulting order back in ascending time
  _items.sort((a, b) => a.start - b.start || b.end - a.end)
  const itemArr = _items.map(item => ({ id: item.id, track: itemTrack[item.id] }))

  arrangement.items = itemArr
  return arrangement
}

// the intervals are the ranges of time that are free - always come in pairs of numbers
export function findGapInIntervals(intervals, startTime, endTime) {
  const l = intervals.length
  let i = 0

  while (i < l) {
    const s = intervals[i]
    const e = intervals[i + 1]
    if (startTime >= s && endTime <= e) {
      // can fit in the interval
      return true
    }
    i += 2
  }

  return false
}

export function arrangeByStartTimeInSeries(items, idKey = '_id') {
  const current = new Date().getFullYear() + 1
  const arrangement = {
    minTime:   1e15,
    maxTime:   -1e15,
    method:    'startTimeSeries',
    maxTracks: 0,
  }

  // prepare for sorting
  const _items = []
  items.forEach((item, idx) => {
    if (item._start) {
      const id = item[idKey]
      const type = item._display
      const start = item._start
      let end = type === 'range' ? item._end : 0
      end = end === CURRENT_TIME ? current : end || start + 1 / 365

      arrangement.minTime = Math.min(arrangement.minTime, start)
      arrangement.maxTime = Math.max(arrangement.maxTime, end)
      const index = item._index || parseInt(`${idx}`.substr(1) || 0)
      _items.push({ id: id, start: start, end: end, idx: index, type: type })
    }
  })

  // sort by asc start and desc end
  _items.sort((a, b) => a.start - b.start || b.end - a.end || a.idx - b.idx)
  const itemArr = []
  let endDate = -1e-12
  let track = 0

  _items.forEach(item => {
    arrangement.maxTracks = Math.max(arrangement.maxTracks, track + 1)
    itemArr.push({ id: item.id, track: track })
    endDate = Math.max(item.end, endDate)
    track++
  })

  arrangement.items = itemArr
  return arrangement
}

export function arrangeByEndTimeInSeries(items, idKey = '_id') {
  const current = new Date().getFullYear() + 1
  const arrangement = {
    minTime:   1e15,
    maxTime:   -1e15,
    method:    'startTimeSeries',
    maxTracks: 0,
  }

  // prepare for sorting
  const _items = []
  items.forEach((item, idx) => {
    if (item._start) {
      const id = item[idKey]
      const type = item._display
      const start = item._start
      let end = type === 'range' ? item._end : 0
      end = end === CURRENT_TIME ? current : end || start + 1 / 365

      arrangement.minTime = Math.min(arrangement.minTime, start)
      arrangement.maxTime = Math.max(arrangement.maxTime, end)
      const index = item._index || parseInt(`${idx}`.substr(1) || 0)
      _items.push({ id: id, start: start, end: end, idx: index, type: type })
    }
  })

  // sort by asc start and desc end
  _items.sort((a, b) => a.end - b.end || a.start - b.start || a.idx - b.idx)
  const itemArr = []
  let endDate = -1e-12
  let track = 0

  _items.forEach(item => {

    arrangement.maxTracks = Math.max(arrangement.maxTracks, track + 1)
    itemArr.push({ id: item.id, track: track })
    endDate = Math.max(item.end, endDate)
    track++
  })

  arrangement.items = itemArr
  return arrangement
}
