import React, { useEffect, useState } from 'react'

import { CurrentState, PopupContext } from '../lib/popup-context'
import { PopupHint, PopupHintOwnProps } from './PopupHint'

export type PopupProviderOwnProps = {
  delayTimeout?: number,
  enableTranslateTransition?: boolean,
  rootSelector?: string,
  visibilityTimeout?: number,
} & Partial<Pick<PopupHintOwnProps, 'animationDuration' | 'enableCloseButton'>>

let delayTimer: number | undefined = undefined
let visibilityTimer: number | undefined = undefined

export const PopupProvider : React.FC<PopupProviderOwnProps> = ({
  animationDuration = 200,
  children,
  delayTimeout = 500,
  enableCloseButton = false,
  enableTranslateTransition = true,
  rootSelector = 'body',
  visibilityTimeout = 500,
}) => {

  const [current, setCurrent] = useState<CurrentState>({} as CurrentState)
  const [isShown, setIsShown] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [translateTransitionEnabled, setTranslateTransitionEnabled] = useState(false)
  const [popupContainer, setPopupContainer] = useState<HTMLElement>()

  useEffect(() => {
    const container = createContainer()
    setPopupContainer(container)
    return () => {
      container && getRootElement().removeChild(container)
    }
  }, [])

  const _setCurrent = (newCurrent: CurrentState) => {
    if (!popupContainer) {
      throw new Error('Invalid popup container')
    }
    if (enableTranslateTransition) {
      setTranslateTransitionEnabled(
        isShown
        && current.target
        && newCurrent.target
        && current.target !== newCurrent.target
      )
    } else {
      translateTransitionEnabled && setTranslateTransitionEnabled(false)
    }
    popupContainer.className = `popup--hint-container ${newCurrent.containerClass || ''}`
    setCurrent(newCurrent)
  }

  const getRootElement = () => {
    const rootElement = document.querySelector<HTMLElement>(rootSelector)
    if (!rootElement) {
      throw new Error('Modal root element is invalid.')
    }
    return rootElement
  }

  const createContainer = () => {
    const rootElement = getRootElement()

    let container = document.querySelector<HTMLElement>('.popup--hint-container')
    if (!container) {
      container = document.createElement('div')
      container.className = 'popup--hint-container'
      rootElement.appendChild(container)
    }

    return container
  }

  const resetVisibilityTimer = () => {
    if (visibilityTimer) {
      clearTimeout(visibilityTimer)
      visibilityTimer = undefined
    }
  }

  const resetDelayTimer = () => {
    if (delayTimer) {
      clearTimeout(delayTimer)
      delayTimer = undefined
    }
  }

  const show = () => {
    if (isShown) {
      resetVisibilityTimer()
      return
    }

    if (delayTimeout) {
      resetDelayTimer()
      delayTimer = window.setTimeout(() => {
        setIsShown(true)
      }, delayTimeout)
    } else {
      setIsShown(true)
    }
  }

  const hide = () => {
    if (!isShown) {
      resetDelayTimer()
      return
    }

    if (visibilityTimeout) {
      resetVisibilityTimer()
      visibilityTimer = window.setTimeout(() => {
        setIsShown(false)
      }, visibilityTimeout)
    } else {
      setIsShown(false)
    }
  }

  const close = () => {
    resetVisibilityTimer()
    setIsShown(false)
  }

  return <PopupContext.Provider value={{
    hide,
    setCurrent: _setCurrent,
    show,
  }}>
    {popupContainer && (isShown || isVisible) &&
      <PopupHint
        animationDuration={animationDuration}
        container={popupContainer}
        enableCloseButton={enableCloseButton}
        place={current.place}
        alignment={current.alignment}
        targetEl={current.target}
        onClose={close}
        onMouseEnter={show}
        onMouseLeave={hide}
        onWheel={() => close()}
        onVisibilityChange={(visible: boolean) => setIsVisible(visible)}
        isShown={isShown}
        translateTransitionEnabled={translateTransitionEnabled}
      >
        {current.content}
      </PopupHint>
    }
    {children}
  </PopupContext.Provider>
}
