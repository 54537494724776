import * as TimeUtils from '@kpv-lab/time-utils'

import { ThemeOptions, TimelineRulerTheme } from '../../TimelineRulerTypes'
import { priorities } from '../constants'

export const tickType = (
  scaleKey: string,
  current: TimeUtils.TimePoint,
  theme: TimelineRulerTheme,
  scaleTypes: any
): Array<string> => {
  const scale: Partial<ThemeOptions> = theme[scaleKey]
  let types: Array<string> = []

  if (scale.major && scale.major.interval) {
    // year scales
    if (current.year % scale.major.interval === 0) {
      types = ['major']
    } else if (scale?.middle?.interval && current.year % scale.middle.interval === 0) {
      types = ['middle']
    } else if (scale?.minor?.interval && current.year % scale.minor.interval === 0) {
      types = ['minor']
    }
  } else {
    // subyear scales
    const subYearTypes: Array<string> = []

    for (const t of scaleTypes[scaleKey]) {
      if (t !== 'year' && current[t] > 0) {
        subYearTypes.unshift(t)
      } else {
        subYearTypes.push(t)
      }
    }

    // remove types that are larger than the previous
    let i = 0
    const m = subYearTypes.length
    let subYearType = ''

    while (i < m) {
      if (!subYearType || priorities[subYearTypes[i]] < priorities[subYearType]) {
        subYearType = subYearTypes[i]
        types.push(subYearType)
      }
      i++
    }
  }
  return types
}
