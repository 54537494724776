import { useEffect, useState } from 'react'

const defaultOptions = {
  threshold: 0.1,
}

const useIntersectionObserver = (elementRef, options = defaultOptions) => {
  const [isIntersecting, setIsIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting)
    }, options)

    const element = elementRef.current
    if (element) {
      observer.observe(element)
    }

    return () => {
      if (element) {
        observer.unobserve(element)
      }
    }
  }, [elementRef, options])

  return isIntersecting
}

export default useIntersectionObserver
