export default {
  // 'sepia-portrait-vignette': 'fit=facearea&faceindex=1&facepad=5&auto=enhance&mono=867367&bg=FFF&usm=25&mask=https%3A%2F%2Fs3.eu-central-1.amazonaws.com%2Fcls-assets%2Fimages%2Fvignette3.jpg',
  'sepia-portrait-vignette': [
    'fit=facearea',
    'faceindex=1',
    'facepad=5',
    'auto=enhance',
    'mono=867367',
    'usm=25',
    'bs=inherit',
    'bm=normal',
    'bg=CDC6B8',
    'blend-fit=scale',
    'blend=https%3A%2F%2Fs3.eu-central-1.amazonaws.com%2Fcls-assets%2Fimages%2Fmask4.png',
    'fm=jpg',
  ].join('&'),
  // 'sepia-vignette': 'fit=fill&auto=enhance&mono=867367&bg=FFF&usm=25&mask=https%3A%2F%2Fs3.eu-central-1.amazonaws.com%2Fcls-assets%2Fimages%2Fvignette3.jpg',
  'sepia-vignette': [
    'fit=fill',
    'auto=enhance',
    'mono=867367',
    'usm=25',
    'bs=inherit',
    'bm=normal',
    'bg=CDC6B8',
    'blend-fit=scale',
    'blend=https%3A%2F%2Fs3.eu-central-1.amazonaws.com%2Fcls-assets%2Fimages%2Fmask4.png',
    'fm=jpg',
  ].join('&'),
  // 'sepia-sketch': 'fit=fill&mono=867367&bg=FFF&usm=25&mask=https%3A%2F%2Fs3.eu-central-1.amazonaws.com%2Fcls-assets%2Fimages%2Fvignette6.jpg',
  'sepia-sketch': [
    'fit=fill',
    'auto=enhance',
    'mono=867367',
    'usm=25',
    'bs=inherit',
    'bm=normal',
    'bg=CDC6B8',
    'blend-fit=scale',
    'blend=https%3A%2F%2Fs3.eu-central-1.amazonaws.com%2Fcls-assets%2Fimages%2Fmask4.png',
    'fm=jpg',
  ].join('&'),
  'sepia-portrait': [
    'fit=facearea',
    'faceindex=1',
    'facepad=5',
    'auto=enhance',
    'mono=867367',
    'bg=FFF',
    'usm=25',
    'fm=jpg',
  ].join('&'),
  mono:  ['auto=enhance', 'mono=777', 'usm=25', 'fm=jpg'].join('&'),
  sepia: ['auto=enhance', 'mono=867367', 'bg=FFF', 'usm=25', 'fm=jpg'].join(
    '&'
  ),
  enhance: ['auto=enhance', 'usm=25', 'fm=jpg'].join('&'),
}
