import './PopupHint.css'

import { TransitionWrapper, TransitionWrapperOwnProps } from '@kpv-lab/transition-wrapper'
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import { Place } from '../lib/place'
import { calculatePosition, Position } from '../lib/position'

export type PopupHintOwnProps = {
  alignment?: Place,
  enableCloseButton: boolean,
  container: HTMLElement,
  isShown: boolean,
  place: Place,
  targetEl?: HTMLElement,
  translateTransitionEnabled: boolean,
  onClose: () => void,
  onMouseEnter: () => void,
  onMouseLeave: () => void,
  onVisibilityChange?: (visible: boolean) => void,
  onWheel?: () => void,
}
& Partial<Pick<TransitionWrapperOwnProps, 'animationDuration'>>

export const PopupHint: React.FC<PopupHintOwnProps> = ({
  alignment,
  animationDuration,
  enableCloseButton,
  children,
  container,
  isShown,
  place,
  targetEl,
  translateTransitionEnabled,
  onClose,
  onMouseEnter,
  onMouseLeave,
  onWheel,
  onVisibilityChange,
}) => {

  const contentRef = useRef<HTMLDivElement>(null)
  const [position, setPosition] = useState<Position|null>(null)

  useEffect(() => {
    if (onWheel) {
      window.addEventListener('wheel', onWheel)
      contentRef.current?.addEventListener('wheel', forwardEventToUnderlyingElement)
      return () => {
        window.removeEventListener('wheel', onWheel)
        contentRef.current?.removeEventListener('wheel', forwardEventToUnderlyingElement)
      }
    }
  }, [])

  useEffect(() => {
    updatePosition()
  }, [
    alignment,
    container,
    isShown,
    place,
    targetEl,
    translateTransitionEnabled,
  ])

  const _onVisibilityChange = (visible: boolean) => {
    onVisibilityChange && onVisibilityChange(visible)
    updatePosition()
  }

  const _calculatePosition = () => {

    if (contentRef.current && targetEl) {
      return calculatePosition(
        targetEl.getBoundingClientRect(),
        contentRef.current.getBoundingClientRect(),
        place,
        alignment
      )
    }
    return null
  }

  const updatePosition = () => {
    if (!isShown) {
      return
    }

    const newPosition = _calculatePosition()
    if (newPosition && (newPosition.left !== position?.left || newPosition.top !== position?.top)) {
      setPosition(newPosition)
    }
  }

  const forwardEventToUnderlyingElement = (event: WheelEvent) => {
    event.preventDefault()
    event.stopPropagation()
    const elements = document.elementsFromPoint(event.x, event.y)
    const nonPopup = elements.find(el => el.closest('.popup--hint') === null)
    if (nonPopup) {
      nonPopup.dispatchEvent(new WheelEvent('wheel', event))
    }
  }

  const placeClassName = position
    ? `popup--hint-position-${position.place} popup--hint-alignment-${position.alignment}`
    : ''

  return ReactDOM.createPortal(
    <TransitionWrapper
      animationDuration={animationDuration}
      onVisibilityChange={_onVisibilityChange}
      show={isShown}
    >
      <div
        className={`popup--hint ${placeClassName}`}
        data-visible={isShown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={contentRef}
        style={{
          transform: position
            ? `translate(${position.left}px,${position.top}px)`
            : undefined,
          transition: translateTransitionEnabled
            ? 'transform 0.5s cubic-bezier(0, 1, 0, 1) 0s'
            : undefined,
        }}
      >
        {enableCloseButton &&
          <i
            onClick={onClose}
            className="popup--hint-close"
          />
        }
        {children}
      </div>
    </TransitionWrapper>,
    container)

}
