import * as TimeUtils from '@kpv-lab/time-utils'

// linear [0-1] output when v moves between edge 1 & 2
export const step = (v: number, e1: number, e2: number): number => {
  const de = e2 - e1
  return de === 0 ? 1 : Math.min(Math.max((v - e1) / de, 0), 1)
}

export const blend = (a: number, b: number, t: number): number => {
  if (t <= 0) {
    return a
  }

  if (t >= 1) {
    return b
  }

  return a * (1 - t) + b * t
}

export const getPixelRatio = () => {
  return typeof window !== 'undefined' ? window.devicePixelRatio : 1
}

export const keepInRange = (time: TimeUtils.TimePoint) => {
  if (time.second ?? 0 >= 60) {
    time.second = 0
    time.minute = time.minute ? time.minute + 1 : 1
  }

  if (time.minute ?? 0 >= 60) {
    time.minute = 0
    time.hour = time.hour ? time.hour + 1 : 1
  }

  if (time.hour ?? 0 >= 24) {
    time.hour = 0
    time.day += 1
  }

  // @ts-ignore
  if (time.day >= time.daysInMonth) {
    time.day = 0
    time.month += 1
    time.daysInMonth = TimeUtils.MONTHDAYS[time.month] || 31

    if (time.daysInMonth === 28 && time.leap) {
      time.daysInMonth = 29
    }
  }

  if (time.month > 11) {
    time.month = 0
    time.year += 1
    time.leap = TimeUtils.isLeapYear(time.year)
    time.daysInMonth = 31
  }
}
