export const CANVAS_SHAPES = {
  CIRCLE:       'circle',
  SQUARE:       'square',
  DIAMOND:      'diamond',
  LINE:         'line',
  BEZIER_CURVE: 'bezier_curve',
}

export function renderPathsToCanvas(paths, canvas, ctx) {
  if (!paths || !canvas || !ctx) {
    return
  }

  ctx.clearRect(0, 0, canvas.width, canvas.height)

  paths.forEach((p) => {
    const pathValues = Object.entries(p)
    for (const [, value] of pathValues) {
      if (typeof value === 'undefined' || !Object.values(CANVAS_SHAPES).includes(value.type)) {
        continue
      }

      drawPath(value, ctx)
    }
  })
}

function drawPath(props, ctx) {
  const alpha = props.opacity || 1

  ctx.globalAlpha = alpha

  switch (props.type) {

    case CANVAS_SHAPES.CIRCLE:
      drawCircle(props, ctx)
      break
    case CANVAS_SHAPES.SQUARE:
      drawSquare(props, ctx)
      break
    case CANVAS_SHAPES.DIAMOND:
      drawDiamond(props, ctx)
      break
    case CANVAS_SHAPES.LINE:
      drawLine(props, ctx)
      break
    case CANVAS_SHAPES.BEZIER_CURVE:
      drawCurve(props, ctx)
      break

  }
}

function drawCircle(props, ctx) {
  const { x, y, radius = 50, style } = props
  ctx.save()
  ctx.beginPath()
  ctx.arc(x, y, radius, 0, 2 * Math.PI)

  if (style) {
    ctx.fillStyle = style.fill
  }
  ctx.fill()
  ctx.restore()
}

function drawSquare(props, ctx) {
  const { x, y, width: w, height: h, style } = props
  ctx.save()

  if (style) {
    ctx.fillStyle = style.fill
  }
  ctx.fillRect(x, y, w, h)
  ctx.restore()
}

function drawDiamond(props, ctx) {
  const { x, y, radius, style } = props

  ctx.save()
  ctx.beginPath()
  ctx.moveTo(x, y)
  ctx.lineTo(x - radius, y + radius)
  ctx.lineTo(x, y + radius * 2)
  ctx.lineTo(x + radius, y + radius)
  ctx.closePath()

  if (style) {
    ctx.fillStyle = style.fill
  }
  ctx.fill()
  ctx.restore()
}

function drawLine(props, ctx) {
  const { x1, y1, x2, y2, style } = props

  ctx.save()
  ctx.beginPath()
  ctx.moveTo(x1, y1)
  ctx.lineTo(x2, y2)

  if (style) {
    ctx.strokeStyle = style.stroke
    ctx.lineWidth = style.strokeWidth
  }
  ctx.stroke()
  ctx.restore()
}

function drawCurve(props, ctx) {
  const { cp1, cp2, start, end, style } = props

  ctx.save()
  ctx.beginPath()
  ctx.moveTo(start.x, start.y)
  ctx.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, end.x, end.y)

  if (style) {
    ctx.strokeStyle = style.stroke
    ctx.lineWidth = style.strokeWidth
  }
  ctx.stroke()
  ctx.restore()
}
