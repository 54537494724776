import settings from '@config/settings'
import { saveActivity } from '@lib/activity'
import { useEffect } from 'react'

interface Activity {
  itemId: number,
  section: string,
}

let activityTimeout: NodeJS.Timeout

const logActivity = (toSave: Activity) => {
  if (!toSave?.itemId) {
    return
  }
  window.clearTimeout(activityTimeout)
  activityTimeout = setTimeout(() => {
    saveActivity(toSave)
  }, settings.pageViewThreshold)
}

const useSaveActivity = (toSave: Activity) => {
  useEffect(() => {
    logActivity(toSave)
  }, [toSave?.itemId])
  useEffect(() => {
    return () => {
      window.clearTimeout(activityTimeout)
    }
  }, [])
}

export default useSaveActivity
