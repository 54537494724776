export const templateIds = {
  author:        1001,
  essay:         1002,
  publication:   1007,
  comparison:    1008,
  connection:    1009,
  work:          1017,
  quiz:          1018,
  chronologies:  1014,
  tagPage:       1015,
  'daily-event':       1019,
}

export const CURRENT_TIME = 9999
