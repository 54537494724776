/* global VERSION */
import 'core-js/stable'
import './app.css'
import './wdyr'
import './lib/polyfills'
import './lib/suppress-events'

import createCache from '@emotion/cache'
import React from 'react'
import ReactDOM from 'react-dom'

import settings from './config/settings'
import client from './services/apollo'
import history from './services/history'
import configureStore from './services/redux'
import App from './views/app/App'

if (settings.useSentry) {
  require('@sentry/browser').init({
    release:     `le@${VERSION}`,
    dsn:         settings.sentryDsn,
    environment: settings.sentryEnv,
  })
}

console.info(
  '\n%cCHRONO•LOGIC\n%cLiterature Explorer%c',
  'font-size: 16px; font-weight: var(--font-weight-medium); font-family: HelveticaNeue, sans-serif;',
  'font-size: 16px; font-weight: var(--font-weight-normal); font-family: HelveticaNeue, sans-serif;',
  '',
  `\n\nv${VERSION}\n `
)

// this global variable shortcut will help when sizing the UI based on the base font size
window._appBaseSize = parseInt(window.getComputedStyle(document.body).fontSize) || 15

const { store, persistor } = configureStore(window.__INITIAL_STATE__ || {})

const emotionCache = createCache({
  prefix: process.env.NODE_ENV === 'development' ? false : true,
})

ReactDOM.render(
  <App store={store} persistor={persistor} client={client} history={history} emotionCache={emotionCache} />,
  document.getElementById('root')
)
