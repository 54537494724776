import settings from '@config/settings'
import { getCookie, setCookie } from '@lib/cookies'
import React, { createContext, FC, useContext, useEffect, useState } from 'react'

import { Partner } from '../../views/app/components/sub-menu/_types'

interface PartnersContextType {
  selectedPartners: Array<Partner>,
  togglePartner: (partner: Partner) => void,
  clearPartners: () => void,
}
const PartnersContext = createContext<PartnersContextType | undefined>(
  undefined
)


export const usePartners = () => {
  const context = useContext(PartnersContext)
  if (!context) {
    throw new Error('usePartners must be used within a PartnersProvider')
  }
  return context
}

const savePartnersToCookies = (partners: Array<Partner>) => {
  setCookie(settings.partnerKey, JSON.stringify(partners))
}


const loadPartnersFromCookies = (): Array<Partner> => {
  const cookieData = getCookie(settings.partnerKey)
  return cookieData ? JSON.parse(cookieData) : []
}


export const PartnersProvider: FC = ({ children }) => {
  const [selectedPartners, setSelectedPartners] = useState<Array<Partner>>([])

  useEffect(() => {
    const initialPartners = loadPartnersFromCookies()
    setSelectedPartners(initialPartners)
  }, [])


  const togglePartner = (partner: Partner) => {
    const exists = selectedPartners.some((p) => p.id === partner.id)
    if (exists) {
      deselectPartner(partner.id)
      return
    }
    setSelectedPartners((prev) => {
      const updatedPartners = [...prev, partner]
      savePartnersToCookies(updatedPartners)
      return updatedPartners
    })
  }

  const deselectPartner = (id: string) => {
    setSelectedPartners((prev) => {
      const updatedPartners = prev.filter((p) => p.id !== id)
      savePartnersToCookies(updatedPartners)
      return updatedPartners
    })
  }

  const clearPartners = () => {
    setSelectedPartners([])
  }

  return (
    <PartnersContext.Provider
      value={{
        selectedPartners,
        togglePartner,
        clearPartners,
      }}
    >
      {children}
    </PartnersContext.Provider>
  )
}