import PropTypes from 'prop-types'
import React, { forwardRef, PureComponent } from 'react'

import { DimensionsCtx } from './dimensions-provider'

export const withDimensions = WrappedComponent => {
  class Wrapped extends PureComponent {

    static propTypes = {
      forwardedRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
      ]),
    }

    static displayName = `withDimensions(${
      WrappedComponent.displayName || WrappedComponent.name
    })`

    render() {
      const { forwardedRef } = this.props
      return (
        <DimensionsCtx.Consumer>
          {dimensions => (
            <WrappedComponent
              {...this.props}
              {...dimensions}
              ref={forwardedRef}
            />
          )}
        </DimensionsCtx.Consumer>
      )
    }

  }

  return forwardRef((props, ref) => <Wrapped {...props} forwardedRef={ref} />)
}
