import * as TimeUtils from '@kpv-lab/time-utils'

import { TimelineRulerProps } from '../../../TimelineRulerTypes'

export const deriveInfoLabel = (props: TimelineRulerProps, title: string): string => {
  const { mode, startTime, endTime, scale, lang = 'en' } = props
  if (mode === 'seconds') {
    return title
  }
  let _info
  const middleTime = TimeUtils.timeDetails(startTime + (endTime - startTime) / 2)
  const year = TimeUtils.formatYear(middleTime.year, { lang })


  if (scale === 'months' ||  middleTime.year === 0) {
    _info = '' + year
  } else if (scale === 'days') {
    _info = TimeUtils.FULLMONTHNAMES[middleTime.month][lang] + ' ' + year
  } else if (scale === 'hours' || scale === 'minutes' || scale === 'seconds') {
    _info =
      middleTime.day + 1 + ' ' + TimeUtils.FULLMONTHNAMES[middleTime.month][lang] + ' ' + year
  } else {
    _info = ''
  }

  return _info
}
