'use strict'

import { getImgixURL } from '@kpv-lab/image-utils'
import PropTypes from 'prop-types'
import React from 'react'

import { CURRENT_TIME } from '../../../../cls/cl-studio/src/app/config/constants'
import FormattedDate from './FormattedDate'

export default function FieldBlock(props) {
  const { size, field, val, style, onload, length, itemPos, customRender, vertical, lang } = props
  let className = 'item'

  if (style._rotation) {
    className += ' item-rotate-' + style._rotation
  }

  if (/date/i.test(field.type)) {
    // date fields

    const str = val.value ? val.value : (typeof val === 'string' || typeof val === 'number') ? val : CURRENT_TIME

    const date = <FormattedDate str={str} lang={lang} />
    let _props = {
      className:       `${className} item-date item-${field.id}`,
      style:           style,
      'data-field-id': field.id,
      key:             `date-${field.id}`,
    }

    if (customRender) {
      _props = customRender(field, date, _props, length, size, itemPos, vertical)
    }

    return <span {..._props}>{date}</span>
  } else if (field.type === 'image') {
    // image fields
    if (typeof val === 'object' && val.path) {
      let w = style.width
      const h = style.height

      if (!w && !h) {
        w = size
      }

      const resizedImage = getImgixURL(val, {
        targetWidth:  w,
        targetHeight: h,
        fit:          'crop',
      })
      const aspect = resizedImage.width / resizedImage.height
      const stl =
        aspect > 1 ? { maxWidth: '100%', height: 'auto' } : { maxHeight: '100%', width: 'auto' }

      const img = (
        <img
          src={resizedImage.path}
          alt=""
          width={resizedImage.width}
          height={resizedImage.height}
          onLoad={onload}
          style={stl}
        />
      )
      let _props = {
        className:       `${className} item-image item-${field.id}`,
        style:           style,
        'data-field-id': field.id,
      }

      if (customRender) {
        _props = customRender(field, img, _props, length, size, itemPos, vertical)
      }

      return <span {..._props}>{img}</span>
    } else {
      // return <noscript></noscript>;
      return false
    }
  } else if (field.type === 'video' && val) {
    // video fields
    if (val.url) {
      return (
        <span>
          <i className="material-icons md-18">video_library</i>
        </span>
      )
    } else {
      return <span />
    }
  } else if (/richtext|body/.test(field.type)) {
    return (
      <div
        className={`${className} item-${field.type} item-${field.id}`}
        style={style}
        data-field-id={field.id}
        dangerouslySetInnerHTML={{ __html: val }}
      />
    )
  } else if (val) {
    // everything else
    let text = (typeof val === 'string' || Array.isArray(val))
      ? val
      : val.value || ''

    let _props = {
      className:       `${className} item-${field.type} item-${field.id}`,
      style:           style,
      'data-field-id': field.id,
    }

    if (customRender) {
      _props = customRender(field, text, _props, length, size, itemPos, vertical)

      // a little quirk for the cases where we want to change the text,
      // like only showing the surname instead of the full name
      if (_props._text) {
        text = _props._text
        delete _props._text
      }
    }

    return <span {..._props}>{text}</span>
  } else {
    // return <noscript></noscript>;
    return false
  }
}

FieldBlock.propTypes = {
  id:           PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style:        PropTypes.object,
  size:         PropTypes.number,
  length:       PropTypes.number,
  itemPos:      PropTypes.number,
  field:        PropTypes.object,
  lang:         PropTypes.string,
  vertical:     PropTypes.bool,
  onload:       PropTypes.func,
  customRender: PropTypes.func,
  val:          PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
}
