import { rgbaString } from '@kpv-lab/color-utils'

import { StyleOptions, TimelineRulerTheme } from '../../TimelineRulerTypes'
import { getPixelRatio } from '../rulerHelpers'

export const getStyle = (
  scaleKey: string,
  type: string,
  theme: TimelineRulerTheme,
  scales: Array<string>
): StyleOptions => {

  const styles: {[key: string]: StyleOptions} = {}

  let skey = scaleKey
  let key = `${skey}-${type}`

  const pixelRatio = getPixelRatio()
  if (!styles[key]) {
    const themeRange = theme[skey] || theme.years
    let themeScale: StyleOptions = themeRange[type] || themeRange.minor || themeRange.year

    if (!themeScale) {
      let idx = scales.indexOf(skey) + 1
      while (idx < scales.length) {
        skey = scales[idx]

        if (theme[skey] && theme[skey][type]) {
          themeScale = theme[skey][type]
          break
        }
        idx++
      }

      key = `${skey}-${type}`
    }

    styles[key] = {
      interval:       themeScale.interval,
      tickPos:        themeScale.tickPos,
      tickLength:     themeScale.tickLength,
      tickThickness:  themeScale.tickThickness * pixelRatio,
      tickColor:      themeScale.tickColor,
      tickAlpha:      themeScale.tickAlpha,
      labelColor:     themeScale.labelColor,
      labelAlpha:     themeScale.labelAlpha,
      labelWeight:    themeScale.labelWeight,
      labelSize:      themeScale.labelSize * pixelRatio,
      labelPos:       themeScale.labelPos,
      labelOffset:    themeScale.labelOffset,
      labelRotation:  themeScale.labelRotation,
      labelPlacement: parseFloat(themeScale.labelPlacement as string) || 0,
      cell1Color:     themeScale.cell1Color || '#FFFFFF',
      cell1Alpha:     themeScale.cell1Alpha || 0,
      cell2Color:     themeScale.cell2Color || '#FFFFFF',
      cell2Alpha:     themeScale.cell2Alpha || 0,
      outline:        themeScale.outline * pixelRatio,
      outlineColor:   themeScale.outlineColor,
      outlineAlpha:   themeScale.outlineAlpha || 0,
      shadowAlpha:    themeScale.shadowAlpha || 0,
      shadowColor:    themeScale.shadowColor || '#000000',
      shadowX:        (themeScale.shadowX || 0) * pixelRatio,
      shadowY:        (themeScale.shadowY || 0) * pixelRatio,
      shadowBlur:     (themeScale.shadowBlur || 0) * pixelRatio,
    }

    styles[key]._labelAlpha = styles[key].labelAlpha
    styles[key]._outlineAlpha = styles[key].outlineAlpha

    if (styles[key].tickColor && styles[key].tickColor.indexOf('#') !== 0) {
      styles[key].tickColor = `#${styles[key].tickColor}`
    }

    if (styles[key].labelColor && styles[key].labelColor.indexOf('#') !== 0) {
      styles[key].labelColor = `#${styles[key].labelColor}`
    }

    if (styles[key].outlineColor && styles[key].outlineColor.indexOf('#') !== 0) {
      styles[key].outlineColor = `#${styles[key].outlineColor}`
    }

    if (styles[key].shadowColor && styles[key].shadowColor.indexOf('#') !== 0) {
      styles[key].shadowColor = `#${styles[key].shadowColor}`
    }

    if (styles[key].cell1Color && styles[key].cell1Color.indexOf('#') !== 0) {
      styles[key].cell1Color = `#${styles[key].cell1Color}`
    }

    if (styles[key].cell2Color && styles[key].cell2Color.indexOf('#') !== 0) {
      styles[key].cell2Color = `#${styles[key].cell2Color}`
    }

    styles[key].shadowColorAlpha = rgbaString(
      styles[key].shadowColor,
      styles[key].shadowAlpha
    )

  }
  return styles[key]
}
