'use strict'

import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { CANVAS_SHAPES, renderPathsToCanvas } from '../utils/canvas-helpers'
export default class CanvasMarkers extends Component {

  static propTypes = {
    className:    PropTypes.string,
    width:        PropTypes.number,
    length:       PropTypes.number,
    edge:         PropTypes.number,
    markers:      PropTypes.array.isRequired,
    styles:       PropTypes.object.isRequired,
    side:         PropTypes.number,
    vertical:     PropTypes.bool,
    group:        PropTypes.number,
    sizeScale:    PropTypes.number,
    customMarker: PropTypes.func,
    alwaysPath:   PropTypes.bool,
  }

  static defaultProps = {
    sizeScale: 1,
  }

  constructor(props) {
    super(props)
    this.refLines = React.createRef()
    this.canvasLines = null
    this.linesCtx = null
    this.pixelRatio = 1
  }

  componentDidMount() {
    this.canvasLines = this.refLines.current
    this.linesCtx = this.canvasLines.getContext('2d')
    this.pixelRatio = this.getDevicePixelRatio()
  }

  componentDidUpdate(prevProps) {
    const { width, length } = this.props
    if (prevProps.width !== width || prevProps.length !== length) {
      this.pixelRatio = this.getDevicePixelRatio()
    }

    const canvasMarkers = this.getMarkers()
    renderPathsToCanvas(canvasMarkers, this.canvasLines, this.linesCtx)
  }

  getDevicePixelRatio() {
    return window.devicePixelRatio || 1
  }

  getMarkers = () => {
    const {
      markers,
      styles,
      vertical,
      width,
      side,
      group,
      customMarker,
      sizeScale,
      alwaysPath,
    } = this.props

    if (!markers || !styles) {
      return
    }

    // marker format:
    // {
    //   t: 334.567,    // pixel position in time axis from the start of the timeline ruler
    //   d: 20.1,       // pixel delta between start and end of marker line in time axis
    //   p: 200,        // position in space (often the distance from ruler edge + the track pos)
    //   k: 'inside',   // type of marker for index on styles object
    //   a: 1           // alpha
    // }

    const markersArry = []
    const pxr = this.getDevicePixelRatio()

    let pp = -100

    markers.forEach((marker) => {
      let x1, y1, xc1, yc1, x2, y2, xc2, yc2

      const d = marker.d || 0
      const k = marker.k

      const shape         = styles[k].shape
      const ends          = styles[k].ends
      const offset1       = (styles[k].offset1 || 0) * sizeScale  // offset from ruler edge
      const offset2       = (styles[k].offset2 || 0) * sizeScale  // offset from item
      const offset3       = styles[k].offset3 || 0                // endpoint offset in time direction
      const radius        = styles[k].radius * pxr || 0
      const skipThreshold = group || radius
      const curviness     = styles[k].curviness || 0
      const lineStyle     = styles[k].line
      const markerStyle   = styles[k].marker
      const start         = /s$/.test(k) ? 1 : -1
      const inRange       = !marker.o

      const f =
        curviness === 0
          ? 0.01
          : Math.min(Math.max(Math.abs(d) / ((marker.p - offset1 - offset2) * curviness), 0), 1) *
            curviness

      if (vertical) {
        x1 = offset1
        y1 = marker.t

        if (!inRange && y1 - pp < skipThreshold) {
          return
        }
        pp  = y1
        x2  = marker.p - offset2
        xc1 = x2 * f
        xc2 = x2 - xc1
        y2  = y1 + d + start * offset3
        yc2 = y2
        yc1 = y1

        if (side < 0) {
          x1  = width - x1
          x2  = width - x2
          xc1 = width - xc1
          xc2 = width - xc2
        }
      } else {
        y1 = offset1
        x1 = marker.t

        if (!inRange && x1 - pp < skipThreshold) {
          return
        }
        pp = x1

        yc1 = (marker.p - offset2) * f
        xc1 = x1
        y2  = marker.p - offset2
        x2  = x1 + d + start * offset3
        yc2 = marker.p - offset2 - yc1
        xc2 = x2

        if (side < 0) {
          y1  = width - y1
          y2  = width - y2
          yc1 = width - yc1
          yc2 = width - yc2
        }
      }

      x1  = x1.toFixed(1) * 1 * pxr
      x2  = x2.toFixed(1) * 1  * pxr
      xc1 = xc1.toFixed(1) * 1 * pxr
      xc2 = xc2.toFixed(1) * 1 * pxr
      y1  = y1.toFixed(1) * 1 * pxr
      y2  = Math.floor(y2) * pxr
      yc1 = yc1.toFixed(1) * 1 * pxr
      yc2 = yc2.toFixed(1) * 1 * pxr

      const alpha =  typeof marker.a === 'undefined' ? 1 : marker.a

      let path, startDot, endDot

      if (shape && radius > 0 && ends !== 'none' && alpha > 0) {
        if (!ends || ends === 'start') {
          switch (shape) {

            case CANVAS_SHAPES.SQUARE:
              startDot = {
                type:   CANVAS_SHAPES.SQUARE,
                x:      x1 - radius,
                y:      y1 - radius,
                width:  radius * 2,
                height: radius * 2,
                style:  markerStyle,
              }
              break
            case CANVAS_SHAPES.DIAMOND:
              startDot = {
                type:  CANVAS_SHAPES.DIAMOND,
                x:     x1 - radius,
                y:     y1 - radius,
                radius,
                style: markerStyle,
              }
              break
            default:
              startDot = {
                type:  CANVAS_SHAPES.CIRCLE,
                x:     x1,
                y:     y1,
                radius,
                style: markerStyle,
              }

          }
        }

        if (inRange && (!ends || ends === 'end')) {
          switch (shape) {

            case CANVAS_SHAPES.SQUARE:
              endDot = {
                type:    CANVAS_SHAPES.SQUARE,
                x:       x2 - radius,
                y:       y2 - radius,
                width:   radius * 2,
                height:  radius * 2,
                style:   markerStyle,
                opacity: alpha,
              }
              break
            case CANVAS_SHAPES.DIAMOND:
              endDot = {
                type:    CANVAS_SHAPES.DIAMOND,
                x:       x2 - radius,
                y:       y2 - radius,
                radius,
                style:   markerStyle,
                opacity: alpha,
              }
              break
            default:
              endDot = {
                type:    CANVAS_SHAPES.CIRCLE,
                x:       x2,
                y:       y2,
                radius,
                style:   markerStyle,
                opacity: alpha,
              }

          }
        }
      }

      if (inRange && lineStyle.strokeWidth && alpha > 0) {
        const strokeWidth = lineStyle.strokeWidth * pxr
        y1 += strokeWidth / 2
        y2 += strokeWidth / 2
        if (Math.abs(y2 - y1) < 5 && !alwaysPath) {
          let _props = {
            x1,
            y1,
            x2,
            y2,
            style:   { ...lineStyle, strokeWidth },
            opacity: alpha,
          }

          if (customMarker) {
            _props = customMarker(CANVAS_SHAPES.LINE, marker, _props)
          }

          path = { ..._props, type: CANVAS_SHAPES.LINE }
        } else {
          const xa = shape === CANVAS_SHAPES.DIAMOND && ends === 'start' ? radius : 0
          const xb = shape === CANVAS_SHAPES.DIAMOND && ends === 'end' ? radius : 0
          let _props = {
            d:       `M${x1 + xa},${y1} C${xc1},${yc1} ${xc2},${yc2} ${x2 - xb},${y2}`, // can be removed once we update the customMarker() method
            cp1:     { x: xc1, y: yc1 },
            cp2:     { x: xc2, y: yc2 },
            start:   { x: x1 + xa, y: y1 },
            end:     { x: x2 - xb, y: y2 },
            style:   { ...lineStyle, strokeWidth },
            opacity: alpha,
          }

          if (customMarker) {
            _props = customMarker(CANVAS_SHAPES.PATH, marker, _props)
          }

          path = { ..._props, type: CANVAS_SHAPES.BEZIER_CURVE }
        }
      }

      if (path || startDot) {
        markersArry.push({ path, startDot, endDot })
      }
    })

    return markersArry
  }

  render() {
    const {
      vertical,
      width,
      length,
      edge,
      side,
      className,
    } = this.props

    const pxr = this.pixelRatio
    const canvasStyles = {}

    let w, h
    if (vertical) {
      w = width
      h = length

      if (side > 0) {
        canvasStyles.left = `${edge}px`
      } else {
        canvasStyles.left = `${edge - width}px`
      }
    } else {
      w = length
      h = width
      if (side > 0) {
        canvasStyles.top = `${edge}px`
      } else {
        canvasStyles.top = `${edge - width}px`
      }
    }

    const w2 = w * pxr
    const h2 = h * pxr

    const _className = `timeline-markers ${className || ''}`

    return (
      <canvas
        ref={this.refLines}
        className={_className}
        width={w2}
        height={h2}
        style={{ ...canvasStyles,  width: `${w}px`, height: `${h}px` }}
      />
    )
  }

}
