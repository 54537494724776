import { rgbaString } from '@kpv-lab/color-utils'

import { TimelineRulerProps } from '../../../TimelineRulerTypes'
import { getPixelRatio } from '../../rulerHelpers'

export const drawInfoLabel = (str: string, props: TimelineRulerProps, ctxTitle: CanvasRenderingContext2D) => {
  const { rulerSize, length, theme, orientation } = props
  const pixelRatio = getPixelRatio()
  const common = theme.common
  const w = rulerSize * pixelRatio
  let x, y

  if (!ctxTitle) {
    console.warn('drawInfoLabel: ctxTitle undefined')
    return
  }

  ctxTitle.clearRect(0, 0, w, length * pixelRatio)
  ctxTitle.textBaseline = 'middle'
  ctxTitle.textAlign = common.titleAlign as CanvasTextAlign
  ctxTitle.lineJoin = common.lineJoin

  if (orientation === 'horizontal') {
    x = Math.floor(w * (1 - common.titleOffset))
  } else {
    x = Math.floor(w * common.titleOffset)
  }

  y = Math.floor(length * pixelRatio * common.titlePos)
  ctxTitle.save()
  ctxTitle.translate(x, y)
  ctxTitle.rotate(-Math.PI / 2)

  x = 0
  y = 0

  const font =
    common.titleWeight +
    ' ' +
    common.titleStyle +
    ' ' +
    common.titleSize * pixelRatio +
    'px ' +
    common.titleFont
  ctxTitle.font = font

  if (common.titleShadowAlpha && common.titleShadowAlpha > 0) {
    ctxTitle.shadowColor = rgbaString(common.titleShadowColor, common.titleShadowAlpha)
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    ctxTitle.shadowOffsetX = common.titleShadowX! * pixelRatio
    ctxTitle.shadowOffsetY = common.titleShadowY! * pixelRatio
    ctxTitle.shadowBlur = common.titleShadowBlur! * pixelRatio
    /* eslint-enable */
  }

  if (common.titleOutline > 0 && common.titleOutlineAlpha > 0) {
    ctxTitle.globalAlpha = common.titleOutlineAlpha
    ctxTitle.strokeStyle = common.titleOutlineColor
    ctxTitle.lineWidth = common.titleOutline * pixelRatio
    ctxTitle.strokeText(str, x, y, length * pixelRatio)
  }

  if (common.titleAlpha > 0) {
    ctxTitle.globalAlpha = common.titleAlpha
    ctxTitle.fillStyle = common.titleColor
    ctxTitle.fillText(str, x, y, length * pixelRatio)
  }

  return ctxTitle.restore()
}
