import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

export default class TimelineCursor extends PureComponent {

  static propTypes = {
    position: PropTypes.number,
    edge:     PropTypes.number,
    side:     PropTypes.number,
    styles:   PropTypes.object,
    vertical: PropTypes.bool,
  }

  render() {
    const { position, edge, styles, vertical, side } = this.props
    const s = styles || {}
    const length = s.length || 100
    const thickness = s.thickness || 2
    let dir

    let style
    if (vertical) {
      style = {
        width:     `${length}%`,
        height:    thickness,
        marginTop: -Math.floor(thickness / 2),
        top:       position,
      }

      if (side === 1) {
        style.left = edge + (s.offset || 0)
        dir = 'to right'
      } else {
        style.left = `calc(${edge - (s.offset || 0)}px - ${style.width})`
        dir = 'to left'
      }
    } else {
      style = {
        height:     `${length}%`,
        width:      thickness,
        marginLeft: -Math.floor(thickness / 2),
        left:       position,
      }

      if (side === 1) {
        style.top = edge + (s.offset || 0)
        dir = 'to bottom'
      } else {
        style.top = `calc(${edge - (s.offset || 0)}px - ${style.height})`
        dir = 'to top'
      }
    }

    if (s.color1 && s.color2) {
      style.backgroundImage = `linear-gradient(${dir}, ${s.color1} 0%, ${s.color2} 100%)`
    } else {
      style.backgroundColor = s.color1
    }

    if (s.shadowX || s.shadowY || s.shadowBlur || s.shadowSpread) {
      style.boxShadow = `${s.shadowX || 0}px ${s.shadowY || 0}px ${s.shadowBlur ||
        0}px ${s.shadowSpread || 0}px ${s.shadowColor || '#000'}`
    }

    return <div className="timeline-cursor" style={style} />
  }

}
