import { blendColors, rgbaString } from '@kpv-lab/color-utils'

import { StyleOptions } from '../../TimelineRulerTypes'
import { blend } from '../rulerHelpers'

export const blendOutStyle = (style1: StyleOptions, style2: StyleOptions, blendOut: number): Partial<StyleOptions> => {
  const attrs: Partial<StyleOptions> = {}
  attrs.tickAlpha = blend(style1.tickAlpha, style2.tickAlpha, blendOut)

  if (attrs.tickAlpha > 0) {
    attrs.tickColor = blendColors(style1.tickColor, style2.tickColor, blendOut) as string
  }

  attrs.tickPos = blend(style1.tickPos, style2.tickPos, blendOut)
  attrs.tickLength = blend(style1.tickLength, style2.tickLength, blendOut)
  attrs.tickThickness = blend(style1.tickThickness, style2.tickThickness, blendOut)
  attrs.labelSize = Number(blend(style1.labelSize, style2.labelSize, blendOut).toFixed(3))
  attrs.labelWeight = style2.labelWeight
  attrs.labelOffset = blend(style1.labelOffset, style2.labelOffset, blendOut)
  attrs.labelRotation = Number(blend(style1.labelRotation, style2.labelRotation, blendOut).toFixed(3))
  attrs.labelPos = blend(style1.labelPos, style2.labelPos, blendOut)
  attrs.labelPlacement = Number(blend(style1.labelPlacement as number, style2.labelPlacement as number, blendOut).toFixed(3))
  attrs.labelAlpha = blend(style1.labelAlpha, style2.labelAlpha, blendOut)
  attrs._labelAlpha = attrs.labelAlpha

  if (attrs.labelAlpha > 0) {
    attrs.labelColor = blendColors(style1.labelColor, style2.labelColor, blendOut) as string
  }

  attrs.outline = Number(blend(style1.outline, style2.outline, blendOut).toFixed(3))
  attrs.outlineAlpha = blend(style1.outlineAlpha, style2.outlineAlpha, blendOut)
  attrs._outlineAlpha = attrs.outlineAlpha

  if (attrs.outlineAlpha > 0 && attrs.outline > 0) {
    attrs.outlineColor = blendColors(style1.outlineColor, style2.outlineColor, blendOut) as string
  }

  attrs.cell1Alpha = blend(style1.cell1Alpha || 0, style2.cell1Alpha || 0, blendOut)
  if (attrs.cell1Alpha > 0) {
    attrs.cell1Color = blendColors(style1.cell1Color, style2.cell1Color, blendOut) as string
  }

  attrs.cell2Alpha = blend(style1.cell2Alpha || 0, style2.cell2Alpha || 0, blendOut)
  if (attrs.cell2Alpha > 0) {
    attrs.cell2Color = blendColors(style1.cell2Color, style2.cell2Color, blendOut) as string
  }

  attrs.shadowAlpha = blend(style1.shadowAlpha, style2.shadowAlpha, blendOut)
  if (attrs.shadowAlpha > 0) {
    attrs.shadowColor = blendColors(style1.shadowColor, style2.shadowColor, blendOut) as string
    attrs.shadowColorAlpha = rgbaString(attrs.shadowColor, attrs.shadowAlpha) as string
  }

  attrs.shadowX = blend(style1.shadowX, style2.shadowX, blendOut)
  attrs.shadowY = blend(style1.shadowY, style2.shadowY, blendOut)
  attrs.shadowBlur = blend(style1.shadowBlur, style2.shadowBlur, blendOut)

  return attrs
}
