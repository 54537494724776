import {
  scaleBlend,
  timeLimits
} from '../../utils/action-helpers'
import { defaultState } from './initial-state'

export const setRange = (state, data) => {
  const { startTime, endTime, tplId, track } = data
  let newState = state || { ...defaultState }

  if (tplId && track >= 0) {
    newState.itemsTrack[tplId] = track
  }

  newState = {
    ...newState,
    ...timeLimits(newState, startTime, endTime),
  }

  return {
    ...newState,
    ...scaleBlend(newState),
  }
}
