'use strict'
/* eslint "react/prop-types": 0 */

import React from 'react'

export default function TimelineItemsBackground({ style, textureHost }) {
  if (style && style.bgTexture) {
    style.backgroundImage = `url(${textureHost || ''}${style.bgTexture})`
    delete style.bgTexture
  }

  return <section className="timeline-group-bg" style={style} />
}
