'use strict'

export default function mergeStyles(obj, scale, length, size, focus, textureHost) {
  if (!obj) {
    return {}
  }

  const styleGroups = ['common', 'commonCustom', scale, `${scale}Custom`]
  const medLimit = obj.commonMediumLimit || 120
  const smlLimit = obj.commonSmallLimit || 50

  if (length <= medLimit) {
    styleGroups.push('commonMedium', 'commonMediumCustom', `${scale}Medium`, `${scale}MediumCustom`)
  }

  if (length <= smlLimit || size < 10) {
    styleGroups.push('commonSmall', 'commonSmallCustom', `${scale}Small`, `${scale}SmallCustom`)
  }

  if (focus) {
    styleGroups.push('commonFocus', 'commonFocusCustom', `${scale}Focus`, `${scale}FocusCustom`)
  }

  let styles = styleGroups.map(k => obj[k]).filter(s => s)

  styles = Object.assign({}, ...styles)

  if (styles.backgroundImage) {
    styles.backgroundImage = styles.backgroundImage.replace(/^url\(/, `url(${textureHost || ''}`)
  }

  if (styles.boxShadowEnabled !== '1') {
    delete styles.boxShadow
  }
  delete styles.boxShadowEnabled

  if (styles.textShadowEnabled !== '1') {
    delete styles.textShadow
  }
  delete styles.textShadowEnabled

  if (
    styles._translateX ||
    styles._translateY ||
    styles._translateZ ||
    styles._rotateX ||
    styles._rotateY ||
    styles._rotateZ
  ) {
    styles.transform = `translate3d(${styles._translateX || 0}px, ${styles._translateY ||
      0}px, ${styles._translateZ || 0}px) rotateX(${styles._rotateX ||
      0}deg) rotateY(${styles._rotateY || 0}deg) rotateZ(${styles._rotateZ || 0}deg)`
  }

  if (
    typeof styles._transformOriginX !== 'undefined' ||
    typeof styles._transformOriginY !== 'undefined'
  ) {
    styles.transformOrigin = `${styles._transformOriginX || 0}% ${styles._transformOriginY || 0}%`
  }

  delete styles._translateX
  delete styles._translateY
  delete styles._translateZ
  delete styles._rotateX
  delete styles._rotateY
  delete styles._rotateZ

  if (styles.display !== 'none' && styles.alignItems) {
    styles.display = 'flex'
  }

  return styles
}
