import { CURRENT_TIME } from '../../../cls/cl-studio/src/app/config/constants'
export type TimePoint = {
  year: number,
  month: number,
  day: number,
  hour?: number,
  minute?: number,
  second?: number,
  ms?: number,
  dayOfWeek?: number,
  dayOfYear?: number,
  days?: number,
  daysInMonth?: number,
  leap?: boolean,
  secs?: number,
  totalSecs?: number,
};

export interface DateToStringParams {
  full: boolean,
  lang: 'en' | 'de',
  ordinal?: boolean,
}

export const SEC = 1
export const MIN = 60
export const HOUR = MIN * 60
export const DAY = HOUR * 24
export const WEEK = DAY * 7
export const YEAR = DAY * 365
export const LEAPYEAR = DAY * 366
export const MONTHDAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
export const INVYEAR = 1 / YEAR

export const MONTHNAMES = [
  { en: 'Jan', de: 'Jan.' },
  { en: 'Feb', de: 'Feb.' },
  { en: 'Mar', de: 'Mär.' },
  { en: 'Apr', de: 'Apr.' },
  { en: 'May', de: 'Mai' },
  { en: 'Jun', de: 'Jun.' },
  { en: 'Jul', de: 'Jul.' },
  { en: 'Aug', de: 'Aug.' },
  { en: 'Sep', de: 'Sep.' },
  { en: 'Oct', de: 'Okt.' },
  { en: 'Nov', de: 'Nov.' },
  { en: 'Dec', de: 'Dez.' },
]

export const FULLMONTHNAMES = [
  { en: 'January', de: 'Januar' },
  { en: 'February', de: 'Februar' },
  { en: 'March', de: 'März' },
  { en: 'April', de: 'April' },
  { en: 'May', de: 'Mai' },
  { en: 'June', de: 'Juni' },
  { en: 'July', de: 'Juli' },
  { en: 'August', de: 'August' },
  { en: 'September', de: 'September' },
  { en: 'October', de: 'Oktober' },
  { en: 'November', de: 'November' },
  { en: 'December', de: 'Dezember' },
]

export const DAYNAMES = [
  { en: 'Sun' },
  { en: 'Mon' },
  { en: 'Tue' },
  { en: 'Wed' },
  { en: 'Thu' },
  { en: 'Fri' },
  { en: 'Sat' },
]

export const FULLDAYNAMES = [
  { en: 'Sunday' },
  { en: 'Monday' },
  { en: 'Tuesday' },
  { en: 'Wednesday' },
  { en: 'Thursday' },
  { en: 'Friday' },
  { en: 'Saturday' },
]

export const DAY_DELIMITER = {
  en: '',
  de: '.',
}

export const TIME_ABBREVIATION = {
  AD:   { en: 'AD', de: '' },
  BC:   { en: 'BC', de: 'v. Chr.' },
  CE:   { en: 'CE', de: '' },
  ZERO: { en: 'CE', de: '0' },
  MA:   { en: 'Ma', de: 'Ma' },
  KA:   { en: 'ka', de: 'ka' },
  BA:   { en: 'Ba', de: 'Ba' },
}

export function today() {
  const d = new Date()
  return `${d.getFullYear()}_${d.getMonth() + 1}_${d.getDate()}`
}

export function todayFloat(): number {
  return toFloatTime(today())
}

export function dateToString(
  str,
  langParam?: string,
  type = 'short',
  tagged = false,
  withTime = false,
  circa = ''
) {
  const lang = langParam || 'en'
  let val = str
  let timeVal = ''
  if (typeof val === 'object') {
    return val
  }

  if (!val || val.toString() === CURRENT_TIME.toString()) {
    return tagged ? { today: lang === 'en' ? 'Today' : 'Heute' } : ''
  }

  let d
  if (typeof val === 'number') {
    // Note: this will only show the year for the 1st Jan
    // due to the unknown exact dates there is an ambiguity between just a year and 1st Jan, so
    // might have to hack the 1st of Jan by storing it with a few seconds too
    if (val % 1 === 0) {
      const year = formatYear(val, { circa, lang })
      return tagged ? { year } : year
    }

    d = timeDetails(val)
    val = `${d.year}_${d.month + 1}_${d.day + 1}`
  } else if (isFinite(val)) {
    const yr = parseFloat(val)
    if (yr % 1 === 0) {
      // return as just year
      return formatYear(yr, { circa, lang })
    }

    d = timeDetails(yr)
    val = `${d.year}_${d.month + 1}_${d.day + 1}`
  }

  if (withTime && (d.hour || d.minute || d.second)) {
    timeVal = ` ${('00' + d.hour).substr(-2)}:${('00' + d.minute).substr(-2)}`
    if (d.second) {
      timeVal += `:${('00' + d.second).substr(-2)}`
    }
  }

  if (type !== 'short' && type !== 'full') {
    return val + timeVal
  }

  const m = val.match(/(-?[\d]+)[-_]([\d]{1,2})[-_]([\d]{1,2})/)
  if (m) {
    const year = formatYear(parseInt(m[1]), { circa, lang })
    const month = parseInt(m[2]) || 0
    const day = parseInt(m[3]) || 0

    if (month === 0) {
      // Unknown month or day
      return tagged ? { year: year } : year
    } else if (day === 0) {
      // unknown day
      const mon =
        type === 'full'
          ? FULLMONTHNAMES[month - 1][lang]
          : MONTHNAMES[month - 1][lang]
      if (tagged) {
        return {
          month: mon,
          year:  year,
        }
      } else {
        return `${mon} ${year}`
      }
    } else {
      // full date
      const mon =
        type === 'full'
          ? FULLMONTHNAMES[month - 1][lang]
          : MONTHNAMES[month - 1][lang]
      if (tagged) {
        return {
          day:          day,
          dayDelimiter: DAY_DELIMITER[lang],
          month:        mon,
          year:         year,
          time:         timeVal ? timeVal.trim() : null,
        }
      } else {
        return `${day}${DAY_DELIMITER[lang]} ${mon} ${year}${timeVal}`
      }
    }
  } else {
    return val
  }
}

/**
 * Converts a time value to a float value.
 *
 * If the input is a number it will be return as-is, unless that number is 9999 in which case it will
 * return the flat value of today.
 *
 * String values will first be parsed by dateDetails. If the input is something that can be parsed
 * as a TimePoint then it must be zero-based indexing for months and days.
 */
export function toFloatTime(input: string | number | TimePoint): number {
  if (typeof input === 'number' || input === null) {
    if (!input || input === CURRENT_TIME) {
      return todayFloat()
    }

    return input
  }

  let details: TimePoint
  if (typeof input === 'string') {
    const tempDetails = dateDetails(input)
    if (!tempDetails) {
      return 0
    }
    details = tempDetails

    if (details.month < 1) {
      return details.year
    } else if (details.month === 1 && details.day === 1) {
      // special case for the first day of the year
      const side = details.year < 0 ? -1 : 0
      return details.year + side + MIN * INVYEAR
    }

    // change to zero index version
    details.month = Math.max(details.month - 1, 0)
    details.day = Math.max(details.day - 1, 0)
  } else {
    details = input
  }
  if (!details) {
    return 0
  }
  const year = Math.floor(details.year)
  const leap = isLeapYear(year)
  const side = year < 0 ? -1 : 0
  let days = 0

  for (let i = 0, l = MONTHDAYS.length; i < l; i++) {
    const d = MONTHDAYS[i]
    if (i === details.month) {
      break
    }
    days += d
    if (d === 28 && leap) {
      days += 1
    }
  }

  days += details.day
  let secs = days * DAY
  secs += (details.hour || 0) * HOUR
  secs += (details.minute || 0) * MIN
  secs += details.second || 0
  secs += details.ms || 0

  const totalSecs = leap ? LEAPYEAR : YEAR
  return year + side + secs / totalSecs
}

/**
 * Attempts to parse the string as a YMDHMS value with separators of either _ or -.
 *
 * This will return a TimePoint structure without altering the values found. So if you pass in a
 * zero-indexed string you'll get zero-indexed values back.
 */
export function dateDetails(str: string) {
  if (typeof str === 'object') {
    return str
  }

  const year = '(-?[\\d]+)[-_]?'
  const month = '([\\d]{1,2})?[-_]?'
  let hour: string, minute: string, second: string
  const day = (hour = minute = second = month)

  const regex = new RegExp(year + month + day + hour + minute + second)
  const m = str.toString().match(regex)

  if (m) {
    const details: TimePoint = {
      year:  parseInt(m[1]),
      month: m[2] ? parseInt(m[2]) : 0,
      day:   m[3] ? parseInt(m[3]) : 0,
    }

    if (m[4] !== undefined) {
      details.hour = parseInt(m[4])
    }

    if (m[5] !== undefined) {
      details.minute = parseInt(m[5])
    }

    if (m[6] !== undefined) {
      details.second = parseInt(m[6])
    }

    return details
  }

  return null
}

export function isLeapYear(year: number): boolean {
  return Boolean(
    year && ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0)
  )
}

export function timeDetails(yr: string | number, yrSecs?: number): TimePoint {
  let year = toFloatTime(yr)
  let yearSecs = yrSecs
  let leapYear = isLeapYear(Math.floor(year))
  let totalSecs = leapYear ? LEAPYEAR : YEAR

  if (yearSecs == null) {
    let f = year % 1
    if (year < 0) {
      f = 1 + f
    }
    yearSecs = Math.round(f * totalSecs * 1000) / 1000
  }

  year = year < 0 ? Math.ceil(year) : Math.floor(year)

  while (yearSecs >= totalSecs) {
    yearSecs -= totalSecs
    year++
    leapYear = isLeapYear(year)
    totalSecs = leapYear ? LEAPYEAR : YEAR
  }

  const dayOfYear = Math.floor(yearSecs / DAY)
  const daySeconds = yearSecs - dayOfYear * DAY
  const hour = Math.floor(daySeconds / HOUR)
  const hourSeconds = daySeconds - hour * HOUR
  const minute = Math.floor(hourSeconds / MIN)
  let second = hourSeconds % MIN
  const ms = Math.round((second % 1) * 1000)
  second = Math.floor(second)
  let month = 0 // 0-11 zero index
  let day = 0 // 0-30 zero index
  let doy = 0
  let d = 0

  for (let i = 0, l = MONTHDAYS.length; i < l; i++) {
    month = i
    const days = MONTHDAYS[month]
    d = days

    if (days === 28 && leapYear) {
      d += 1
    }

    if (dayOfYear < doy + d) {
      break
    }

    doy += d
  }

  day = dayOfYear - doy

  return {
    year:        year,
    secs:        yearSecs,
    dayOfYear:   dayOfYear,
    month:       month,
    daysInMonth: d,
    day:         day,
    hour:        hour,
    minute:      minute,
    second:      second,
    ms:          ms,
    leap:        leapYear,
    days:        leapYear ? 366 : 365,
    totalSecs:   totalSecs,
    dayOfWeek:   dayOfWeek(year, month + 1, day + 1),
  }
}

export interface DateAsStrings {
  year: string,
  month?: string,
  day?: string,
}

export function dateToStringObject(
  inputDate: TimePoint | string,
  opts: Partial<DateToStringParams> = {}
): DateAsStrings {
  const date =
    typeof inputDate === 'object'
      ? inputDate
      : (dateDetails(inputDate) as TimePoint)
  const lang = opts.lang ?? 'en'

  const obj: DateAsStrings = {
    year: formatYear(date.year, { lang }),
  }

  if (date.month >= 1 && date.month <= 12) {
    obj.month = opts.full
      ? FULLMONTHNAMES[date.month - 1][lang]
      : MONTHNAMES[date.month - 1][lang]
  }

  if (date.day) {
    obj.day = opts.ordinal
      ? `${date.day}${ordinal(date.day, lang)}`
      : date.day.toString()
  }

  return obj
}

// ref: http://stackoverflow.com/a/15397495/384938
export function ordinal(n: number, lang: 'en' | 'de') {
  if (lang === 'de') {
    return '.'
  }

  if (n > 3 && n < 21) {
    return 'th'
  }

  switch (n % 10) {

    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'

  }
}

export function prevDayObject(input: string) {
  const date = dateDetails(input)

  if (!date || !date.day || !date.month) {
    return date
  }

  date.day -= 1
  if (date.day < 1) {
    date.month -= 1

    if (date.month < 1) {
      date.month = 12
    }
    // always include the 29th feb
    date.day = date.month === 2 ? 29 : MONTHDAYS[date.month - 1]
  }

  return date
}

export function nextDayObject(input: string) {
  const date = dateDetails(input)

  if (!date || !date.day || !date.month) {
    return date
  }

  date.day += 1
  const daysInMonth = date.month === 2 ? 29 : MONTHDAYS[date.month - 1]
  if (date.day > daysInMonth) {
    date.day = 1
    date.month += 1

    if (date.month > 12) {
      date.month = 1
    }
  }

  return date
}

export function secsToStartOfMonth(year: number, month: number) {
  const leapYear = isLeapYear(year)
  let doy = 0
  let d = 0
  let idx = 0

  while (idx < month) {
    d = MONTHDAYS[idx]
    if (d === 28 && leapYear) {
      d++
    }
    doy += d
    idx++
  }

  return doy * DAY
}

export function year2secs(year: number): number {
  let secs = Math.floor(year) * YEAR
  const leapDays = leapYearsBefore(year)
  secs += leapDays * DAY
  return secs
}

export function leapYearsBetween(startYear: number, endYear: number): number {
  return leapYearsBefore(endYear) - leapYearsBefore(startYear + 1)
}

export function leapYearsBefore(year: number): number {
  return (Math.floor(year) - 1) * 0.2425
}

type RoundingMode =
  | 'centuries'
  | 'years'
  | 'months'
  | 'days'
  | 'hours'
  | 'minutes'
  | 'seconds';

export function roundToIntervalStart(
  mode: RoundingMode,
  time: TimePoint,
  yearInterval = 1
) {
  // round down to the nearest interval for the current range mode
  if (mode === 'months') {
    time.day = 0
    time.hour = 0
    time.minute = 0
    time.second = 0
    time.ms = 0
  } else if (mode === 'days') {
    time.hour = 0
    time.minute = 0
    time.second = 0
    time.ms = 0
  } else if (mode === 'hours') {
    time.minute = 0
    time.second = 0
    time.ms = 0
  } else if (mode === 'minutes') {
    time.second = 0
    time.ms = 0
  } else if (mode === 'seconds') {
    time.ms = 0
  } else {
    // year intervals
    time.year = Math.floor(time.year / yearInterval) * yearInterval
    time.month = 0
    time.day = 0
    time.hour = 0
    time.minute = 0
    time.second = 0
    time.ms = 0
  }

  return time
}

export function secs2duration(time: number, long = false) {
  let s = time
  const hours = Math.floor(s / 3600)
  s -= hours * 3600

  const mins = Math.floor(s / 60)
  s -= mins * 60

  const secs = Math.ceil(s % 60)

  if (long) {
    let duration = ''
    if (hours) {
      duration = '' + hours + ' hr '
    }

    if (mins) {
      duration += '' + mins + ' min '
    }

    if (!hours && secs) {
      duration += '' + secs + ' sec'
    }

    if (!duration) {
      duration = '0 sec'
    }
    return duration.trim()
  }

  const formattedHours = hours ? hours + ':' : ''
  const formattedMins = hours ? ('00' + mins).substr(-2) : mins
  const formattedSeconds = ('00' + secs).substr(-2)

  const duration = formattedHours + formattedMins + ':' + formattedSeconds

  return duration.trim()
}

export const pad = (shouldPad: boolean, value: number): string =>
  shouldPad && value < 10 ? '0' + value : '' + value

export function parseYear(str: string | number) {
  let year = str.toString()

  // replace any punctuation after letters
  year = year.replace(/([a-z])\s*[^a-z0-9]\s*/gi, '$1')

  if (/bce?/i.test(year)) {
    // BC/E date
    return -Math.abs(parseInt(year.replace(/[^0-9.]+/i, '')))
  } else if (/ad|ce/i.test(year)) {
    // AD/CE dates
    return parseInt(year.replace(/[^0-9.]+/i, ''))
  } else if (/ka?/i.test(year)) {
    // thousands of years ago
    return (
      Math.abs(Number(parseFloat(year.replace(/[^0-9.]+/i, '')).toFixed(2))) *
      -1e3
    )
  } else if (/ma?/i.test(year)) {
    // millions of years ago
    return (
      Math.abs(Number(parseFloat(year.replace(/[^0-9.]+/i, '')).toFixed(2))) *
      -1e6
    )
  } else if (/ba?/i.test(year)) {
    // billions of years ago
    return (
      Math.abs(Number(parseFloat(year.replace(/[^0-9.]+/i, '')).toFixed(2))) *
      -1e9
    )
  }

  return parseInt(year)
}

export function formatYear(year: string | number, options?) {
  if (typeof year === 'string') {
    return year
  }

  const defaultOptions = {
    circa: '',
    limit: {
      AD: 200,
      CE: 501,
    },
    lang: 'en',
  }
  const opt = { ...defaultOptions, ...options }
  const { circa, limit, lang } = opt
  const timeSplitter: any = []

  if (year >= limit.CE) {
    timeSplitter.push(circa)
    timeSplitter.push(Math.floor(year))
  } else if (year >= limit.AD) {
    timeSplitter.push(circa)
    timeSplitter.push(Math.floor(year))
    timeSplitter.push(TIME_ABBREVIATION['CE'][lang])
  } else if (year >= 1) {
    timeSplitter.push(TIME_ABBREVIATION['AD'][lang])
    timeSplitter.push(circa)
    timeSplitter.push(Math.floor(year))
  } else if (year >= 0) {
    timeSplitter.push(TIME_ABBREVIATION['ZERO'][lang])
  } else if (year >= -10000) {
    timeSplitter.push(circa)
    timeSplitter.push(Math.floor(-year))
    timeSplitter.push(TIME_ABBREVIATION['BC'][lang])
  } else if (year >= -500e3) {
    timeSplitter.push(Number((-year / 1e3).toFixed(1)))
    timeSplitter.push(TIME_ABBREVIATION['KA'][lang])
  } else if (year >= -500e6) {
    timeSplitter.push(Number((-year / 1e6).toFixed(2)))
    timeSplitter.push(TIME_ABBREVIATION['MA'][lang])
  } else {
    timeSplitter.push(Number((-year / 1e9).toFixed(2)))
    timeSplitter.push(TIME_ABBREVIATION['BA'][lang])
  }

  return timeSplitter.filter((x) => x !== '').join(' ')
}

export function toHMS(time: string, leadingZero = false) {
  const sec_num = parseInt(time, 10) // don't forget the second param
  const hours = Math.floor(sec_num / 3600)
  const minutes = Math.floor((sec_num - hours * 3600) / 60)
  const seconds = sec_num - hours * 3600 - minutes * 60

  return `${pad(leadingZero, hours)}h${pad(leadingZero, minutes)}m${pad(
    leadingZero,
    seconds
  )}s`
}

export function dateToHMS(input: string) {
  if (!input) {
    return ''
  }

  const year = '(-?[\\d]+)[-_]?'
  const month = '([\\d]{1,2})?[-_]?'
  let hourR: string, minuteR: string, secondR: string
  const day = (hourR = minuteR = secondR = month)

  const regex = new RegExp(year + month + day + hourR + minuteR + secondR)

  // We're going to trust the input to always give us a matchable result, so we
  // can cast the result and ignore nulls
  const m = input.toString().match(regex) as RegExpMatchArray

  const hour = parseInt(m[4])
  const minute = parseInt(m[5])
  const second = parseInt(m[6])

  let output = ''
  if (!Number.isNaN(hour)) {
    output += `${('00' + hour).substr(-2)}`
  }

  if (!Number.isNaN(minute)) {
    output += `:${('00' + minute).substr(-2)}`
  }

  if (!Number.isNaN(second)) {
    output += `:${('00' + second).substr(-2)}`
  }

  if (output.length && !output.includes(':')) {
    output += 'h'
  }

  return output
}

// https://en.wikipedia.org/wiki/Determination_of_the_day_of_the_week
// 1 <= m <= 12,  y > 1752 (in the U.K.)
export function dayOfWeek(y: number, m: number, d: number): number {
  const date = new Date(`${y}-${m}-${d}`)
  return date.getDay()

  // const t = [0, 3, 2, 5, 0, 3, 5, 1, 4, 6, 2, 4]
  // const _y = Math.floor(m < 3 ? y - 1 : y)
  // return (_y + Math.floor(_y / 4) - Math.floor(_y / 100) + Math.floor(_y / 400) + t[m - 1] + d) % 7
}
