'use strict'
import { INVYEAR } from '@kpv-lab/time-utils'

export const defaultState = {
  scales:         {},
  startMargin:    0,
  endMargin:      0,
  startTime:      1900,
  endTime:        1960,
  minTime:        1800,
  maxTime:        new Date().getFullYear() + 1,
  minSpan:        INVYEAR * 10,
  maxSpan:        100,
  minZoom:        -2,
  maxZoom:        8,
  zoomLimit:      -10, // temporary limit to prevent zooming in on empty space
  blendIn:        0,
  blendOut:       0,
  width:          window.innerWidth || 800,
  height:         window.innerHeight || 600,
  length:         window.innerHeight || 600,
  lengthScale:    1,
  rulerWidth:     150,
  mode:           'years',
  viewMode:       '2d',
  orientation:    'vertical',
  scale:          'years',
  nextScale:      '',
  prevScale:      '',
  focusMode:      'precise',
  unrestricted:   false,
  startOvershoot: 0,
  endOvershoot:   0,
  itemsTrack:     {},
  className:      '',
}

export const getInitialState = function (scope = 'default') {
  return {
    scope,
    [scope]: Object.assign({}, defaultState),
  }
}
