import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import TimelineItem from './TimelineItem'

export default class TimelineAnchor extends PureComponent {

  static propTypes = {
    item:              PropTypes.object,
    template:          PropTypes.object,
    margin:            PropTypes.number,
    timePos:           PropTypes.number,
    itemPos:           PropTypes.number,
    track:             PropTypes.number,
    groupSize:         PropTypes.number,
    trackSpacing:      PropTypes.number,
    trackOffset:       PropTypes.number, //eslint-disable-line
    alpha:             PropTypes.number,
    length:            PropTypes.number,
    pointHeight:       PropTypes.number,
    side:              PropTypes.number,
    size:              PropTypes.number,
    sizeScale:         PropTypes.number,
    timeOffset:        PropTypes.number,
    layoutId:          PropTypes.string,
    scale:             PropTypes.string,
    type:              PropTypes.string,
    vertical:          PropTypes.bool,
    zIndex:            PropTypes.number,
    autoUpdate:        PropTypes.number,
    updateLength:      PropTypes.bool,
    focus:             PropTypes.bool,
    autoFocus:         PropTypes.bool,
    className:         PropTypes.string,
    activeClassName:   PropTypes.string,
    lang:              PropTypes.string,
    itemHandler:       PropTypes.func,
    customItemRender:  PropTypes.func,
    customFieldRender: PropTypes.func,
    customItemStyle:   PropTypes.func,
    customItemClass:   PropTypes.func,
  }

  state = { len: 0 }

  componentDidUpdate(prevProps) {
    const { length } = this.props
    if (length === prevProps.length || length === this.state.len) {
      return
    }

    if (this.props.updateLength || !this.state.len || this.props.item !== prevProps.item) {
      this.setState({ len: length })
    }
  }

  render() {
    const {
      item,
      margin,
      timePos,
      vertical,
      zIndex,
      autoUpdate,
      layoutId,
      scale,
      length,
      alpha,
      sizeScale,
      type,
      pointHeight,
      track,
      trackSpacing,
      groupSize,
      itemPos,
      customItemRender,
      customFieldRender,
      customItemStyle,
      customItemClass,
      activeClassName,
      className,
      focus,
      autoFocus,
      template,
      itemHandler,
      size,
      timeOffset,
      side,
      lang,
    } = this.props
    const { len } = this.state
    const _className = `timeline-item-anchor ${activeClassName || ''}`
    let px, py

    if (vertical) {
      py = timePos
      const ss = Math.floor(sizeScale * (size + trackSpacing))

      if (side > 0) {
        px = margin + track * ss
      } else {
        px = groupSize - (track + 1) * ss + Math.floor(sizeScale * trackSpacing) - margin
      }
    } else {
      px = timePos
      const ss = Math.floor(sizeScale * (size + trackSpacing))

      if (side > 0) {
        // py = Math.floor(margin + sizeScale * trackPos);
        py = margin + track * ss
      } else if (type === 'point') {
        // py = Math.floor(margin - trackPos - pointHeight);
        py = groupSize - margin - pointHeight
      } else {
        // py = Math.floor(margin - sizeScale * (trackPos + size));
        // py = margin - (ss + track * ss);
        py = groupSize - (track + 1) * ss + Math.floor(sizeScale * trackSpacing) - margin
      }
    }

    const style = {
      transform: `translate3d(${px}px, ${Math.floor(py)}px, 0)`,
      opacity:   alpha,
      zIndex:    zIndex,
    }
    style.WebkitTransform = style.transform

    return (
      <div className={_className} style={style}>
        <TimelineItem
          content={item}
          type={type}
          lang={lang}
          layoutId={layoutId}
          scale={scale}
          focus={focus}
          itemPos={itemPos}
          autoFocus={autoFocus}
          length={len || length}
          size={Math.floor(size * sizeScale)}
          sizeScale={sizeScale}
          timeOffset={timeOffset}
          className={className}
          vertical={vertical}
          autoUpdate={autoUpdate}
          template={template}
          itemHandler={itemHandler}
          customRender={customItemRender}
          customFieldRender={customFieldRender}
          customStyle={customItemStyle}
          customClass={customItemClass}
        />
      </div>
    )
  }

}
