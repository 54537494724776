import { rgbaString } from '@kpv-lab/color-utils'

import { StyleOptions, TimelineRulerProps } from '../../TimelineRulerTypes'
import clamp from '../../utils/clamp'
import { blend } from '../rulerHelpers'

export const blendInStyle = (style: StyleOptions, blendIn: number, props: TimelineRulerProps): Partial<StyleOptions> => {
  const { theme } = props
  const attrs: Partial<StyleOptions> = {
    tickAlpha:      blend(0, style.tickAlpha, blendIn),
    tickColor:      style.tickColor,
    tickPos:        blend(0, style.tickPos, blendIn),
    tickLength:     blend(0, style.tickLength, blendIn),
    tickThickness:  style.tickThickness,
    labelSize:      Number(blend(style.labelSize * 0.5, style.labelSize, blendIn).toFixed(3)),
    labelWeight:    style.labelWeight,
    labelOffset:    style.labelOffset,
    labelRotation:  Number((style.labelRotation).toFixed(3)),
    labelPlacement: style.labelPlacement,
    labelColor:     style.labelColor,
    labelAlpha:     clamp(2 * blendIn - 1, 0, 1) * style.labelAlpha,
    outline:        style.outline,
    outlineColor:   style.outlineColor,
    outlineAlpha:   blendIn * style.outlineAlpha,
    shadowAlpha:    blendIn * style.shadowAlpha,
    shadowColor:    style.shadowColor,
    shadowX:        style.shadowX,
    shadowY:        style.shadowY,
    shadowBlur:     style.shadowBlur,
  }

  attrs._labelAlpha = attrs.labelAlpha
  attrs._outlineAlpha = attrs.outlineAlpha

  if (theme.common.textAlign === 'center') {
    attrs.labelPos = style.labelPos
  } else {
    attrs.labelPos = blend(0, style.labelPos, blendIn)
  }

  if (attrs.shadowAlpha && attrs.shadowAlpha > 0) {
    attrs.shadowColorAlpha = rgbaString(attrs.shadowColor || '#000000', attrs.shadowAlpha)
  }

  return attrs
}
