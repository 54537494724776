import { dateToString } from '@kpv-lab/time-utils'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

export default class FormattedDate extends PureComponent {

  static propTypes = {
    str:  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lang: PropTypes.string,
  }

  renderSegments(date) {
    const segments = []

    if (date.today) {
      return <span className="date-today">{date.today}</span>
    }

    if (date.day) {
      segments.push(
        <span className="date-day" key="day">
          {date.day}
          {date.dayDelimiter}
        </span>
      )
    }

    if (date.month) {
      segments.push(
        <span className="date-month" key="month">
          {date.month}
        </span>
      )
    }

    if (date.year) {
      segments.push(
        <span className="date-year" key="year">
          {date.year}
        </span>
      )
    }

    if (date.time) {
      segments.push(
        <span className="date-time" key="time">
          {date.time}
        </span>
      )
    }

    return segments
  }

  render() {
    const { str, lang } = this.props
    const date = dateToString(str, lang, 'short', true)

    return <span className="date">{this.renderSegments(date)}</span>
  }

}
