'use strict'

import * as types from './action-types'

export function setTimelineLayout(timeline, layout) {
  const { orientation, lengthScale, rulerStartMargin, rulerEndMargin } = layout
  return {
    ...timeline,
    orientation: orientation || 'vertical',
    lengthScale: (lengthScale || 100) / 100,
    startMargin: rulerStartMargin || 0,
    endMargin:   rulerEndMargin || 0,
  }
}

// action creators
export function setRange(startTime, endTime, tplId, track, scope = 'default') {
  return {
    type: types.SET_RANGE,
    data: { startTime, endTime, tplId, track, scope },
  }
}

export function setTrack(tplId, track, scope = 'default') {
  return {
    type: types.SET_TRACK,
    data: { tplId, track, scope },
  }
}

export function setOptions(opts = {}) {
  return {
    type: types.SET_OPTIONS,
    data: opts,
  }
}

export function setTheme(theme, scope = 'default') {
  return {
    type: types.SET_THEME,
    data: { theme, scope },
  }
}

export function setClassName(className, scope = 'default') {
  return {
    type: types.SET_CLASS_NAME,
    data: { className, scope },
  }
}

export function setSize(width, height, scope = 'default') {
  return {
    type: types.SET_SIZE,
    data: { width, height, scope },
  }
}

export function setScaleBlend(key, val, scope = 'default') {
  return {
    type: types.SET_SCALE_BLEND,
    data: { key, val, scope },
  }
}

export function toggleZoomLimit(state, scope = 'default') {
  return (dispatch, getState) => {
    const timeline = getState().timeline
    const _scope = scope || timeline.scope
    const zoom =  timeline[_scope]?.zoom
    const zoomLimit =  timeline[_scope]?.zoomLimit

    if (state && zoomLimit === -10) {
      // enable
      return dispatch(setZoomLimit(zoom))
    } else if (!state && zoomLimit > -10) {
      // disable
      return dispatch(setZoomLimit(-10))
    }
  }
}

export function setZoomLimit(limit, scope = 'default') {
  return {
    type: types.ZOOM_LIMIT,
    data: { limit, scope },
  }
}
