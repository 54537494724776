import { useEventListener } from '@lib/hooks'
import PropTypes from 'prop-types'
import React, { createContext, useCallback, useContext, useState } from 'react'

export const DimensionsCtx = createContext({ width: 0, height: 0 })

export const useDimensions = () => useContext(DimensionsCtx)

const DimensionsProvider = ({ children }) => {


  function getDimensions() {
    return {
      width:  window.innerWidth,
      height: window.innerHeight,
    }
  }

  function getBaseSize(w = window.innerWidth) {
    let fontSize = 15
    if (w < 1200) {
      fontSize -= 1
    } else if (w > 3400) {
      fontSize += 15
    } else if (w > 3000) {
      fontSize += 10
    } else if (w > 2400) {
      fontSize += 7
    } else if (w > 2100) {
      fontSize += 5
    } else if (w > 1800) {
      fontSize += 3
    }

    if (fontSize !== window._appBaseSize) {
      document.body.parentNode.style.fontSize = `${fontSize}px`
      window._appBaseSize = fontSize
    }
    return fontSize
  }

  const [dimensions, setDimensions] = useState({
    ...getDimensions(),
    baseSize: getBaseSize(window.innerWidth),
  })

  const handleResize = useCallback(() => {
    setDimensions({
      ...getDimensions(),
      baseSize: getBaseSize(window.innerWidth),
    })
  }, [window.innerWidth])

  useEventListener('resize', handleResize, 250)

  return (
    <DimensionsCtx.Provider value={dimensions}>
      {children}
    </DimensionsCtx.Provider>
  )
}

export default DimensionsProvider

DimensionsProvider.propTypes = {
  children: PropTypes.node,
}
