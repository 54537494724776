'use strict'

/**
 * deep in place setIn
 */
export const setIn = (object, path, value) => {
  const keys = (typeof path === 'string' ? path.split('.') : path)
    .filter(k => k !== '' && k !== undefined)
    .map(k => (isFinite(k) ? parseInt(k) : k))

  if (!object || !keys.length) {
    return
  }

  const lastKey = keys.slice(-1)
  const lastObject = keys.slice(0, -1)
    .reduce(
      (obj, key) => (obj[key] = obj[key] || {}),
      object
    )
  lastObject[lastKey] = value
}
