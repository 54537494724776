import { StyleOptions, TimelineRulerProps } from '../../../TimelineRulerTypes'
import { getPixelRatio } from '../../rulerHelpers'

export const drawLabel = (
  style: StyleOptions,
  textTransform: string,
  width: number,
  pos: number,
  str: string,
  extraStr: string,
  key: string,
  divSize: number,
  fadeAlpha: number,
  props: TimelineRulerProps,
  ctxLabels: CanvasRenderingContext2D,
  ctxLabels2: CanvasRenderingContext2D
) => {
  const { theme, orientation } = props
  let w: number
  let x: number
  let y = pos

  const pixelRatio = getPixelRatio()
  /*
      {
        str: "test",
        textTransform: "toUpperCase"
      }

      > test.toUpperCase()
    */
  // @ts-ignore: There's no saving this
  const label = str && textTransform ? str[textTransform]() : str
  // @ts-ignore
  const extraLabel = extraStr && textTransform ? extraStr[textTransform]() : extraStr

  const common = theme.common
  const textAlign = theme.common.textAlign
  const ctx = /major|months-year/.test(key) ? ctxLabels2 : ctxLabels
  let s = 1

  switch (textAlign) {

    case 'left':
      x = style.labelPos * width
      w = Math.abs(style.labelRotation) < 30 ? width - x : width
      break

    case 'right':
      x = (1 - style.labelPos) * width
      w = Math.abs(style.labelRotation) < 30 ? x : width
      s = -1
      break

    default:
      x = (0.5 + style.labelPos) * width
      w = width

  }

  if (orientation === 'horizontal') {
    if (textAlign === 'right') {
      y += style.labelOffset * style.labelSize
    } else {
      s = -1
      y -= style.labelOffset * style.labelSize
    }
  } else {
    y += style.labelOffset * style.labelSize
  }

  if (!ctx) {
    console.warn('drawLabel: ctx is undefined')
    return
  }

  if (style.labelRotation !== 0) {
    ctx.save()
    ctx.translate(x, y)
    ctx.rotate(s * style.labelRotation * (Math.PI / 180))
    x = 0
    y = 0
  }

  const font = `${style.labelWeight} ${common.fontStyle} ${style.labelSize}px ${common.font}`
  ctx.font = font

  if (style.shadowAlpha > 0) {
    ctx.shadowColor = style.shadowColorAlpha as string
    ctx.shadowOffsetX = style.shadowX
    ctx.shadowOffsetY = style.shadowY
    ctx.shadowBlur = style.shadowBlur
  } else {
    ctx.shadowOffsetX = 0
    ctx.shadowOffsetY = 0
    ctx.shadowBlur = 0
  }

  if (style.outline > 0 && style._outlineAlpha > 0) {
    ctx.globalAlpha = style._outlineAlpha as number
    ctx.strokeStyle = style.outlineColor
    ctx.lineWidth = style.outline
    ctx.strokeText(label, x, y, w * 0.95)
  }

  let alpha = Number(style._labelAlpha) * fadeAlpha
  const minDivSize = 40 * pixelRatio
  if (divSize <= minDivSize && /jan|dec|dez/i.test(str)) {
    alpha *= Math.min(Math.max((divSize - minDivSize * 0.25) / (minDivSize * 0.75), 0), 1)
  }

  if (alpha > 0) {
    ctx.globalAlpha = alpha
    ctx.fillStyle = style.labelColor
    ctx.fillText(label, x, y, w * 0.95)

    if (extraLabel) {
      ctx.fillText(
        extraLabel,
        x + (common.dayLabelOffset || 0),
        y + (common.dayLabelPos || 0),
        w * 0.95
      )
    }
  }

  if (style.labelRotation !== 0) {
    ctx.restore()
  }
}
