import { processingOptions as defaultProcessingOptions } from '@kpv-lab/image-utils'

export type FitOption = 'clamp' | 'clip' | 'crop' | 'facearea' | 'fill' | 'min' | 'max' | 'scale' | ''
export type CropOption = 'top' | 'bottom' | 'left' | 'right' | 'faces' | 'entropy' | ''

interface ImgixImage {
  path: string,
  width?: number,
  height?: number,
}

interface ImgixOptions {
  targetWidth: number,
  targetHeight: number,
  fit: FitOption,
  crop: CropOption,
  quantise: number,
  options: string,
  noEnlarge: boolean,
  assetHost: string,
  processingOptions: Record<string, string>,
}

type ImgixPathParams = {
  path: string,
  width?: number,
  height?: number,
  fit?: FitOption,
  crop?: string,
  options?: string,
  additionalParams?: string,
  enlarging?: boolean,
  dpr?: number,
  assetHost?: string,
  processingOptions?: Record<string, string>,
}

interface ImgixHeightParams {
  fullPath: string,
  width: string,
}

let defaultAssetHost = ''

export function setAssetHost(host: string) {
  defaultAssetHost = host
}

export function getImgixURL(
  image: ImgixImage,
  imgixOptions: Partial<ImgixOptions> = {}
) {
  const {
    targetWidth,
    targetHeight,
    fit,
    crop,
    quantise,
    options,
    noEnlarge,
    assetHost = defaultAssetHost,
    processingOptions = defaultProcessingOptions,
  } = imgixOptions

  const { path } = image
  const srcWidth = image.width || 640
  const srcHeight = image.height || 480

  const srcRatio = srcWidth / srcHeight
  const rnd = quantise || 50

  let width: number  = targetWidth || 0
  let height: number = targetHeight || 0

  let exactWidth: number | undefined
  let exactHeight: number | undefined
  let destFit = fit
  let destCrop = crop

  if (width && height) {
    exactWidth = width
    exactHeight = height
    width = Math.ceil(width / rnd) * rnd
    height = Math.ceil(height / rnd) * rnd
  } else if (!height) {
    exactWidth = width
    exactHeight = Math.floor(exactWidth / srcRatio)
    width = Math.ceil(width / rnd) * rnd
  } else if (!width) {
    exactHeight = height
    exactWidth = Math.floor(height * srcRatio)
    height = Math.ceil(height / rnd) * rnd
  }

  const _options: string = options ? processingOptions[options] || options : ''
  if (_options) {
    if (_options.includes('fit=')) {
      destFit = ''
    }

    if (_options.includes('fit=facearea')
      && typeof targetHeight === 'number'
      && typeof targetWidth === 'number') {
      height = targetHeight
      exactHeight = targetHeight
      width = height
      exactWidth = exactHeight
    }

    if (_options.includes('crop=')) {
      destCrop = ''
    }
  }

  const enlarging = (typeof targetWidth !== 'undefined' ? targetWidth >= srcWidth : false)
    || (typeof targetHeight !== 'undefined' ? targetHeight >= srcHeight : false)

  if (enlarging && noEnlarge) {
    width = Math.ceil(srcWidth / rnd) * rnd
    height = Math.floor(width / srcRatio)
  }
  return {
    width:  exactWidth && Math.floor(exactWidth),
    height: exactHeight && Math.floor(exactHeight),
    path:   getImgixPath({
      path,
      width,
      height,
      fit:      destFit,
      crop:    destCrop,
      options: _options,
      enlarging,
      assetHost,
    }),
  }
}

export function getImgixPath({
  path,
  width,
  height,
  fit,
  crop,
  options,
  additionalParams,
  enlarging,
  dpr = window.devicePixelRatio,
  assetHost = defaultAssetHost,
  processingOptions = defaultProcessingOptions,
}: ImgixPathParams) {

  if (!path) {
    throw new Error('No path supplied')
  }
  const params: Array<string> = []
  if (width) {
    params.push(`w=${Math.floor(width)}`)
  }
  if (height) {
    params.push(`h=${Math.floor(height)}`)
  }
  const opts = options
    ? processingOptions[options] || options
    : undefined

  // fit options: clamp, clip, crop, facearea, fill, min, max, scale
  if (fit) {
    if (!opts || opts.indexOf('fit=') === -1) {
      params.push(`fit=${fit}`)
    }
  }

  // cropmode options: top, bottom, left, right, faces, entropy
  if (crop) {
    params.push(`crop=${crop}`)
  }

  if (dpr > 1.5 && (width || height) && !enlarging) {
    params.push('dpr=2')
    params.push('q=50')
  }

  if (opts) {
    params.push(opts)
  }

  if (additionalParams) {
    params.push(additionalParams)
  }

  return `${path.startsWith('http') ? path : assetHost + path}?${params.join('&')}`
}

export const IMG_WIDTHS = {
  default: 640,
  tiny:    80,
  small:   160,
  medium:  320,
  large:   640,
}

export async function getHeightFromImgix({ fullPath, width }: ImgixHeightParams) {
  const imgixUrl = `${fullPath}&fm=json`
  let response
  try {
    const responseRaw = await fetch(imgixUrl)
    response = await responseRaw.json()
  } catch (e) {
    console.error('The image url is not valid ', imgixUrl)
    throw e
  }
  const { h, w } = { h: response.PixelHeight, w: response.PixelWidth }
  if (!h || !w) {
    throw new Error(`Height (${h}) or width (${w}) is missing`)
  }
  let userWidth
  switch (width) {

    case 'tiny':
      userWidth = IMG_WIDTHS.tiny
      break
    case 'small':
      userWidth = IMG_WIDTHS.small
      break
    case 'medium':
      userWidth = IMG_WIDTHS.medium
      break
    case 'large':
      userWidth = IMG_WIDTHS.large
      break
    default:
      userWidth = IMG_WIDTHS.default
      break

  }

  const ratio = userWidth / w
  return Math.round(h * ratio)
}