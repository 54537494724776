// eslint-disable-next-line
import { Draft, produce, castDraft } from 'immer'
import { Question } from 'le-client'

import { QuizActionTypes } from './actions'
import { QuizStates, QuizzesState, ReadyQuizState, QuestionState } from './types'

const getCorrectIds = (question: Question) =>
  question.answers.reduce((ids, { isCorrect }, index) => {
    isCorrect && ids.push(index)
    return ids
  }, [] as Array<number>)

const initialiseQuestions = (questions = [] as ReadonlyArray<Question>): Array<QuestionState> => {
  return questions.map(question => ({
    answers:    question.answers.map(() => 'unanswered'),
    correctIds: getCorrectIds(question),
    status:     'unanswered',
    title:      question.title,
  }))
}

export const quizInitialState = (questions?: ReadonlyArray<Question>, title?: string, dataUpdatedAt?: string): ReadyQuizState => {
  return {
    status:        'ready',
    lastUpdate:    Date.now(),
    dataUpdatedAt:  dataUpdatedAt,
    title,
    questions:     initialiseQuestions(questions),
  }
}

const error = (message: string): never => {
  throw new Error(message)
}

const assertAndUpdateQuiz = (quizzes: Draft<QuizzesState>, quizId: number) => {
  const quiz = quizzes.get(quizId) || error(`Quiz id '${quizId}' not found`)
  quiz.lastUpdate = Date.now()
  return quiz
}

export const reducer = () => produce((state: Draft<QuizzesState>, action: QuizActionTypes) => {
  if (action.type === 'INIT_QUIZZES') {
    action.quizzes.forEach(({ id, updatedAt }) => {
      if (!state.get(id)) {
        state.set(id, castDraft(quizInitialState(undefined, undefined, updatedAt)))
      }
    })
    return
  }

  const { quizId } = action
  let currentQuiz: Draft<QuizStates>

  switch (action.type) {

    case 'INIT_QUIZ':
      if (!state.get(quizId)) {
        state.set(quizId, castDraft(quizInitialState()))
      }
      if (state.get(quizId)?.status === 'ready' && state.get(quizId)?.questions.length === 0) {
        state.set(quizId, castDraft(quizInitialState(action.questionsData, action.title, action.dataUpdatedAt)))
      }
      break

    case 'RESTART_QUIZ':
      currentQuiz = assertAndUpdateQuiz(state, quizId)
      state.set(quizId, {
        status:     'started',
        questionId: 0,
        questions:  castDraft(
          currentQuiz.questions.map(({ answers, correctIds }, idx) => ({
            answers: answers.map(() => 'unanswered'),
            correctIds,
            status:  'unanswered',
            title:   currentQuiz.questions[idx].title,
          }))
        ),
        lastUpdate:    currentQuiz.lastUpdate,
        title:         currentQuiz.title,
        dataUpdatedAt:  currentQuiz.dataUpdatedAt,
      })
      break

    case 'START_QUIZ':
      currentQuiz = assertAndUpdateQuiz(state, quizId)
      let _questionId = 0
      if (currentQuiz.status === 'paused-resume-dismissed') {
        _questionId = currentQuiz.questionId >= 0 ? currentQuiz.questionId : 0
      }
      state.set(quizId, castDraft({
        status:        'started',
        questionId:    _questionId,
        questions:     currentQuiz.questions,
        lastUpdate:    currentQuiz.lastUpdate,
        title:         currentQuiz.title,
        dataUpdatedAt:  currentQuiz.dataUpdatedAt,
      }))
      break

    case 'NEXT_QUESTION':
      currentQuiz = assertAndUpdateQuiz(state, quizId)
      if (currentQuiz.status !== 'started' && currentQuiz.status !== 'review') {
        return
      }
      const { status: questionStatus } = currentQuiz.questions[currentQuiz.questionId]
      if (questionStatus === 'unanswered') {
        return
      }
      const nextIdx = currentQuiz.questionId + 1
      const nextUnansweredIdx = currentQuiz.questions
        .findIndex(({ status: status }) => status === 'unanswered')
      if (nextUnansweredIdx === -1) {
        state.set(quizId, castDraft({
          status:        'completed',
          done:          false,
          questions:     currentQuiz.questions,
          lastUpdate:    currentQuiz.lastUpdate,
          title:         currentQuiz.title,
          dataUpdatedAt:  currentQuiz.dataUpdatedAt,
        }))
      } else {
        const questionId = currentQuiz.questions[nextIdx].status !== 'unanswered'
          ? nextUnansweredIdx
          : nextIdx
        state.set(quizId, castDraft({
          status:        'started',
          questionId,
          questions:     currentQuiz.questions,
          lastUpdate:    currentQuiz.lastUpdate,
          title:         currentQuiz.title,
          dataUpdatedAt:  currentQuiz.dataUpdatedAt,
        }))
      }
      break

    case 'SELECT_ANSWER':
      currentQuiz = assertAndUpdateQuiz(state, quizId)
      const { answerId } = action
      if (currentQuiz.status !== 'started') {
        return
      }
      const questionState = currentQuiz.questions[currentQuiz.questionId]

      if (questionState.status !== 'unanswered') {
        return
      }

      questionState.correctIds.forEach(id => {
        questionState.answers[id] = 'correct'
      })
      if (questionState.correctIds.includes(answerId)) {
        questionState.status = 'correct'
      } else {
        questionState.status = 'wrong'
        questionState.answers[answerId] = 'wrong'
      }
      break

    case 'SELECT_QUESTION':
      currentQuiz = assertAndUpdateQuiz(state, quizId)
      if (currentQuiz.status === 'ready') {
        return
      }

      state.set(quizId, castDraft({
        status:        'review',
        questionId:    action.questionId,
        questions:     currentQuiz.questions,
        lastUpdate:    currentQuiz.lastUpdate,
        title:         currentQuiz.title,
        dataUpdatedAt:  currentQuiz.dataUpdatedAt,
      }))
      break

    case 'SET_DONE':
      currentQuiz = assertAndUpdateQuiz(state, quizId)
      if (currentQuiz.status !== 'completed') {
        return
      }
      currentQuiz.done = true
      break

    case 'DISMISS_RESUME':
      currentQuiz = assertAndUpdateQuiz(state, quizId)
      if (currentQuiz.status === 'started') {
        state.set(quizId, castDraft({
          status:        'paused-resume-dismissed',
          questionId:    currentQuiz.questionId,
          questions:     currentQuiz.questions,
          lastUpdate:    currentQuiz.lastUpdate,
          title:         currentQuiz.title,
          dataUpdatedAt:  currentQuiz.dataUpdatedAt,
        }))
      }
      break

    case 'SET_UPDATED':
      state.set(quizId, castDraft({
        status:        'updated',
        lastUpdate:    Date.now(),
        dataUpdatedAt:  action.dataUpdatedAt,
        title:         action.title,
        questions:     initialiseQuestions(action.questionsData),
      }))
      break

    default:
      return

  }
}, new Map<number, QuizStates>())
