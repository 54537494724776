export const AGE_OF_UNIVERSE = 13.82e9

export const globalScales = {
  seconds: ['seconds', 'minutes', 'hours'],
  years:   ['seconds',
    'minutes',
    'hours',
    'days',
    'months',
    'years',
    'centuries',
    'millennia',
    'thousands',
    '100thousands',
    'millions',
    '100millions',
    'billions',
    'eons'],
}

export const priorities: { [key: string]: number } = {
  second: 1,
  minute: 2,
  hour:   3,
  day:    4,
  month:  5,
  year:   7,
  minor:  7,
  middle: 8,
  major:  9,
}