// action constants
export const CLEAR_TIMELINE_ITEMS = 'le/CLEAR_TIMELINE_ITEMS'
export const FULL_SCREEN = 'le/FULL_SCREEN'
export const HIDE_INTRO = 'le/HIDE_INTRO'
export const ONBOARD_TOGGLED = 'le/ONBOARD_TOGGLED'
export const PLAYING = 'le/PLAYING'
export const PROJECT_FAILURE = 'le/PROJECT_FAILURE'
export const PROJECT_REQUEST = 'le/PROJECT_REQUEST'
export const PROJECT_SUCCESS = 'le/PROJECT_SUCCESS'
export const SET_COMPARISON_MODE = 'le/SET_COMPARISON_MODE'
export const SET_MENU_OPTIONS = 'le/SET_MENU_OPTIONS'
export const SET_SECTION_LINKS = 'le/SET_SECTION_LINKS'
export const SET_TIMELINE_ITEMS = 'le/SET_TIMELINE_ITEMS'
export const SET_TITLE = 'le/SET_TITLE'
export const SHOW_INTRO = 'le/SHOW_INTRO'
export const SHOW_MAP = 'le/SHOW_MAP'
export const SET_SUBMENU_ID = 'le/SET_SUBMENU_ID'
export const CLEAR_SEARCH = 'le/CLEAR_SEARCH'
export const SET_SEARCH = 'le/SET_SEARCH'
