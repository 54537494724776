import { produce } from 'immer'

import { HIDE_IMAGE, HideImageAction, ImageMeta, ImageViewerActions, OriginMeta, SHOW_IMAGE, ShowImageAction, UPDATE_IMAGE, UpdateImageAction  } from './actions'


interface ImageViewerState {
  className: string,
  id: number|null,
  origin: OriginMeta|null,
  src: ImageMeta|null,
  visible: boolean,
}

export const initialState: ImageViewerState = {
  visible:   false,
  src:       null,
  origin:    null,
  className: '',
  id:        null,
}

type ImageViewerActionHandler<T extends ImageViewerActions> = (state: ImageViewerState, action: T) => void

const showImage: ImageViewerActionHandler<ShowImageAction> = (state, action) => {
  const { src, origin, className, id } = action.data
  state.src =  src
  state.origin =  origin
  state.className = className
  state.id = (id || src.id)
  state.visible = true
}

const hideImage: ImageViewerActionHandler<HideImageAction> = (state, action) => {
  if (!action.id || state.id === action.id) {
    return initialState
  }
}

const updateImage: ImageViewerActionHandler<UpdateImageAction> = (state, action) => {
  if (state.id === action.id) {
    if (action.origin) {
      state.origin = action.origin
    }
    if (action.src) {
      state.src = {
        ...state.src,
        ...action.src,
      }
    }
  }
}

type ImageViewActionNames = Pick<ImageViewerActions, 'type'>

export const reducer = () => {
  const actionHandlers = new Map<ImageViewActionNames['type'], ImageViewerActionHandler<any>>([
    [SHOW_IMAGE, showImage],
    [HIDE_IMAGE, hideImage],
    [UPDATE_IMAGE, updateImage],
  ])

  return produce((state: ImageViewerState, action: ImageViewerActions|null) => {
    if (!action) {
      return state
    }

    const handler = actionHandlers.get(action.type)
    return handler ? handler(state, action) : state
  }, initialState)
}
