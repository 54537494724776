import { saveActivity } from '@lib/activity'
import { useIntersectionObserver, usePrevious } from '@lib/hooks'
import { useEffect, useRef } from 'react'

let saveActivityTimeout: NodeJS.Timeout
interface ToSave {
  itemId: number,
  section: string,
}

interface Setting {
  pageViewThreshold: number,
  skip: boolean,
}

const useSaveActivityOnView = (toSave: ToSave, { pageViewThreshold, skip }: Setting) => {
  const chapterRef = useRef(null)
  const isVisisted = useIntersectionObserver(chapterRef, {
    threshold: 0,
  })
  const isVisistedPrev = usePrevious(isVisisted)
  let visitInOut = false
  if ((!isVisistedPrev && isVisisted) || (isVisistedPrev && isVisisted)) {
    visitInOut = true
  }
  if (isVisistedPrev && !isVisisted) {
    visitInOut = false
  }
  useEffect(() => {
    if (visitInOut && !skip) {
      clearTimeout(saveActivityTimeout)
      saveActivityTimeout = setTimeout(() => {
        saveActivity(toSave)
      }, pageViewThreshold)
    }
  }, [visitInOut, skip])

  useEffect(() => {
    return () => {
      clearTimeout(saveActivityTimeout)
    }
  }, [])
  return chapterRef
}
export default useSaveActivityOnView
