// Since iPad OS now returns the same browser agent as Safari on the desktop we have to
// check for touch feature support
// https://stackoverflow.com/a/57654258/384938
export function isIpad() {
  const ua = window.navigator.userAgent
  if (ua.indexOf('iPad') > -1) {
    return true
  }

  if (ua.indexOf('Macintosh') > -1) {
    try {
      document.createEvent('TouchEvent')
      return true
    } catch (e) {
      //
    }
  }

  return false
}
