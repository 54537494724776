if (!Math.clamp) {
  // console.log('polyfilling Math.clamp');
  Math.clamp = function (v, min, max) {
    return Math.min(Math.max(v, min), max)
  }
}

if (!Math.log10) {
  // console.log('polyfilling Math.log10');
  Math.log10 = function (v) {
    return Math.log(v) / Math.log(10)
  }
}

if (!String.prototype.toTitleCase) {
  String.prototype.toTitleCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
}
