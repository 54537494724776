'use strict'
import { INVYEAR, YEAR } from '@kpv-lab/time-utils'

import { timelineProps, zoomRanges } from '../config/timeline-props'

export const modeScales = {
  seconds: ['seconds', 'minutes', 'hours'],
  years:   zoomRanges,
}

export function getPropsFromTheme(state, theme) {
  const floatKeys = ['minSpan', 'maxSpan', 'minTime']
  const newProps = timelineProps.reduce((obj, key) => {
    // key in theme
    if (key in theme) {
      obj[key] = theme[key]
    } else {
      obj[key] = state[key]
    }
    if (floatKeys.indexOf(key) > -1) {
      obj[key] = parseFloat(obj[key])
    }
    return obj
  }, {})

  newProps.length =
    (newProps.orientation === 'vertical'
      ? state.height
      : state.width)
    * (newProps.lengthScale || 1)
  newProps.maxSpan = newProps.maxTime - newProps.mintime
  return newProps
}

export function getScalesFromTheme(theme) {
  return modeScales[theme.mode || 'years'].reduce((obj, scale) => {
    if (scale in theme) {
      obj[scale] = {
        zoom:     theme[scale].zoom,
        blendIn:  theme[scale].blendIn || 0.1,
        blendOut: theme[scale].blendOut || 0.1,
      }
    }
    return obj
  }, {})
}

export function rangeLimits(state) {
  let minTime = state.minTime
  if (typeof minTime === 'string') {
    minTime = parseFloat(minTime || 1)
  }

  let maxTime = state.maxTime
  if (typeof maxTime === 'string') {
    maxTime = parseFloat(maxTime || new Date().getFullYear() + 1)
  }

  let minSpan = state.minSpan
  if (typeof minSpan === 'string') {
    minSpan = parseFloat(minSpan || 0)
  }

  let startTime = state.startTime
  let endTime = state.endTime

  if (!minTime || typeof minTime !== 'number') {
    minTime = 1
  }

  if (!maxTime || typeof maxTime !== 'number') {
    maxTime = new Date().getFullYear() + 1
  }

  if (!minSpan) {
    minSpan = INVYEAR * 10
  }

  const maxSpan = maxTime - minTime
  if (minTime >= maxTime) {
    minTime = maxTime - (maxSpan || 100)
  }

  if (!isFinite(startTime)) {
    startTime = minTime
  }

  if (!isFinite(endTime)) {
    endTime = maxTime
  }

  return { minTime, maxTime, minSpan, maxSpan, startTime, endTime }
}

export function calculateExtension(span) {
  return Math.max(span, 0) * 0.03
}

export function timeLimits(state, start, end) {
  let _startTime = start
  let _endTime = end

  if (_endTime <= _startTime) {
    // catch weird issues that might happen with the tweening!
    _startTime = end
    _endTime = _startTime + 1
  }

  if (state.unrestricted) {
    const timeSpan = _endTime - _startTime
    const minTime = state.minTime
    const maxTime = state.maxTime
    if (_startTime > maxTime) {
      return { startTime: maxTime, endTime: maxTime + timeSpan }
    } else if (_endTime < minTime) {
      return { startTime: minTime - timeSpan, endTime: minTime }
    }

    return { startTime: _startTime, endTime: _endTime }
  }

  const originalSpan = state.endTime - state.startTime

  let newSpan = Math.max(_endTime - _startTime, state.minSpan)
  const min = state.minTime - state.startOvershoot * originalSpan
  const max = state.maxTime + state.endOvershoot * originalSpan + calculateExtension(originalSpan)

  if (_startTime < min && _endTime > max) {
    _startTime = min
    _endTime = max
  } else if (_startTime < min) {
    _startTime = min
    _endTime = Math.min(_startTime + newSpan, max)
  } else if (_endTime > max) {
    _endTime = max
    _startTime = Math.max(_endTime - newSpan, min)
  }

  newSpan = _endTime - _startTime
  if (newSpan < state.minSpan) {
    _endTime = _startTime + state.minSpan
  }

  return { startTime: _startTime, endTime: _endTime }
}

export function scaleBlend(state) {
  const { zoom, scale, nextScale, prevScale } = setZoomScales(state)
  const { scales } = state
  const z0 = scales?.[nextScale]?.zoom || state.minZoom
  const z1 = scales?.[scale]?.zoom || state.maxZoom
  const dz = z1 - z0

  let blendIn = scales?.[scale]?.blendIn || 0.1
  blendIn = Math.min(Math.max((zoom - z1) / -(dz * blendIn), 0), 1)

  let blendOut = scales?.[scale]?.blendOut || 0.1
  blendOut = 1 - Math.min(Math.max((zoom - z0) / (dz * blendOut), 0), 1)

  if (blendOut === 1 || zoom <= state.minZoom || zoom >= state.maxZoom) {
    blendIn = 1
    blendOut = 0
  }

  return { zoom, scale, nextScale, prevScale, blendIn, blendOut }
}

export function setZoomScales(state) {
  const mode = state.mode || 'years'
  const scales = state.scales
  const timeSpan = (state.endTime - state.startTime) * (mode === 'years' ? YEAR : 1)

  let idx = 0
  let scale = ''
  let nextScale = ''
  let zoom = Math.log10(
    timeSpan /
      (state.length - (state.startMargin || 0) - (state.endMargin || 0))
  )
  zoom = Math.min(Math.max(zoom, state.minZoom), state.maxZoom).toFixed(4) * 1

  for (const s of modeScales[mode]) {
    nextScale = scale
    scale = s
    idx++
    if (scales[s] && zoom <= scales[s]?.zoom) {
      break
    }
  }
  const prevScale = modeScales[mode][idx] || ''

  return { zoom, scale, nextScale, prevScale }
}
