type RGBAValue = [number, ...Array<number>] & { length: 4 }
type RGBValue = [number, ...Array<number>] & { length: 3 }
type ColorInput = string|ReadonlyArray<string>|ReadonlyArray<number>

export function col2rgba(input: ColorInput, alpha = 1): RGBAValue {
  let r = 0,
    g = 0,
    b = 0
  let a = Math.min(Math.max(alpha, 0), 1)

  // console.log('col2rgba:', str, a);

  if (Array.isArray(input)) {
    a = input.length > 3 ? parseFloat(input[3]) : a
    return [...input.slice(0, 3), a].map(Number) as RGBAValue
  }

  let color = (input as string || '000000').replace(/[#\s]*/g, '')

  if (color.length === 2) {
    color = color + color + color
  } else if (color.length === 3) {
    color = color.split('').map((c) => c + c).join('')
  }

  // standard hex
  let m = color.match(/^([\da-f]{2})([\da-f]{2})([\da-f]{2})/i)

  if (m) {
    r = parseInt(m[1], 16)
    g = parseInt(m[2], 16)
    b = parseInt(m[3], 16)
    return [r, g, b, a]
  }

  // rgb(r, g, b)
  m = color.match(/^rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/i)

  if (m) {
    r = parseInt(m[1], 10)
    g = parseInt(m[2], 10)
    b = parseInt(m[3], 10)
    return [r, g, b, a]
  }

  // rgba(r, g, b, a)
  m = color.match(/^rgba\((\d{1,3}),(\d{1,3}),(\d{1,3}),([\d.]+)\)/i)

  if (m) {
    r = parseInt(m[1], 10)
    g = parseInt(m[2], 10)
    b = parseInt(m[3], 10)
    a = Math.min(Math.max(parseFloat(m[4]), 0), 1)
    return [r, g, b, a]
  }

  return [r, g, b, a]
}

export function vec2rgba(vec: Array<number>): RGBAValue {
  const r = Math.floor(vec[0] * 255)
  const g = Math.floor(vec[1] * 255)
  const b = Math.floor(vec[2] * 255)
  const a = vec[3] || vec[3] === 0 ? vec[3] : 1

  return [r, g, b, a]
}

export function col2vec(col: string|Array<string>, alpha?: number) {
  const [r, g, b, a] = col2rgba(col, alpha)
  return [(r / 255).toFixed(5), (g / 255).toFixed(5), (b / 255).toFixed(5), a].map(Number)
}

export function rgbaString(rgb: ColorInput, alpha?: number) {
  const [r, g, b, a] = col2rgba(rgb, alpha)
  return `rgba(${Math.floor(r)},${Math.floor(g)},${Math.floor(b)},${Number(a.toFixed(3))})`
}

export function blendColors(col1: string, col2: string, t = 0, alpha = 1, array = false) {
  const c1 = col2rgba(col1)
  const c2 = col2rgba(col2)

  if (c1[0] === c2[0] && c1[1] === c2[1] && c1[2] === c2[2]) {
    return alpha === 1 ? col1 : rgbaString(col1, alpha)
  }

  const rf = Math.floor(linearBlend(c1[0], c2[0], t))
  const gf = Math.floor(linearBlend(c1[1], c2[1], t))
  const bf = Math.floor(linearBlend(c1[2], c2[2], t))

  return array ? [rf, gf, bf, alpha] : `rgba(${rf},${gf},${bf},${alpha})`
}

export function linearBlend(a: number, b: number, t = 0): number {
  if (t <= 0) {
    return a
  }

  if (t >= 1) {
    return b
  }

  return a * (1 - t) + b * t
}

// linear [0-1] output when v moves between edge 1 & 2
export function step(v: number, e1: number, e2: number): number {
  const de = e2 - e1
  return de === 0 ? 1 : Math.min(Math.max((v - e1) / de, 0), 1)
}

// rgb to hsl
export function rgb2hsl(r = 0, g = 0, b = 0, normalized = false, precise = false): RGBValue {
  let [h, s, l, rDelta, gDelta, bDelta]: Array<number> = []
  let col: Array<number>

  col = Array.isArray(r) ? r : [r, g, b]

  if (!normalized) {
    col = col.map(i => i / 255)
  }

  const rgbMin = Math.min(col[0], Math.min(col[1], col[2]))
  const rgbMax = Math.max(col[0], Math.max(col[1], col[2]))
  const rgbDelta = rgbMax - rgbMin
  l = rgbMax

  if (rgbDelta === 0) {
    // grey
    h = 0
    s = 0
  } else {
    // color
    s = rgbDelta / rgbMax
    rDelta = ((rgbMax - col[0]) / 6.0 + rgbDelta / 2.0) / rgbDelta
    gDelta = ((rgbMax - col[1]) / 6.0 + rgbDelta / 2.0) / rgbDelta
    bDelta = ((rgbMax - col[2]) / 6.0 + rgbDelta / 2.0) / rgbDelta

    if (col[0] === rgbMax) {
      h = bDelta - gDelta
    } else if (col[1] === rgbMax) {
      h = 1.0 / 3.0 + rDelta - bDelta
    } else if (col[2] === rgbMax) {
      h = 2.0 / 3.0 + gDelta - rDelta
    }

    if (h < 0) {
      h += 1
    } else if (h > 1) {
      h -= 1
    }
  }

  h *= 359
  s *= 100
  l *= 100

  return precise ? [h, s, l] : [Math.round(h), Math.round(s), Math.round(l)]
}

export function hsl2rgb(hue: number, sat: number, lgt: number, normalized = false, precise = false): RGBValue {
  let [h, s, l, r, g, b, i, j, p, q, t]: Array<number> = []

  if (!normalized) {
    h = hue / 359
    s = Math.max(Math.min(sat / 100, 1), 0)
    l = Math.max(Math.min(lgt / 100, 1), 0)
  } else {
    h = hue
    s = sat
    l = lgt
  }

  if (h >= 1) {
    h = 0
  }

  if (l === 0) {
    // no brightness so return black
    return [0, 0, 0]
  } else if (s === 0) {
    // no saturation so return grey
    l = Math.round(l * 255)
    return [l, l, l]
  } else {
    // RGB color
    h *= 6
    i = Math.floor(h)
    j = h - i
    p = l * (1 - s)
    q = l * (1 - s * j)
    t = l * (1 - s * (1 - j))

    if (i === 0) {
      r = l
      g = t
      b = p
    } else if (i === 1) {
      r = q
      g = l
      b = p
    } else if (i === 2) {
      r = p
      g = l
      b = t
    } else if (i === 3) {
      r = p
      g = q
      b = l
    } else if (i === 4) {
      r = t
      g = p
      b = l
    } else if (i === 5) {
      r = l
      g = p
      b = q
    }
  }

  r *= 255
  g *= 255
  b *= 255

  return precise ? [r, g, b] : [Math.round(r), Math.round(g), Math.round(b)]
}

export function rgba2hex(r: number|ReadonlyArray<number>, g?: number, b?: number, a?: number): string {
  const rgb = Array.isArray(r) ? r : [r, g, b, a]
  let hex = ''
  let i = 0
  let limit = 3

  if (rgb[3] !== undefined) {
    rgb[3] = rgb[3] * 256
    limit = 4
  }

  while (i < limit) {
    rgb[i] = Math.min(Math.round(rgb[i]), 255)
    if (rgb[i] < 16) {
      hex += '0'
    }

    hex += rgb[i].toString(16)
    i++
  }

  return '#' + hex.toUpperCase()
}

export function hsl2hex(h: number|Array<number>, s = 0, l = 0): string {
  const hsl = Array.isArray(h) ? h : [h, s, l]
  return rgba2hex(hsl2rgb(hsl[0], hsl[1], hsl[2], false, true))
}

export function hex2rgb(str: string|Array<string>): RGBValue {
  const [r, g, b] = col2rgba(str)
  return [r, g, b]
}

export function hex2hsl(hex: string) {
  const rgb = hex2rgb(hex)
  return rgb2hsl(...rgb)
}

export const lumCoeff = [0.2126, 0.7152, 0.0722]

export function rgb2lum(r: number|string, g: number|string, b: number|string): number {
  const numberValues = [r, g, b].map((v: any) => parseFloat(v))
  return lumCoeff.map((l, i) => numberValues[i] / 255 * l).reduce((v, s) => s + v, 0)
}

export function hsl2lum(hue: number, sat:number, lgt:number): number {
  const val = hsl2rgb(hue, sat, lgt)
  return lumCoeff.map((b, i) => val[i] / 255 * b).reduce((v, s) => s + v, 0)
}
