import './Tooltip.css'

import Portal from '@components/portal'
import classNames from 'classnames'
import React, { useState } from 'react'

interface TooltipProps {
  title: string,
  className?: string,
  children: React.ReactNode,
}

const Tooltip: React.FC<TooltipProps> = ({ title, className, children }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [visible, setVisible] = useState(false)

  const offsetInPx = { x: 10, y: 20 }

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    setPosition({ x: e.clientX + offsetInPx.x, y: e.clientY + offsetInPx.y })
  }

  const handleMouseEnter = () => setVisible(true)
  const handleMouseLeave = () => setVisible(false)

  const tooltipContent = (
    <div
      className="tooltip-title"
      style={{
        top:        `${position.y}px`,
        left:       `${position.x}px`,
        visibility: visible ? 'visible' : 'hidden',
        opacity:    visible ? 1 : 0,
      }}
    >
      {title}
    </div>
  )

  return (
    <>
      <div
        className={classNames('tooltip-element-wrapper', className)}
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {/* remove tooltip from regular DOM flow via Portal to ensure it does not influence other positions. */}
      <Portal>
        {tooltipContent}
      </Portal>
    </>
  )
}

export default Tooltip
