import { ContentRect, findHorizontalAlignment, findVerticalAlignment, TargetRect } from './alignment'
import { parseHorizontal, parseVertical, Place } from './place'

export type Position = {
  alignment: Place,
  left: number,
  place: Place,
  top: number,
}

const placeAttempts: Record<Place, Array<Place>> = {
  top:    ['top', 'left', 'right', 'bottom', 'middle', 'centre'],
  middle: ['middle', 'centre', 'top', 'left', 'right', 'bottom'],
  bottom: ['bottom', 'left', 'right', 'top', 'middle', 'centre'],
  left:   ['left', 'top', 'bottom', 'right', 'centre', 'middle'],
  centre: ['centre', 'middle', 'top', 'left', 'right', 'bottom'],
  right:  ['right', 'top', 'bottom', 'left', 'centre', 'middle'],
}

export function calculatePosition(
  targetRect: TargetRect,
  contentRect: ContentRect,
  place: Place,
  alignment?: Place,
  safetyMargin = 4
): Position | null {

  let top: number | null = null
  let left: number | null = null
  let calculatedPlace: Place | null = null
  let calculatedAlignment: Place | null = null

  const contentWidth = contentRect.width
  const contentHeight = contentRect.height

  if (!contentWidth || !contentHeight) {
    return null
  }

  const horizontalAlignmentResult = findHorizontalAlignment(
    targetRect,
    contentWidth,
    safetyMargin,
    parseHorizontal(alignment)
  )

  const verticalAlignmentResult = findVerticalAlignment(
    targetRect,
    contentHeight,
    safetyMargin,
    parseVertical(alignment)
  )

  placeAttempt:
  for (const placeAttempt of (placeAttempts[place] || placeAttempts['top'])) {
    calculatedPlace = placeAttempt

    switch (placeAttempt) {

      case 'top': {
        top = targetRect.top - contentHeight
        const fitsVertically = top >= safetyMargin

        if (!fitsVertically || !horizontalAlignmentResult) {
          continue placeAttempt
        }

        calculatedAlignment = horizontalAlignmentResult.alignment
        left = horizontalAlignmentResult.left
        break placeAttempt
      }
      case 'middle': {
        const targetVerticalCenter = (targetRect.top + targetRect.bottom) / 2
        top = targetVerticalCenter - contentHeight / 2
        const fitsVertically = top >= safetyMargin && top + contentHeight + safetyMargin < window.innerHeight

        if (!fitsVertically || !horizontalAlignmentResult) {
          continue placeAttempt
        }

        calculatedAlignment = horizontalAlignmentResult.alignment
        left = horizontalAlignmentResult.left
        break placeAttempt
      }
      case 'bottom': {
        top = targetRect.bottom
        const fitsVertically = top + contentHeight + safetyMargin < window.innerHeight

        if (!fitsVertically || !horizontalAlignmentResult) {
          continue placeAttempt
        }

        calculatedAlignment = horizontalAlignmentResult.alignment
        left = horizontalAlignmentResult.left
        break placeAttempt
      }

      case 'left': {
        left = targetRect.left - contentWidth
        const fitsHorizontally = left >= safetyMargin

        if (!fitsHorizontally || !verticalAlignmentResult) {
          continue placeAttempt
        }

        calculatedAlignment = verticalAlignmentResult.alignment
        top = verticalAlignmentResult.top
        break placeAttempt
      }
      case 'centre': {
        const targetHorizontalCenter = (targetRect.left + targetRect.right) / 2
        left = targetHorizontalCenter - contentWidth / 2
        const fitsHorizontally = left >= safetyMargin && left + contentWidth + safetyMargin < window.innerWidth

        if (!fitsHorizontally || !verticalAlignmentResult) {
          continue placeAttempt
        }

        calculatedAlignment = verticalAlignmentResult.alignment
        top = verticalAlignmentResult.top

        break placeAttempt
      }
      case 'right': {
        left = targetRect.right
        const fitsHorizontally = left + contentWidth + safetyMargin < window.innerWidth

        if (!fitsHorizontally || !verticalAlignmentResult) {
          continue placeAttempt
        }

        calculatedAlignment = verticalAlignmentResult.alignment
        top = verticalAlignmentResult.top
        break placeAttempt
      }

    }

  }

  return top !== null && left !== null && calculatedPlace !== null && calculatedAlignment != null
    ? {
      top,
      left,
      place:     calculatedPlace,
      alignment: calculatedAlignment,
    }
    : null
}

export const equals = (p1?: Position, p2?: Position) => {
  if (p1 === p2) {
    return true
  }
  if (!p1 || !p2) {
    return false
  }
  return ['top', 'left', 'place', 'alignment'].every((prop: string) => p1[prop] === p2[prop])
}
