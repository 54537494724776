import {
  getPropsFromTheme,  getScalesFromTheme,
  scaleBlend } from '../../utils/action-helpers'
import { defaultState } from './initial-state'

export const setTheme = (state, data) => {
  const { theme } = data

  let newState = state || { ...defaultState }

  newState = {
    ...newState,
    ...getPropsFromTheme(newState, theme),
  }

  newState.scales = getScalesFromTheme(theme)

  return {
    ...newState,
    ...scaleBlend(newState),
  }
}
