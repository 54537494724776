import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import apolloLogger from 'apollo-link-logger'

import settings from '../config/settings'
import { getCookie } from '../lib/cookies'

const links: Array<ApolloLink> = []

// ignore coverage
const busyLink = new ApolloLink((operation, forward) => {
  const event = new window.CustomEvent('cls:busy')
  document.body.dispatchEvent(event)

  return forward(operation).map(result => {
    const doneEvent = new window.CustomEvent('cls:done')
    document.body.dispatchEvent(doneEvent)
    return result
  })
})
links.push(busyLink)

// ignore coverage
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...getTokenAuthorization(),
    },
  }))
  return forward(operation)
})
links.push(authLink)

export function getTokenAuthorization(token = getCookie(settings.jwtKey)) {
  if (!token) {
    console.warn('no JWT token!')
  }
  return token ? { authorization: `Bearer ${token}` } : {}
}

// ignore coverage
const partnerLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...getSelectedPartners(),
    },
  }))
  return forward(operation)
})
links.push(partnerLink)

function getSelectedPartners(selectedPartners = getCookie(settings.partnerKey)) {
  return selectedPartners ? { selectedpartners: JSON.parse(selectedPartners).map(({ id }) => id) } : {}
}


// only use the logger in dev mode
if (module.hot) {
  links.push(apolloLogger)
}

// ignore coverage
const httpLink = new HttpLink({
  fetch: (uri, opts) => fetch(setFetchUri(), opts),
})
links.push(httpLink)

export function setFetchUri() {
  return settings.publicGraphql
}

const client = new ApolloClient({
  link:  ApolloLink.from(links),
  cache: new InMemoryCache(),

  ssrMode:            false,
  queryDeduplication: true,
})

export default client
