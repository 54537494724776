import { Reference as AuthorReferenceData, ReferenceBlock as ReferenceBlockData  } from './types'


export const addReferenceValues = (refBlocks: Array<ReferenceBlockData>, authorReferences: Array<AuthorReferenceData>) => {

  const refs  = refBlocks.reduce((result: Array<ReferenceBlockData>, refBlock) => {
    const authorRef = authorReferences.find(ref => ref.refId === refBlock.refId)
    if (authorRef) {
      result.push({
        ...refBlock,
        title:         authorRef.title,
        fullReference: authorRef.fullReference,
      })
    }
    return result
  }, [])

  return refs
}

export const filterUnreferencedSources = (refBlocks: Array<ReferenceBlockData>, authorReferences: Array<AuthorReferenceData>) => {
  return authorReferences?.reduce((result: Array<ReferenceBlockData>, authorRef) => {
    const authorRefInRefBlock = refBlocks.filter(refBlock => authorRef.refId === refBlock.refId)
    if (authorRefInRefBlock.length === 0) {
      result.push({
        refIdx:        result.length + 1,
        refId:         authorRef.refId,
        blockId:       null,
        title:         authorRef.title,
        fullReference: authorRef.fullReference,
      })
    }
    return result
  }, [])
}
