import React from 'react'

import { Place } from './place'

export interface CurrentState {
  alignment?: Place,
  containerClass?: string,
  content: React.ReactNode,
  target: HTMLElement,
  place: Place,
}

export interface PopupContextProps {
  hide: () => void,
  setCurrent: (currentPopup: CurrentState) => void,
  show: () => void,
}

export const PopupContext = React.createContext<PopupContextProps>({} as PopupContextProps)

