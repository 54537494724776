'use strict'

export const timelineProps = [
  'startTime',
  'endTime',
  'minZoom',
  'maxZoom',
  'mode',
  'rulerWidth',
  'viewMode',
  'orientation',
  'startOvershoot',
  'endOvershoot',
  'minTime',
  'maxTime',
  'unrestricted',
  'minSpan',
  'maxSpan',
  'startMargin',
  'endMargin',
  'lengthScale',
  'focusMode',
]

export const zoomRanges = [
  'seconds',
  'minutes',
  'hours',
  'days',
  'months',
  'years',
  'centuries',
  'millennia',
  'thousands',
  '100thousands',
  'millions',
  '100millions',
  'billions',
  'eons',
]

export const AGE_OF_UNIVERSE = 13.82e9

export const CURRENT_TIME = 9999
