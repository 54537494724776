import { gql, useQuery } from '@apollo/client'
import settings from '@config/settings'
import { clearCookie } from '@lib/cookies'
import { CurrentUserQuery } from 'le-client'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

interface OwnProps {
  component: React.ComponentType<any>,
  loadingComponent: React.ComponentType,
  errorComponent: React.ComponentType,
  loginPath: string,
}

export const AuthRoute:React.FC<OwnProps> = ({
  loadingComponent: LoadingComponent,
  component: Component,
  errorComponent: ErrorComponent,
  loginPath,
}) => {

  const { loading, error, data } = useQuery<CurrentUserQuery>(CURRENT_USER)
  if (loading) {
    return (<LoadingComponent />)
  } else if (data?.currentUser?.id) {
    return (
      <Route render={props => <Component {...props} user={data.currentUser} />} />
    )
  }

  if (error) {
    console.warn('AuthRoute error', error)
    clearCookie(settings.jwtKey)
    // @ts-ignore: We're knowingly using the window object
    window._leError = /network error/i.test(error.message)
      ? 'Network error, please try again'
      : error.message

    return (<ErrorComponent />)
  }

  const redirectTo = window.location.pathname
  const location = {
    pathname: loginPath,
    state:    { from: redirectTo },
  }

  // @ts-ignore: We're knowingly using the window object
  window._leRedirect = redirectTo

  return <Redirect to={location} />
}

export const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      email
      name
      role
      data
      createdAt
      updatedAt
    }
    activity
  }
`

export default AuthRoute
