export function getCookie(key: string) {
  if (!key) {
    return null
  }
  return window.localStorage.getItem(key)
}

export function setCookie(key: string, val = '') {
  window.localStorage.setItem(key, val)
}

export function clearCookie(key: string) {
  window.localStorage.removeItem(key)
}
