import { addReferenceValues, filterUnreferencedSources } from '../../references/lib/add-reference-values'
import { InfoBlock, Reference as AuthorReferenceData, ReferenceBlock as ReferenceBlockData } from '../../references/lib/types'

export interface PreparedInfoBlock {
  id: number,
  value: string,
  isRef: boolean,
  refIdx?: number,
}

export function prepareInfoBlocks(blocks: Array<InfoBlock> = [], startNum = 1) {
  let num = startNum
  const newBlocks: Array<PreparedInfoBlock> = blocks.filter((block) => block).map(block => {
    const { id, value, isRef } = block
    return {
      id,
      value,
      isRef,
      refIdx: block?.isRef ? num++ : undefined,
    }
  })

  return { blocks: newBlocks, nextRef: num }
}

export function prepareRefBlocks(blocks: Array<ReferenceBlockData> = [], authorReferences: Array<any>, startRefIdx = 1) {
  const authorRefs: Array<AuthorReferenceData> = authorReferences?.map(ref => {
    return {
      ...ref,
      refId: ref.id,
    }
  })

  const refsWithValues = addReferenceValues(blocks, authorRefs)
  const sourcesWithoutReferences = filterUnreferencedSources(blocks, authorRefs) || []

  let num = startRefIdx

  const updateBlocks = [...refsWithValues, ...sourcesWithoutReferences].map(({ refIdx, ...block }) => ({
    ...block,
    refIdx: refIdx !== undefined ? refIdx : num++,
  }))

  return {
    blocks:     updateBlocks,
    nextRefIdx: num,
  }
}

export function addNbspBeforeLastWord(str = '') {
  const wrapperEl = document.createElement('div')
  wrapperEl.innerHTML = str
  const el = wrapperEl.childNodes[0]
  if (!el) {
    return str
  }
  const textNodesWithSpace = Array.from(el.childNodes).filter(
    node => node.nodeName === '#text' && node.textContent?.includes(' ')
  )
  if (textNodesWithSpace.length === 0) {
    return str
  }
  const lastTextNode = textNodesWithSpace[textNodesWithSpace.length - 1]
  const nonBreakingSpaceChar = String.fromCharCode(160)
  const newText = lastTextNode.textContent?.replace(/ (?=[^ ]*$)/, nonBreakingSpaceChar)
  lastTextNode.textContent = newText ?? lastTextNode.textContent
  return wrapperEl.innerHTML
}
