import { reducer as timelineReducer } from '@kpv-lab/le-timeline'
import { enableMapSet } from 'immer'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createTransform, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import { reducer as imageReducer } from '../components/image-viewer/state/reducer'
import { reducer as modalReducer } from '../components/modal/state/modal-redux'
import { reducer as appReducer } from '../views/app/state/reducer'
import { reducer as feedbackReducer } from '../views/feedback/state/reducer'
import { reducer as quizReducer } from '../views/quiz/state/reducer'
import { QuizStates, QuizzesState } from '../views/quiz/state/types'
import { reducer as searchReducer } from '../views/search/state/search-redux'
import { reducer as snippetReducer } from '../views/snippets/state/snippets-redux'


enableMapSet()

const persistConfig = {
  key:             'root',
  storage,
  whitelist:  ['quizzes'],
  transforms: [createTransform<QuizzesState, Array<[number, QuizStates]>>(
    map => Array.from(map),
    array => new Map(array),
    { whitelist: ['quizzes'] }
  )],
}

const rootReducer = combineReducers({
  app:      appReducer,
  timeline: timelineReducer,
  snippets: snippetReducer(),
  search:   searchReducer(),
  modal:    modalReducer(),
  feedback: feedbackReducer(),
  image:    imageReducer(),
  quizzes:  quizReducer(),
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function configureStore(initialState) {
  const composeEnhancers = composeWithDevTools(
    { trace: true, traceLimit: 25 }
  )
  const store = createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(thunk)))
  const persistor = persistStore(store)

  // ignore coverage
  if (module.hot) {
    module.hot.accept('./redux', () => {
      store.replaceReducer(persistedReducer)
    })
  }
  return { store, persistor }
}
