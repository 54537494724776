export function addMarkerStyle(styles, key, style) {
  if (!style) {
    styles[key] = { active: false }
    return styles
  }

  const { active, line, marker } = style

  if (!styles[key]) {
    styles[key] = {
      active:    active || false,
      curviness: line?.curviness || 0,
      offset1:   line?.offset1 || 0,
      offset2:   line?.offset2 || 0,
      offset3:   line?.offset3 || 0,
      radius:    marker?.radius || 0,
      shape:     marker?.shape,
      ends:      marker?.ends,

      line: {
        stroke:      line?.stroke,
        strokeWidth: line?.strokeWidth,
        fill:        'none',
      },
      marker: {
        fill:        marker?.fill,
        stroke:      marker?.stroke,
        strokeWidth: marker?.strokeWidth,
      },
    }

    const d1 = line?.dash1
    const d2 = line?.dash2

    if (d1 && d2) {
      styles[key].line.strokeDasharray = `${d1},${d2}`
    }
  }

  return styles
}

export const markersFactory = (markers, markersWidth, markerStyles, sizeScale) => ({
  addRangeMarker: (markerKey, props, endMarker, side = 1, distFromEdge = 0) => {
    markers[side].push({
      t: props.timePos + (endMarker ? props.length : 0),
      p: distFromEdge + props.trackPos,
      a: props.alpha,
      k: markerKey,
      i: props.itemPos,
    })
    markersWidth[side] = Math.max(
      markersWidth[side],
      distFromEdge +
        props.trackPos +
        markerStyles[markerKey].radius -
        markerStyles[markerKey].offset2 * sizeScale
    )
  },
  addPointMarker: (markerKey, props, side = 1, distFromEdge = 0) => {
    markers[side].push({
      t: props.rulerPos,
      d: props.timePos - props.rulerPos,
      p: distFromEdge + props.trackPos,
      a: props.alpha,
      k: markerKey,
      i: props.itemPos,
    })
    markersWidth[side] = Math.max(
      markersWidth[side],
      distFromEdge +
        props.trackPos +
        markerStyles[markerKey].radius -
        markerStyles[markerKey].offset2 * sizeScale
    )
  },
  addRulerMarker: (markerKey, timePos, side) => {
    markers[side].push({
      t: timePos,
      k: markerKey,
      o: true,
    })
    markersWidth[side] =
      markerStyles[markerKey].offset1 * sizeScale + markerStyles[markerKey].radius * 2
  },
})
