import { produce } from 'immer'

const SHOW_MODAL = 'SHOW_MODAL'
const HIDE_MODAL = 'HIDE_MODAL'

interface ShowModalAction {
  type: typeof SHOW_MODAL,
  data: Omit<ModalState, 'visible'>,
}

interface HideModalAction {
  type: typeof HIDE_MODAL,
}

interface ModalState {
  acceptHandler?: () => void,
  cancelHandler?: () => void,
  children: any,
  className: string,
  clickToClose: boolean,
  visible: boolean,
  id?: string,
}

type ModalActions = ShowModalAction | HideModalAction

export function showModal(data: ShowModalAction['data']): ShowModalAction {
  return {
    type: SHOW_MODAL,
    data,
  }
}

export function hideModal(): HideModalAction {
  return {
    type: HIDE_MODAL,
  }
}

export const initialState: ModalState = {
  visible:       false,
  children:      null,
  acceptHandler: undefined,
  cancelHandler: undefined,
  clickToClose:  false,
  className:     '',
}

export const reducer = () => {
  return produce((draftState: ModalState, action: ModalActions|null) => {
    if (!action) {
      return draftState
    }

    if (action.type === 'SHOW_MODAL') {
      const { acceptHandler, cancelHandler, children, className, id, clickToClose } = action.data
      draftState.acceptHandler = acceptHandler
      draftState.cancelHandler = cancelHandler
      draftState.children = children
      draftState.className = className
      draftState.id = (id || Date.now()).toString()
      draftState.visible = true
      draftState.clickToClose = clickToClose
    } else if (action?.type === 'HIDE_MODAL') {
      return initialState
    }
  }, initialState)
}
