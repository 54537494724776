import { getIn } from '@lib/helpers'

import settings from './settings'

export function getLang() {
  return settings.defaultLang
}

let language = {}

export function loadLang(locale = 'en') {
  const strings = import(/* webpackChunkName: "language" */ `./lang/${locale}`)
  return strings.then((data) => {
    language = Object.assign(language, ...data.strings)
  }).catch((e) => {
    console.error('Error loading language file: ', e)
  })
}

export function t(key: string, lowercase?: boolean, placeholders?: Array<string>) {
  const path = `${key}`
  let string = getIn(language, path) || 'UNDEFINED' as string

  if (placeholders && placeholders.length) {
    const parts = string.split(' ')
    placeholders.forEach((p, idx) => {
      parts.map((w, i) => {
        if (w === `$${idx}`) {
          parts[i] = placeholders[idx]
        }
      })
    })

    string = parts.join(' ')
  }

  return lowercase ? string.toLowerCase() : string

}

