import { HorizontalPlace, Place, VerticalPlace } from './place'

export type TargetRect = Pick<ClientRect, 'top' | 'bottom' | 'right' | 'left'>
export type ContentRect = Pick<ClientRect, 'width' | 'height'>

const horizontalAlignmentAttempts: Record<HorizontalPlace, Array<HorizontalPlace>> = {
  left:   ['left', 'centre', 'right'],
  centre: ['centre', 'left', 'right'],
  right:  ['right', 'centre', 'left'],
}

const verticalAlignmentAttempts: Record<VerticalPlace, Array<VerticalPlace>> = {
  top:    ['top', 'middle', 'bottom'],
  middle: ['middle', 'top', 'bottom'],
  bottom: ['bottom', 'middle', 'top'],
}

export const findHorizontalAlignment = (
  targetRect: TargetRect,
  contentWidth: number,
  safetyMargin: number,
  alignment: HorizontalPlace = 'centre'
) => {

  let left: number | null = null
  let found = false
  let calculatedAlignment : Place | null = null

  alignmentAttempt:
  for (const attempt of (horizontalAlignmentAttempts[alignment] || horizontalAlignmentAttempts['centre'])) {
    calculatedAlignment = attempt

    switch (attempt) {

      case 'left':
        left = targetRect.left
        break

      case 'right':
        left = targetRect.right - contentWidth
        break

      case 'centre':
      default:
        const targetHorizontalCenter = (targetRect.left + targetRect.right) / 2
        left = targetHorizontalCenter - contentWidth / 2
        break

    }
    if (
      safetyMargin <= left &&
      left + contentWidth + safetyMargin < window.innerWidth
    ) {
      found = true
      break alignmentAttempt
    }
  }

  return found ? { left, alignment: calculatedAlignment } : null
}

export const findVerticalAlignment = (
  targetRect: TargetRect,
  contentHeight: number,
  safetyMargin: number,
  alignment: VerticalPlace = 'middle'
) => {

  let top: number | null = null
  let found = false
  let calculatedAlignment : Place | null = null

  alignmentAttempt:
  for (const attempt of (verticalAlignmentAttempts[alignment] || verticalAlignmentAttempts['middle'])) {
    calculatedAlignment = attempt
    switch (attempt) {

      case 'top':
        top = targetRect.top
        break

      case 'bottom':
        top = targetRect.bottom - contentHeight
        break

      case 'middle':
      default:
        const targetVerticalCenter = (targetRect.top + targetRect.bottom) / 2
        top = targetVerticalCenter - contentHeight / 2
        break

    }
    if (
      safetyMargin <= top &&
      top + contentHeight + safetyMargin < window.innerHeight
    ) {
      found = true
      break alignmentAttempt
    }
  }

  return found ? { top, alignment: calculatedAlignment } : null
}
