'use strict'

import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import TimelineSpaceScroll from './TimelineSpaceScroll'

export default class TimelineItemsGroup extends PureComponent {

  static propTypes = {
    id:                 PropTypes.string,
    track:              PropTypes.number,
    trackSide:          PropTypes.number,
    trackCount:         PropTypes.number,
    tracksVisible:      PropTypes.number,
    trackHeight:        PropTypes.number,
    scrollable:         PropTypes.bool,
    vertical:           PropTypes.bool,
    style:              PropTypes.object,
    spaceScrollHandler: PropTypes.func,
    children:           PropTypes.array,
  }

  render() {
    const {
      spaceScrollHandler,
      id,
      track,
      trackSide,
      trackCount,
      tracksVisible,
      trackHeight,
      scrollable,
      children,
      vertical,
      style,
    } = this.props

    if (style.borderEdge) {
      if (style.borderEdge === 'none') {
        delete style.borderStyle
        delete style.borderWidth
        delete style.borderColor
      } else if (style.borderEdge === 'both') {
        if (vertical) {
          style.borderTopWidth = 0
          style.borderBottomWidth = 0
        } else {
          style.borderLeftWidth = 0
          style.borderRightWidth = 0
        }
      } else if (style.borderEdge === 'outside') {
        const w = style.borderWidth
        style.borderWidth = 0

        if (vertical) {
          if (trackSide === 1) {
            style.borderRightWidth = w
            style.borderLeftWidth = 0
          } else {
            style.borderLeftWidth = w
            style.borderRightWidth = 0
          }
        } else {
          if (trackSide === 1) {
            style.borderBottomWidth = w
            style.borderTopWidth = 0
          } else {
            style.borderTopWidth = w
            style.borderBottomWidth = 0
          }
        }
      } else if (style.borderEdge === 'inside') {
        const w = style.borderWidth
        style.borderWidth = 0

        if (vertical) {
          if (trackSide === -1) {
            style.borderRightWidth = w
            style.borderLeftWidth = 0
          } else {
            style.borderLeftWidth = w
            style.borderRightWidth = 0
          }
        } else {
          if (trackSide === -1) {
            style.borderBottomWidth = w
            style.borderTopWidth = 0
          } else {
            style.borderTopWidth = w
            style.borderBottomWidth = 0
          }
        }
      }
    }

    delete style.backgroundBlendMode
    delete style.backgroundColor
    delete style.bgTexture

    let spaceScroller
    if (scrollable) {
      spaceScroller = (
        <TimelineSpaceScroll
          track={track}
          trackCount={trackCount}
          tracksVisible={tracksVisible}
          trackGroupId={id}
          trackSide={trackSide}
          side={trackSide}
          vertical={vertical}
          updateHandler={spaceScrollHandler}
        />
      )

    }

    const className = `timeline-group ${scrollable ? 'scrollable' : ''}`
    return (
      <section
        className={className}
        data-group-id={id}
        data-start-track={track}
        data-track-height={trackHeight}
        data-track-limit={trackCount - Math.floor(tracksVisible)}
        data-track-side={trackSide}
        data-scrollable={scrollable}
        style={style}
      >
        <div className="timeline-group-items">
          {children}
        </div>
        {spaceScroller}
      </section>
    )
  }

}
